import React from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import OrganizationDetails from "../../components/searchOrganization/OrganizationDetails";

const Organization = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setCharityPath,
  setCharityDetails,
  organizationDetails,
  organizationPath,
  setTaxReceiptModal,
  setLinkDonationModal,
  currentQuery,
  setQuantity,
}) => (
  <div className={userData ? "main-container logged-in" : "main-container"}>
    <Header
      hostName={hostName}
      userData={userData}
      setLoggedInUserData={setLoggedInUserData}
      handleToggle={handleToggle}
      dropdownToggle={dropdownToggle}
      handleDropdownToggle={handleDropdownToggle}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      organizationPath={organizationPath}
      organizationDetails={organizationDetails}
      setTaxReceiptModal={setTaxReceiptModal}
      setLinkDonationModal={setLinkDonationModal}
    />
    <div className="dashboard-container">
      <Sidebar userData={userData} toggled={toggled} />
      <OrganizationDetails
        hostName={hostName}
        userData={userData}
        setLoggedInUserData={setLoggedInUserData}
        organizationDetails={organizationDetails}
        setCharityPath={setCharityPath}
        setCharityDetails={setCharityDetails}
        currentQuery={currentQuery}
        setQuantity={setQuantity}
      />
    </div>
  </div>
);

export default Organization;
