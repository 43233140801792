import React from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import LinkDonationComponent from "../../components/qr-link-donation/LinkDonationComponent";

const LinkDonation = ({
  userData,
  hostName,
  toggled,
  setLoggedInUserData,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => (
  <div className={userData ? "main-container logged-in" : "main-container"}>
    <Header
      hostName={hostName}
      userData={userData}
      setLoggedInUserData={setLoggedInUserData}
      handleToggle={handleToggle}
      dropdownToggle={dropdownToggle}
      handleDropdownToggle={handleDropdownToggle}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setTaxReceiptModal={setTaxReceiptModal}
      setLinkDonationModal={setLinkDonationModal}
    />
    <div className="dashboard-container">
      <Sidebar userData={userData} toggled={toggled} />
      <div className="home-content">
        <LinkDonationComponent
          hostName={hostName}
          setLoggedInUserData={setLoggedInUserData}
          userData={userData}
        />
      </div>
    </div>
  </div>
);
export default LinkDonation;
