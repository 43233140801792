import React from "react";
import Icon from "react-icons-kit";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";

const EmbeddedOrganizationDetails = ({
  organizationDetails,
  setCharityDetails,
  step,
  setStep,
  paths,
  lang,
  handleLanguage,
  currentQuery,
  setQuantity,
  setAmount
}) => {
  // translation
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // campaign click
  const handleCampaignClick = (campaign) => {
    setQuantity(1);
    setAmount(0);
    setCharityDetails(campaign);
    localStorage.setItem("campaign", JSON.stringify(campaign));
    setStep(step + 1);

    // Update the URL to include the campaign name
    const campaignName = campaign.name
      .toLowerCase()
      .replace(/[^a-zA-Z0-9-]/g, "");

    // Construct the URL conditionally including currentQuery if it exists
    const newPath = `/${paths[0]}/${paths[1]}/${campaignName}`;
    const query = currentQuery ? currentQuery : "";
    const url = query ? `${newPath}${query}` : newPath;

    navigate(url, { replace: true });
  };

  return (
    <div className="home-content">
      {organizationDetails && (
        <div className="cards-container single-card">
          <div className="custom-card single-card">
            {organizationDetails.campaigns &&
            organizationDetails.campaigns.length > 0 ? (
              <>
                <div className="embed-organization-logo-div">
                  <img
                    className="embed-organization-logo-img"
                    src={organizationDetails.logo}
                    alt="organization-logo"
                  />
                </div>
                <h5 className="embed-organization-name-h5">
                  {organizationDetails.name}
                </h5>
                <div className="campaign-msg embed">
                  {t("OrganizationDetails.select_a_campaign")}
                </div>
                <div className="campaign-box embed">
                  {organizationDetails.campaigns
                    .filter((campaign) => !campaign.hidden)
                    .map((campaign) => {
                      const percentage = (
                        (campaign.raisedAmount / campaign.targetAmount) *
                        100
                      ).toFixed(2);
                      const width =
                        percentage >= 100 || !isFinite(percentage)
                          ? "100%"
                          : `${percentage}%`;
                      const backgroundColor = "#007c16";
                      return (
                        <div
                          className="campaign embed"
                          onClick={() => handleCampaignClick(campaign)}
                          key={campaign.tagNumber}
                        >
                          <div className="campaign-icon embed">
                            <img
                              className="campaign-icon-img"
                              alt="campaign-icon"
                              src={campaign.icon.filename}
                            />
                          </div>
                          <div className="campaign-text embed">
                            {campaign.name}
                          </div>
                          {campaign.targetAmount > 0 && (
                            <div className="campaign-progress-bar">
                              <div
                                className="progress-bar"
                                style={{
                                  width,
                                  background: backgroundColor,
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div className="error-msg" style={{ width: "100%" }}>
                This organization has no campaigns associated with it.
              </div>
            )}
          </div>
        </div>
      )}

      {/* footer */}
      <div className="embedded-footer-box">
        <div>
          <span>
            <Icon icon={basic_lock} size={22} />
          </span>
          <span className="secure-donation">
            {t("OrganizationDetails.secure_donation")}
          </span>
        </div>

        <div className="powered-by">
          {t("Embed.powered_by")}
          <img src={logo} alt="logo" />
        </div>

        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            English
          </button>
        )}
      </div>
    </div>
  );
};

export default EmbeddedOrganizationDetails;
