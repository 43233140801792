import React, { useState, useEffect } from "react";
import i18next from "i18next";
import EmbeddedOrganizationDetails from "./EmbeddedOrganizationDetails";
import EmbeddedCharityDetails from "./EmbeddedCharityDetails";
import EmbeddedHowOften from "./EmbeddedHowOften";
import FeeScreen from "./FeeScreen";
import AddInformation from "./AddInformation";
import SuccessMsgScreen from "./SuccessMsgScreen";

// Helper function to get the first future date that matches the recurringDay
const getNextMatchingDay = (recurringDay) => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // getDay() gives 0-6 (0 is Sunday, 6 is Saturday)

  // Adjust recurringDay to match 0-6 range, where 1 is Monday and 7 is Sunday
  const adjustedRecurringDay = recurringDay % 7; // Recurring day (1-7), convert 7 to 0 for Sunday

  // If the current day is the same or before the recurring day, select this week's day
  if (adjustedRecurringDay >= currentDayOfWeek) {
    currentDate.setDate(
      currentDate.getDate() + (adjustedRecurringDay - currentDayOfWeek)
    );
  } else {
    // If the recurring day is earlier in the week, select the next week's matching day
    currentDate.setDate(
      currentDate.getDate() + (7 - (currentDayOfWeek - adjustedRecurringDay))
    );
  }

  return currentDate;
};

const EmbeddedOrganization = ({
  hostName,
  userData,
  organizationDetails,
  charityDetails,
  setCharityDetails,
  step,
  setStep,
  paths,
  vTag,
  currentQuery,
  quantity,
  setQuantity,
  amountsWithoutZero,
}) => {
  // states

  // amount
  const [amount, setAmount] = useState(0);

  // update amount
  useEffect(() => {
    if (charityDetails?.amounts?.length > 0) {
      if (charityDetails.amounts.includes(0)) {
        setAmount(0);
      } else {
        setAmount(charityDetails.amounts[0]);
      }
    }
  }, [charityDetails]);

  const [newAmount, setNewAmount] = useState(parseFloat(amount));
  const [amountValid, setAmountValid] = useState(false);

  // onetime / recurring
  const [howOften, setHowOften] = useState("");
  useEffect(() => {
    if (charityDetails?.frequency?.length > 0) {
      if (charityDetails.frequency.includes("ONETIME")) {
        setHowOften("ONETIME");
      } else {
        setHowOften(charityDetails.frequency[0]);
      }
    }
  }, [charityDetails]);
  const [recurringValue, setRecurringValue] = useState(null);
  const [validRecurring, setValidRecurring] = useState(true);
  const [specifyTime, setSpecifyTime] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  // useEffect to update startDate when charityDetails and howOften become available
  useEffect(() => {
    if (howOften === "WEEKLY" && charityDetails?.recurringDay) {
      const nextMatchingDate = getNextMatchingDay(charityDetails.recurringDay);
      setStartDate(nextMatchingDate);
    }
  }, [charityDetails, howOften]);

  // hiding the past dates
  const isSelectableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

    // If the user has selected WEEKLY and recurringDay is provided
    if (howOften === "WEEKLY" && charityDetails.recurringDay) {
      // Get the weekday of the selected date (0 for Sunday, 6 for Saturday)
      const selectedWeekday = date.getDay() === 0 ? 7 : date.getDay(); // Adjust for Sunday being 0

      // Only allow future dates that match the recurringDay
      return (
        date >= currentDate && selectedWeekday === charityDetails.recurringDay
      );
    }

    return date >= currentDate;
  };

  // fees
  const [fees, setFees] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  // payment
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState("");

  // success
  const [successMsg, setSuccessMsg] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // language code
  const [lang, setLang] = useState();
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language) {
      setLang(language);
    }
  }, [lang]);

  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);
  };

  return (
    <div className={userData ? "main-container logged-in" : "main-container"}>
      <div className="dashboard-container">
        {step === 0 && (
          <EmbeddedOrganizationDetails
            organizationDetails={organizationDetails}
            setCharityDetails={setCharityDetails}
            step={step}
            setStep={setStep}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            setQuantity={setQuantity}
            setAmount={setAmount}
          />
        )}
        {step === 1 && (
          <EmbeddedCharityDetails
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            setAmount={setAmount}
            amountValid={amountValid}
            setAmountValid={setAmountValid}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            quantity={quantity}
            setQuantity={setQuantity}
            charityDetails={charityDetails}
            amountsWithoutZero={amountsWithoutZero}
          />
        )}
        {step === 2 && !charityDetails.enableQuantity && (
          <EmbeddedHowOften
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            setAmount={setAmount}
            amountValid={amountValid}
            setAmountValid={setAmountValid}
            howOften={howOften}
            setHowOften={setHowOften}
            recurringValue={recurringValue}
            setRecurringValue={setRecurringValue}
            validRecurring={validRecurring}
            setValidRecurring={setValidRecurring}
            specifyTime={specifyTime}
            setSpecifyTime={setSpecifyTime}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            startDate={startDate}
            setStartDate={setStartDate}
            charityDetails={charityDetails}
            isSelectableDate={isSelectableDate}
          />
        )}
        {step === 3 && !charityDetails.enableQuantity && (
          <FeeScreen
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            amountValid={amountValid}
            validRecurring={validRecurring}
            fees={fees}
            setFees={setFees}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            charityDetails={charityDetails}
          />
        )}
        {step === 4 && (
          <AddInformation
            hostName={hostName}
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            amountValid={amountValid}
            howOften={howOften}
            setHowOften={setHowOften}
            recurringValue={recurringValue}
            validRecurring={validRecurring}
            specifyTime={specifyTime}
            fees={fees}
            setFees={setFees}
            isChecked={isChecked}
            cardHolderName={cardHolderName}
            setCardHolderName={setCardHolderName}
            cardHolderEmail={cardHolderEmail}
            setCardHolderEmail={setCardHolderEmail}
            setSuccessMsg={setSuccessMsg}
            setPaymentSuccess={setPaymentSuccess}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            vTag={vTag}
            currentQuery={currentQuery}
            startDate={startDate}
            quantity={quantity}
            charityDetails={charityDetails}
          />
        )}
        {step === 5 && (
          <SuccessMsgScreen
            organizationDetails={organizationDetails}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            howOften={howOften}
            recurringValue={recurringValue}
            specifyTime={specifyTime}
            fees={fees}
            cardHolderName={cardHolderName}
            cardHolderEmail={cardHolderEmail}
            successMsg={successMsg}
            paymentSuccess={paymentSuccess}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            startDate={startDate}
            quantity={quantity}
            charityDetails={charityDetails}
          />
        )}
      </div>
    </div>
  );
};

export default EmbeddedOrganization;
