import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { plus } from "react-icons-kit/entypo/plus";
import { ClapSpinner } from "react-spinners-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import example from "../../images/receipt.png";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const LinkReceiptModal = ({
  hostName,
  setLoggedInUserData,
  setLinkDonationModal,
}) => {
  // naviagte
  const navigate = useNavigate();

  // general states
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [linkValue, setLinkValue] = useState(null);

  // backdrop click
  const handleBackdropClick = (e) => {
    if (
      e.target.classList.contains("backdrop") ||
      e.target.classList.contains("link-cross-icon")
    ) {
      if (loader === false) {
        setLinkDonationModal(false);
      } else {
        setLinkDonationModal(true);
      }
    } else {
      setLinkDonationModal(true);
    }
  };

  // link donation
  const linkDonation = (e) => {
    e.preventDefault();
    setLoader(true);
    let user = localStorage.getItem("user");
    const language = localStorage.getItem("i18nextLng");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        linkCode: linkValue,
      };
      axios
        .post(
          `${hostName}api/v1/donor/linkDonation?language=${language}`,
          data,
          axiosConfig
        )
        .then((res) => {
          setLoader(false);
          setError("");
          setSuccessMsg(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          setSuccessMsg("");
          setError(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            setLinkDonationModal(false);
            navigate("/signin");
          }
        });
    }
  };

  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
        onClick={handleBackdropClick}
      >
        <motion.div
          className="link-receipt-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <header className="link-receipt-modal-header">
            <h4 className="modal-header-heading">
              {t("LinkReceipt.link_donation")}
            </h4>
            <button
              type="button"
              className="link-cross-icon"
              onClick={() => setLinkDonationModal(false)}
              disabled={loader}
            >
              X
            </button>
          </header>
          <div className="link-receipt-box">
            {loader && (
              <div className="link-loading-screen">
                <ClapSpinner size={30} color="#007c16" loading={loader} />
              </div>
            )}
            <form className="custom-form" onSubmit={(e) => linkDonation(e)}>
              <div className="linking-form">
                <MDBInput
                  label={t("LinkReceipt.digit_code")}
                  id="form1"
                  type="text"
                  required
                  readOnly={loader}
                  value={linkValue || ""}
                  onChange={(e) => setLinkValue(e.target.value)}
                />
                <MDBBtn
                  type="submit"
                  className="link-attach-btn"
                  disabled={loader}
                >
                  <Icon icon={plus} />
                </MDBBtn>
              </div>
              {error !== "" ? (
                <div className="error-msg">{error}.</div>
              ) : (
                <>
                  {successMsg !== "" && (
                    <div
                      className="user-message"
                      style={{ marginTop: `${5}px` }}
                    >
                      {successMsg}.
                    </div>
                  )}
                </>
              )}
            </form>
            <div className="example-img">
              <img src={example} alt="example" />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LinkReceiptModal;
