import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtn } from "mdb-react-ui-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const BannerModal = ({ setBannerModal, bannerObj }) => {
  // close modal
  const handleCloseModal = () => {
    setBannerModal(false);
  };

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AnimatePresence>
      <motion.div
        className="blurry-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="popup-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="popup-header">
            <div>Banner</div>
            <div
              className="close-icon"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="popup-body" style={{ padding: "8px" }}>
            <div
              className="banner-img-container"
              style={{
                background: isLoaded ? "transparent" : "#f7f7f7",
              }}
            >
              <img
                src={bannerObj.banner}
                alt="banner"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 500);
                }}
                style={{
                  display: isLoaded ? "block" : "none",
                }}
              />
            </div>

            <MDBBtn
              type="button"
              className="mdb-cancel-btn"
              onClick={handleCloseModal}
            >
              CLOSE
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BannerModal;
