import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import Swal from "sweetalert2";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const RecurringModal = ({
  hostName,
  userData,
  setLoggedInUserData,
  setRecurringModal,
  recurringDetails,
}) => {
  // loading states
  const [loader, setLoader] = useState(false); // amount
  const [closeAcLoader, setCloseAcLoader] = useState(false);
  const [updateCardLoader, setUpdateCardLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [holdLoader, setHoldLoader] = useState(false);

  // step
  const [step, setStep] = useState(1);

  // update card
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [updateCardError, setUpdateCardError] = useState(null);

  // update expiry month and expiry year from card obj
  useEffect(() => {
    // Split the card expiry string into month and year
    const [month, year] = recurringDetails.cardExpiry.split("/");

    // Update the states
    setExpiryMonth(month);
    setExpiryYear(year);
  }, [recurringDetails]);

  // Function to generate the month options
  const generateMonthOptions = () => {
    const monthOptions = [];

    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : `${i}`;
      monthOptions.push({
        value: monthValue,
        label: monthValue,
      });
    }

    return monthOptions;
  };

  // Generate the month options
  const expiryMonthOptions = generateMonthOptions();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Function to generate the expiry year options
  const generateExpiryYearOptions = (startYear, numberOfYears) => {
    const expiryYearOptions = [];

    for (let i = 0; i < numberOfYears; i++) {
      const year = startYear + i;
      expiryYearOptions.push({
        value: String(year).slice(-2),
        label: String(year),
      });
    }

    return expiryYearOptions;
  };

  // Generate the expiry year options for the next 15 years
  const expiryYearOptions = generateExpiryYearOptions(currentYear, 15);

  // amount
  const [amount, setAmount] = useState(parseFloat(recurringDetails.amount)); // float
  const [amountError, setAmountError] = useState(null);

  // amount change
  const handleAmount = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue); // string
  };

  // navigate
  const navigate = useNavigate();

  // update amount
  const updateAmount = (e) => {
    e.preventDefault();
    setLoader(true);
    setAmountError(null);
    // hitting api
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios
      .put(
        `${hostName}api/v1/donor/updateRecurring?amount=${parseFloat(
          amount
        )}&recurringTag=${recurringDetails.recurringTag}&type=update`,
        {},
        axiosConfig
      )
      .then(() => {
        recurringDetails.amount = parseFloat(amount);
        setStep(1);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else if (err.response.data.message) {
          setAmountError(err.response.data.message);
        } else {
          setAmountError(err.message);
        }
      })
      .finally(() => setLoader(false));
  };

  // update card
  const updateCard = (e) => {
    e.preventDefault();
    setUpdateCardLoader(true);
    setUpdateCardError(null);
    // hitting api
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios
      .put(
        `${hostName}api/v1/donor/updateCard`,
        {
          recurringTag: recurringDetails.recurringTag,
          cardHolderName,
          cardNumber,
          expiryMonth,
          expiryYear,
        },
        axiosConfig
      )
      .then((res) => {
        recurringDetails.cardExpiry = res.data.cardExpiry;
        recurringDetails.cardNumber = res.data.cardNumber;
        // recurringDetails.cardHolderName = res.data.cardHolderName;
        setStep(1);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else if (err.response.data.message) {
          setUpdateCardError(err.response.data.message);
        } else {
          setUpdateCardError(err.message);
        }
      })
      .finally(() => setUpdateCardLoader(false));
  };

  // close account
  const [closeAcError, setCloseAcError] = useState(null);
  const closeRecurringAccount = (recurring) => {
    Swal.fire({
      title: `${t("Swal.warning")}`,
      text: `${t("RecurringModal.are_you_sure")}`,
      icon: "warning",
      customClass: {
        container: "custom-sweetalert-container",
        confirmButton: "custom-ok-button",
      },
      didOpen: () => {
        // Set the zIndex for the SweetAlert container
        document.querySelector(
          ".custom-sweetalert-container"
        ).style.zIndex = 999999;

        // customozie ok btn
        const okBtn = document.querySelector(".custom-ok-button");
        okBtn.style.backgroundColor = "#007c16";
        okBtn.style.boxShadow = "none";
      },
      showCancelButton: true,
      confirmButtonText: `${t("RecurringModal.yes")}`,
      cancelButtonText: `${t("RecurringModal.no")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        setCloseAcLoader(true);
        setCloseAcError(null);
        // hitting api
        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userData.token}`,
          },
        };
        axios
          .post(
            `${hostName}api/v1/donor/closeRecurring?recurringTag=${recurring.recurringTag}&type=close`,
            {},
            axiosConfig
          )
          .then(() => {
            recurring.status = "CLOSED";
            setHoldError(null);
            setStatusError(null);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              navigate("/signin");
            } else if (err.response.data.message) {
              setCloseAcError(err.response.data.message);
            } else {
              setCloseAcError(err.message);
            }
          })
          .finally(() => setCloseAcLoader(false));
      }
    });
  };

  // translation
  const { t } = useTranslation();

  // status
  const [statusError, setStatusError] = useState(null);

  // make status active
  const statusActive = (e) => {
    e.preventDefault();
    setStatusLoader(true);
    setStatusError(null);
    // hitting api
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios
      .put(
        `${hostName}api/v1/donor/switchRecurringStatus`,
        {
          recurringTag: recurringDetails.recurringTag,
          type: "active",
          processBackPayments,
        },
        axiosConfig
      )
      .then(() => {
        recurringDetails.status = "ACTIVE";
        setProcessBackPaymentsModal(false);
        setHoldError(null);
        setCloseAcError(null);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else if (err.response.data.messageDetails) {
          setStatusError(err.response.data.messageDetails);
        } else {
          setStatusError(err.message);
        }
      })
      .finally(() => setStatusLoader(false));
  };

  // hold
  const [holdError, setHoldError] = useState(null);

  // hold recurring
  const holdRecurring = () => {
    Swal.fire({
      title: `${t("Swal.warning")}`,
      text: `${t("Swal.textMsg")}`,
      icon: "warning",
      customClass: {
        container: "custom-sweetalert-container",
        confirmButton: "custom-ok-button",
      },
      didOpen: () => {
        // Set the zIndex for the SweetAlert container
        document.querySelector(
          ".custom-sweetalert-container"
        ).style.zIndex = 999999;

        // customozie ok btn
        const okBtn = document.querySelector(".custom-ok-button");
        okBtn.style.backgroundColor = "#007c16";
        okBtn.style.boxShadow = "none";
      },
      showCancelButton: true,
      confirmButtonText: `${t("RecurringModal.yes")}`,
      cancelButtonText: `${t("RecurringModal.no")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        setHoldLoader(true);
        setHoldError(null);
        // hitting api
        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userData.token}`,
          },
        };
        axios
          .put(
            `${hostName}api/v1/donor/switchRecurringStatus`,
            {
              recurringTag: recurringDetails.recurringTag,
              type: "hold",
            },
            axiosConfig
          )
          .then(() => {
            recurringDetails.status = "HOLD";
            setStatusError(null);
            setCloseAcError(null);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              navigate("/signin");
            } else if (err.response.data.messageDetails) {
              setHoldError(err.response.data.messageDetails);
            } else {
              setHoldError(err.message);
            }
          })
          .finally(() => setHoldLoader(false));
      }
    });
  };

  // transactions popup
  const [transactionsModal, setTransactionsModal] = useState(false);

  // popup variants
  const popupVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // convert invoice to title case
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // process back payments modal
  const [processBackPaymentsModal, setProcessBackPaymentsModal] =
    useState(false);
  const [processBackPayments, setProcessBackPayments] = useState(true);

  const handleCloseModalProcessPayments = () => {
    if (!statusLoader) {
      setProcessBackPaymentsModal(false);
    }
  };

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (recurringDetails && recurringDetails.transactions.length > 0) {
      const calculatedAmounts = recurringDetails.transactions.map((item) => {
        const { approvedAmount } = item;
        return approvedAmount;
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [recurringDetails]);

  // backgroundColor of last static row
  const [lastRowBgColor, setLastRowBgColor] = useState("#fff");
  useEffect(() => {
    if (recurringDetails && recurringDetails.transactions.length > 0) {
      // data is neccessary for proper indexing, index is always the second parameter
      recurringDetails.transactions.forEach((data, index) => {
        const bgColor = index % 2 === 0 ? "#fff" : "#fafafa";
        setLastRowBgColor(bgColor);
      });
    }
  }, [recurringDetails]);

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
        key="drawer-backdrop"
      >
        <motion.div
          className="receipt-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          key="drawer-modal"
        >
          {/* header */}
          <header className="receipt-modal-header">
            <h4>
              {step === 1 && <>{t("RecurringModal.recurring_details")}</>}
              {step === 2 && <>{t("RecurringModal.update_amount")}</>}
              {step === 3 && (
                <>
                  {t("RecurringModal.update_card")} -{" "}
                  {recurringDetails.cardNumber.slice(-4)}
                </>
              )}
            </h4>

            {/* cross btn disabled on every 5 loadings */}
            <button
              type="button"
              className="cross-icon donations"
              onClick={() => setRecurringModal(false)}
              disabled={
                closeAcLoader ||
                loader ||
                updateCardLoader ||
                statusLoader ||
                holdLoader
              }
            >
              X
            </button>
          </header>

          {/* drawer loading for close account and switch to hold loading */}
          {(holdLoader || closeAcLoader) && (
            <div className="drawer-loading">
              <ClapSpinner
                loading={holdLoader || closeAcLoader}
                color="#007c16"
                size={30}
              />
            </div>
          )}

          {/* step 1 */}
          {step === 1 && (
            <>
              {/* organization/charity with amount */}
              <div className="receipt-info">
                <div>
                  <h4>{recurringDetails.organizationName}</h4>
                  <h6 className="receipt-campaign">
                    {recurringDetails.campaign}
                  </h6>

                  {/* view transaction btn that will open popup and will remain disabled on status hold and close account loadings */}
                  {recurringDetails.transactions.length > 0 && (
                    <button
                      type="button"
                      className="view-transactions-link"
                      onClick={() => setTransactionsModal(true)}
                      disabled={closeAcLoader || holdLoader || statusLoader}
                    >
                      {t("RecurringModal.view_transactions")}
                    </button>
                  )}
                </div>

                {/* change amount div */}
                <div className="change-amount-div">
                  <h2>
                    {recurringDetails.currencySymbol}
                    {recurringDetails.amount}
                  </h2>

                  {/* change amount btn */}
                  {recurringDetails.status === "ACTIVE" && (
                    <>
                      {recurringDetails.allowRecurringModification ? (
                        <MDBBtn
                          type="button"
                          className="change-amount-btn"
                          onClick={() => setStep(2)}
                          disabled={closeAcLoader || holdLoader || statusLoader}
                        >
                          {t("RecurringModal.change_amount")}
                        </MDBBtn>
                      ) : (
                        <Tippy content={t("RecurringModal.not_allowed_msg")}>
                          <button
                            type="button"
                            className="btn change-amount-btn"
                            style={{ opacity: 0.5 }}
                          >
                            {t("RecurringModal.change_amount")}
                          </button>
                        </Tippy>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* other details */}
              <div className="receipt-other-info recurring">
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.account_id")}
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.accountId}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">{t("RecurringModal.invoice")}</h5>
                  <h5 className="receipt-value">{recurringDetails.invoice}</h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">{t("RecurringModal.billing")}</h5>
                  <h5 className="receipt-value">
                    {recurringDetails.billingPeriod}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.card_no")}.
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.cardNumber}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.card_expiry")}
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.cardExpiry}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.billing_expiry")}
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.billingExpiry}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.created_at")}
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.createDateTime}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">
                    {t("RecurringModal.last_updated")}
                  </h5>
                  <h5 className="receipt-value">
                    {recurringDetails.updateDateTime}
                  </h5>
                </div>
                <div className="receipt-row">
                  <h5 className="receipt-key">{t("RecurringModal.status")}</h5>
                  <h5 className="receipt-value closed">
                    {recurringDetails.status}
                  </h5>
                </div>
                {recurringDetails.historyNotes && (
                  <>
                    <div className="receipt-row">
                      <h5 className="receipt-key">
                        {t("RecurringModal.history_notes")}
                      </h5>
                    </div>
                    <div className="notes-div">
                      <div>
                        {recurringDetails.historyNotes
                          .split("<br/>")
                          .map((line, index) => (
                            <p key={index} style={{ marginBottom: 4 + "px" }}>
                              {line}
                            </p>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* hold error incase */}
              {holdError && (
                <div className="error-msg" style={{ width: "100%" }}>
                  {holdError}
                </div>
              )}

              {/* close account error incase */}
              {closeAcError && (
                <div className="error-msg" style={{ width: "100%" }}>
                  {closeAcError}
                </div>
              )}

              {/* if status is not closed then we will show update card*/}
              {/* after that we will check if status is active then we will show hold btn otherwise active btn*/}
              {recurringDetails.status !== "CLOSED" && (
                <>
                  {/* btns row */}
                  <div className="close-ac-div">
                    {/* update card btn */}
                    <button
                      type="button"
                      className="close-ac-btn"
                      onClick={() => setStep(3)}
                      disabled={closeAcLoader || holdLoader || statusLoader}
                    >
                      {t("RecurringModal.update_card")}
                    </button>

                    {/* we will check if status is active then we will show hold btn otherwise active btn */}
                    {recurringDetails.status === "ACTIVE" ? (
                      <>
                        {/* switch to hold if allowRecurringModification is true */}
                        {recurringDetails.allowRecurringModification ? (
                          <button
                            type="button"
                            className="close-ac-btn"
                            onClick={holdRecurring}
                            disabled={
                              closeAcLoader || holdLoader || statusLoader
                            }
                          >
                            {t("RecurringModal.switch_to_hold")}
                          </button>
                        ) : (
                          <Tippy content={t("RecurringModal.not_allowed_msg")}>
                            <button
                              type="button"
                              className="close-ac-btn"
                              style={{ opacity: 0.5 }}
                            >
                              {t("RecurringModal.switch_to_hold")}
                            </button>
                          </Tippy>
                        )}
                      </>
                    ) : (
                      <>
                        {/* switch to active without checking if allowRecurringModification is true */}
                        <button
                          type="button"
                          className="close-ac-btn"
                          onClick={() => setProcessBackPaymentsModal(true)}
                          disabled={closeAcLoader || holdLoader || statusLoader}
                        >
                          {t("RecurringModal.switch_to_active")}
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}

              {/* close btn + close recurring account btn / switch to active btn */}
              <div className="close-ac-div">
                {/* close btn */}
                <MDBBtn
                  type="button"
                  className="text-dark"
                  color="light"
                  disabled={closeAcLoader || statusLoader || holdLoader}
                  onClick={() => setRecurringModal(false)}
                >
                  {t("RecurringModal.close")}
                </MDBBtn>

                {/* if status is active then we will show close account btn otherwise 
                we will check if status is closed then we will show switch to active btn */}
                {recurringDetails.status === "ACTIVE" ? (
                  <>
                    {/* show close recurring account if status and allowRecurringModification is true */}
                    {recurringDetails.allowRecurringModification ? (
                      <button
                        type="button"
                        className="close-ac-btn"
                        onClick={() => closeRecurringAccount(recurringDetails)}
                        disabled={closeAcLoader || holdLoader || statusLoader}
                      >
                        {t("RecurringModal.close_recurring_account")}
                      </button>
                    ) : (
                      <Tippy content={t("RecurringModal.not_allowed_msg")}>
                        <button
                          type="button"
                          className="close-ac-btn"
                          style={{ opacity: 0.5 }}
                        >
                          {t("RecurringModal.close_recurring_account")}
                        </button>
                      </Tippy>
                    )}
                  </>
                ) : (
                  <>
                    {/* if status is closed then show switch to active without checking allow recurring modification */}
                    {recurringDetails.status === "CLOSED" && (
                      <button
                        type="button"
                        className="close-ac-btn"
                        onClick={() => setProcessBackPaymentsModal(true)}
                        disabled={closeAcLoader || holdLoader || statusLoader}
                      >
                        {t("RecurringModal.switch_to_active")}
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {/* step 2 */}
          {step === 2 && (
            <>
              {/* organization/charity with amount */}
              <div className="receipt-info">
                <div>
                  <h4>{recurringDetails.organizationName}</h4>
                  <h6 className="receipt-campaign">
                    {recurringDetails.campaign}
                  </h6>
                </div>
                <div className="change-amount-div">
                  <h2>
                    {recurringDetails.currencySymbol}
                    {recurringDetails.amount}
                  </h2>
                </div>
              </div>
              {/* update form */}
              <div className="donation-text update-amount">
                {t("RecurringModal.update_amount")}
                <span
                  className="minimum-amount-msg"
                  style={{ marginLeft: 10 + "px" }}
                >
                  {t("RecurringModal.minimum_amount")}{" "}
                  {recurringDetails.currencySymbol}
                  {recurringDetails.campaignMinimumAmount}
                </span>
              </div>
              <form onSubmit={updateAmount} style={{ marginTop: "20px" }}>
                <CustomTextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={amount}
                  onChange={handleAmount}
                  title=""
                  required
                  placeholder={t("RecurringModal.enter_amount")}
                  readOnly={loader}
                  helperText={
                    amount !== "" &&
                    parseFloat(amount) <
                      parseFloat(recurringDetails.campaignMinimumAmount)
                      ? `Please enter an amount greater than or equal to ${recurringDetails.campaignMinimumAmount}`
                      : ""
                  }
                  error={
                    amount !== "" &&
                    parseFloat(amount) <
                      parseFloat(recurringDetails.campaignMinimumAmount)
                  }
                />
                {amountError && (
                  <div className="error-msg" style={{ width: "100%" }}>
                    {amountError}
                  </div>
                )}
                <div className="recurring-action-btns">
                  <MDBBtn
                    type="button"
                    onClick={() => setStep(1)}
                    className="text-dark"
                    color="light"
                    disabled={loader}
                  >
                    {t("RecurringModal.cancel")}
                  </MDBBtn>
                  <MDBBtn
                    type="submit"
                    className="recurring-update"
                    disabled={
                      loader ||
                      parseFloat(amount) ===
                        parseFloat(recurringDetails.amount) ||
                      parseFloat(amount) <
                        parseFloat(recurringDetails.campaignMinimumAmount) ||
                      amount === ""
                    }
                  >
                    {loader ? (
                      <ClapSpinner size={16} color="#fff" loading={loader} />
                    ) : (
                      <>{t("RecurringModal.update")}</>
                    )}
                  </MDBBtn>
                </div>
              </form>
            </>
          )}
          {/* step 3 */}
          {step === 3 && (
            <form onSubmit={updateCard} style={{ marginTop: "20px" }}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("DonationModal.cardholder_name")}</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={cardHolderName}
                    onChange={(e) => setCardHolderName(e.target.value)}
                    placeholder={t("DonationModal.cardholder_name")}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("DonationModal.card_number")}</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={cardNumber}
                    onChange={(e) => {
                      const onlyDigits = e.target.value.replace(/\D/g, "");
                      setCardNumber(onlyDigits);
                    }}
                    placeholder={t("DonationModal.card_number")}
                    pattern=".{15,}"
                    title="Please enter a card number with at least 15 characters"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("DonationModal.expiry_month")}</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={expiryMonthOptions.find(
                      (option) => option.value === expiryMonth
                    )}
                    onChange={(option) => setExpiryMonth(option.value)}
                    options={expiryMonthOptions}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("DonationModal.expiry_year")}</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="top"
                    value={expiryYearOptions.find(
                      (option) => option.value === expiryYear
                    )}
                    onChange={(option) => setExpiryYear(option.value)}
                    options={expiryYearOptions}
                  />
                </MDBCol>
              </MDBRow>
              {/* update card error */}
              {updateCardError && (
                <div className="error-msg" style={{ width: "100%" }}>
                  {updateCardError}
                </div>
              )}
              <div className="recurring-action-btns">
                <MDBBtn
                  type="button"
                  onClick={() => setStep(1)}
                  className="text-dark"
                  color="light"
                  disabled={updateCardLoader}
                >
                  {t("RecurringModal.cancel")}
                </MDBBtn>
                <MDBBtn
                  type="submit"
                  className="recurring-update"
                  disabled={updateCardLoader}
                >
                  {updateCardLoader ? (
                    <ClapSpinner
                      size={16}
                      color="#fff"
                      loading={updateCardLoader}
                    />
                  ) : (
                    <>{t("RecurringModal.update")}</>
                  )}
                </MDBBtn>
              </div>
            </form>
          )}
        </motion.div>
      </motion.div>

      {/* transactions modal */}
      {transactionsModal && (
        <motion.div
          className="blurry-backdrop"
          variants={backVariants}
          initial="hidden"
          animate="visible"
          key="transactions-backdrop"
        >
          <motion.div
            className="popup-modal w-900"
            variants={popupVariants}
            initial="hidden"
            animate="visible"
            key="transactions-popup"
          >
            <div className="popup-header">
              {/* same translation eng and fr */}
              <div>Transactions</div>
              <div
                className="close-icon"
                onClick={() => setTransactionsModal(false)}
              >
                <Icon icon={x} size={16} />
              </div>
            </div>
            <div className="popup-body">
              <div className="transactions-table">
                <MDBTable align="middle">
                  <MDBTableHead className="thead">
                    <tr>
                      <th scope="col">
                        {toTitleCase(t("RecurringModal.invoice"))}
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        {t("ReceiptModal.amount")}
                      </th>
                      <th scope="col">{t("RecurringModal.sequence_number")}</th>
                      <th scope="col">
                        {t("RecurringModal.transaction_date")}
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {recurringDetails.transactions.map((data, index) => (
                      <tr
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                        }}
                        key={data.tagNumber}
                      >
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.invoice && <>{data.invoice}</>}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                            }}
                          >
                            {data.approvedAmount && (
                              <>
                                {recurringDetails.currencySymbol}
                                {data.approvedAmount}
                              </>
                            )}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.sequenceNumber && <>{data.sequenceNumber}</>}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.transactionDate && (
                              <>{data.transactionDate}</>
                            )}
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr
                      style={{
                        backgroundColor:
                          lastRowBgColor === "#fff" ? "#fafafa" : "#fff",
                      }}
                    >
                      <td>
                        <p
                          className="fw-bold mb-1"
                          style={{ color: "#007C16" }}
                        >
                          Total
                        </p>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <p className="fw-bold mb-1">
                          {recurringDetails.currencySymbol}
                          {sum}
                        </p>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={() => setTransactionsModal(false)}
                >
                  {t("RecurringModal.close")}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}

      {/* process back payments */}
      {processBackPaymentsModal && (
        <motion.div
          className="blurry-backdrop"
          variants={backVariants}
          initial="hidden"
          animate="visible"
          key="process-backdrop"
        >
          <motion.div
            className="popup-modal"
            variants={popupVariants}
            initial="hidden"
            animate="visible"
            key="process-modal"
          >
            <div className="popup-header">
              <div>{t("RecurringModal.switch_to_active")}</div>
              <div
                className="close-icon"
                onClick={handleCloseModalProcessPayments}
              >
                <Icon icon={x} size={16} />
              </div>
            </div>
            <div className="popup-body">
              <form onSubmit={statusActive} style={{ marginTop: "20px" }}>
                <h6>{t("RecurringModal.process_back_payments")}</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={processBackPayments}
                    onChange={() =>
                      setProcessBackPayments(!processBackPayments)
                    }
                    disabled={statusLoader}
                  />
                  <span className="slider round"></span>
                </label>

                {statusError && (
                  <div className="error-msg" style={{ width: "100%" }}>
                    {statusError}
                  </div>
                )}

                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={handleCloseModalProcessPayments}
                  >
                    {t("TaxReceipts.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="submit addModal"
                    disabled={statusLoader}
                  >
                    {statusLoader ? (
                      <ClapSpinner loading={statusLoader} size={22} />
                    ) : (
                      <>{t("Content.active")}</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RecurringModal;
