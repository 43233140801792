/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ClapSpinner } from "react-spinners-kit";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import { useTranslation } from "react-i18next";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import ReceiptModal from "../../components/donations-tab/ReceiptModal";
import Select from "react-select";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// get user
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const Donations = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => {
  // per page options array
  const perPageOptions = [
    {
      value: "05",
      label: "05",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // user state
  const [user] = useState(getUser());

  // setting up pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState("20");

  // get donations states
  const [donationsError, setDonationsError] = useState(null);
  const [donationsLoader, setDonationsLoader] = useState(true);
  const [donationsList, setDonationsList] = useState(null);

  // navigate
  const navigate = useNavigate();

  // get donations
  useEffect(() => {
    if (user) {
      // set loader to true
      setDonationsLoader(true);

      // axios config
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      // hitting api
      axios
        .get(
          `${hostName}api/v1/donor/donations?page=${currentPage}&pageSize=${perPage}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            setDonationsList(res.data.data);
            setDonationsError(null);
            setTotalPages(res.data.links.lastPage);
            setCurrentPage(res.data.links.currentPage);
            setPerPage(res.data.links.perPage);
          } else {
            setDonationsError("You do not have transactions history.");
            setDonationsList(null);
          }
        })
        .catch((err) => {
          setDonationsError(err.message);
          setDonationsList(null);
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
        })
        .finally(() => setDonationsLoader(false));
    } else {
      navigate("/signin");
    }
  }, [userData, perPage, currentPage]);

  // handle per page
  const handlePerPage = (value) => {
    if (perPage != value) {
      setPerPage(value);
      setCurrentPage(1);
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    if (currentPage != value) {
      setCurrentPage(value);
    }
  };

  // handle receipt modal
  const [receiptModal, setReceiptModal] = useState(false);
  const [receiptDetails, setReceiptDetails] = useState(null);
  const handleReceiptModal = (receipt) => {
    setReceiptModal(true);
    setReceiptDetails(receipt);
  };

  // to hide body scroll for receipt modal
  useEffect(() => {
    if (receiptModal === true) {
      document.body.style.overflow = "hidden";
    } else if (receiptModal === false) {
      document.body.style.overflow = "auto";
    }
  }, [receiptModal]);

  // translation
  const { t } = useTranslation();

  return (
    <>
      <div className={userData ? "main-container logged-in" : "main-container"}>
        <Header
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          handleToggle={handleToggle}
          dropdownToggle={dropdownToggle}
          handleDropdownToggle={handleDropdownToggle}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setTaxReceiptModal={setTaxReceiptModal}
          setLinkDonationModal={setLinkDonationModal}
        />
        <div className="dashboard-container">
          <Sidebar userData={userData} toggled={toggled} />
          <div className="home-content">
            {donationsLoader ? (
              <main className="info-content-div" style={{ height: `${100}%` }}>
                <div
                  className="loading-screen"
                  style={{ background: "#f8f8f8" }}
                >
                  <ClapSpinner
                    size={30}
                    color="#007c16"
                    loading={donationsLoader}
                  />
                </div>
              </main>
            ) : (
              <>
                {/* error */}
                {donationsError ? (
                  <main
                    className="info-content-div"
                    style={{ height: `${100}%` }}
                  >
                    <div className="error-msg" style={{ marginTop: 0 }}>
                      {donationsError}
                    </div>
                  </main>
                ) : (
                  <>
                    {donationsList && donationsList.length > 0 && (
                      <MDBTable responsive>
                        <MDBTableHead>
                          <tr className="table-head">
                            <th scope="col" className="table-header-th">
                              {t("Donations.campaign")}/{t("Donations.charity")}
                            </th>
                            <th scope="col" className="table-header-th">
                              DATE
                            </th>
                            <th scope="col" className="table-header-th">
                              {t("Donations.source")}
                            </th>
                            <th scope="col" className="table-header-th">
                              {t("Donations.type")}
                            </th>
                            <th
                              scope="col"
                              className="table-header-th"
                              style={{ textAlign: "right" }}
                            >
                              {t("Donations.amount")}
                            </th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {donationsList.map((donation, index) => (
                            <tr
                              key={donation.tagNumber}
                              className="table-row"
                              onClick={() => handleReceiptModal(donation)}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#fff" : "#f8f8f8",
                              }}
                            >
                              <td>
                                <div>{donation.organization}</div>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    color: "#8f8f8f",
                                  }}
                                >
                                  {donation.campaign}
                                </div>
                              </td>
                              <td>{donation.inputDate}</td>
                              <td>{donation.nodeType}</td>
                              <td>{donation.cardType}</td>
                              <td style={{ textAlign: "right" }}>
                                ${donation.approvedAmount}
                              </td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    )}
                  </>
                )}
              </>
            )}
            <>
              {donationsList &&
                donationsList.length > 0 &&
                !donationsLoader && (
                  <div className="transactions-pagination">
                    <div className="per-page-and-pagination">
                      <div className="per-page">
                        <label>{t("Donations.rows_per_page")}</label>
                        <Select
                          value={perPageOptions.find(
                            (option) => option.value === perPage
                          )}
                          onChange={(option) => handlePerPage(option.value)}
                          options={perPageOptions}
                          styles={styles}
                          menuPlacement="top"
                        />
                      </div>
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
            </>
          </div>
        </div>
      </div>
      {receiptModal && (
        <ReceiptModal
          setReceiptModal={setReceiptModal}
          receiptDetails={receiptDetails}
        />
      )}
    </>
  );
};

export default Donations;
