import React from "react";

// components
import Header from "../../components/common/Header";
import Content from "../../components/home/Content";
import Sidebar from "../../components/common/Sidebar";

const Home = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setOrganizationPath,
  setOrganizationDetails,
  searchClass,
  toggleSearchAnimation,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => (
  <div className={userData ? "main-container logged-in" : "main-container"}>
    <Header
      hostName={hostName}
      userData={userData}
      setLoggedInUserData={setLoggedInUserData}
      handleToggle={handleToggle}
      dropdownToggle={dropdownToggle}
      handleDropdownToggle={handleDropdownToggle}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchClass={searchClass}
      setTaxReceiptModal={setTaxReceiptModal}
      setLinkDonationModal={setLinkDonationModal}
    />
    <div className="dashboard-container">
      <Sidebar userData={userData} toggled={toggled} />
      <Content
        hostName={hostName}
        userData={userData}
        setLoggedInUserData={setLoggedInUserData}
        setOrganizationPath={setOrganizationPath}
        setOrganizationDetails={setOrganizationDetails}
        toggleSearchAnimation={toggleSearchAnimation}
      />
    </div>
  </div>
);
export default Home;
