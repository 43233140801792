/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ClapSpinner } from "react-spinners-kit";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Icon from "react-icons-kit";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import Tippy from "@tippyjs/react";
import RegisterationModal from "./RegisterationModal";

function getCurrentPath() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const fullPath = location.pathname;
  // get second path
  const secondPath = fullPath.split("/")[2];
  return secondPath;
}

function getUserEmail() {
  let user = localStorage.getItem("user");
  let email;
  if (user) {
    user = JSON.parse(user);
    email = user.email;
  } else {
    email = "";
  }
  return email;
}

const LinkDonationComponent = ({ hostName, setLoggedInUserData, userData }) => {
  // translation
  const { t } = useTranslation();

  // states

  // transaction states
  const [thirteenDigitsCode] = useState(getCurrentPath());
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // link transaction to email states
  const [email, setEmail] = useState(getUserEmail());
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);

  // modal state
  const [registerationModal, setRegisterationModal] = useState(false);

  // hitting api
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
      },
    };
    axios
      .get(`${hostName}api/v1/link?linkCode=${thirteenDigitsCode}`, axiosConfig)
      .then((res) => {
        const dataArray = [];
        dataArray.push(res.data.data);
        setData(dataArray);
      })
      .catch((err) => {
        if (err.response && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // option states
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  // link donation/transaction to email event
  const navigate = useNavigate();
  const handleLinkDonations = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email)) {
      setEmailError(null);
      setEmailLoading(true);
      // checking if email exist
      axios
        .get(`${hostName}api/v1/auth/exist?email=${email}`)
        .then((res) => {
          if (res.data.exist) {
            const axiosConfig = {
              headers: {
                Accept: "application/json",
              },
            };
            // linking transaction
            axios
              .post(
                `${hostName}api/v1/link?transactionTag=${data[0].transactionTag}&invoice=${data[0].invoice}&email=${email}`,
                axiosConfig
              )
              .then(() => {
                if (userData) {
                  localStorage.setItem(
                    "successMsg",
                    "Your Donations has been linked successfully."
                  );
                  navigate("/dashboard");
                  setTimeout(() => {
                    localStorage.removeItem("successMsg");
                  }, 5000);
                } else {
                  localStorage.setItem(
                    "successMsg",
                    "Your Donations has been linked successfully."
                  );
                  navigate("/signin");
                  setTimeout(() => {
                    localStorage.removeItem("successMsg");
                  }, 5000);
                }
              })
              .catch((err) => {
                setEmailError(
                  `${err.message} in linking transaction. Please try again later.`
                );
              })
              .finally(() => setEmailLoading(false));
          } else {
            // get all options
            Promise.all([
              axios.get(`${hostName}api/v1/public/country`),
              axios.get(`${hostName}api/v1/public/state?countryCode=US`),
              axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
            ])
              .then(([countriesRes, usaStatesRes, provincesRes]) => {
                // Handle countries
                const countryOptions = countriesRes.data.map((country) => ({
                  value: country.code,
                  label: country.name,
                }));
                setCountries(countryOptions);

                // Handle USA states
                const usaStatesOptions = usaStatesRes.data.map((state) => ({
                  value: state.code,
                  label: state.name,
                }));
                setUsaStates(usaStatesOptions);

                // Handle Canada provinces
                const provincesOptions = provincesRes.data.map((province) => ({
                  value: province.code,
                  label: province.name,
                }));
                setProvinces(provincesOptions);

                // set registration modal to true
                setRegisterationModal(true);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => setEmailLoading(false));
          }
        })
        .catch((err) => {
          setEmailLoading(false);
          setEmailError(
            `${err.message} in verifying email existence. Please try again later.`
          );
        });
    } else {
      setEmailError("Email field should be a valid email address.");
    }
  };

  // to hide body scroll for receipt modal
  useEffect(() => {
    if (registerationModal === true) {
      document.body.style.overflow = "hidden";
    } else if (registerationModal === false) {
      document.body.style.overflow = "auto";
    }
  }, [registerationModal]);

  return (
    <>
      {registerationModal && (
        <RegisterationModal
          setRegisterationModal={setRegisterationModal}
          hostName={hostName}
          data={data}
          email={email}
          setLoggedInUserData={setLoggedInUserData}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
        />
      )}
      {loading ? (
        <main className="info-content-div" style={{ height: `${100}%` }}>
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={loading} />
          </div>
        </main>
      ) : (
        <>
          {error ? (
            <main className="info-content-div" style={{ height: `${100}%` }}>
              <div className="error-msg" style={{ marginTop: 0 }}>
                {error}
              </div>
            </main>
          ) : (
            <>
              {data && data.length > 0 ? (
                <>
                  <MDBTable responsive>
                    <MDBTableHead>
                      <tr className="table-head">
                        <th scope="col" className="table-header-th">
                          {t("QR_LinkPage.organization")}
                        </th>
                        <th scope="col" className="table-header-th">
                          {t("QR_LinkPage.campaign")}
                        </th>
                        <th scope="col" className="table-header-th">
                          {t("QR_LinkPage.date")}
                        </th>
                        <th scope="col" className="table-header-th">
                          {t("QR_LinkPage.invoice")}
                        </th>
                        <th scope="col" className="table-header-th">
                          {t("QR_LinkPage.amount")}
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {data.map((individualData, index) => (
                        <tr key={index} className="table-row">
                          <td>{individualData.organizationName}</td>
                          <td>{individualData.campaignName}</td>
                          <td>{individualData.transactionDate}</td>
                          <td>{individualData.invoice}</td>
                          <td>
                            {individualData.currencySymbol}
                            {individualData.approvedAmount}
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                  <form
                    className="form-group link-email-form"
                    onSubmit={handleLinkDonations}
                  >
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <span>{t("QR_LinkPage.enter_email")}</span>
                      <Tippy content={t("Signup.emailInfoMsg")}>
                        <span style={{ marginLeft: `${5}px` }}>
                          <Icon icon={alertCircle} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <div className="link-email-div">
                      <input
                        type="email"
                        className="edit-media-inputs"
                        required
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={userData}
                      />
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={emailLoading}
                      >
                        {emailLoading
                          ? t("QR_LinkPage.linking")
                          : t("QR_LinkPage.link")}
                      </button>
                    </div>
                    {emailError && (
                      <div className="error-msg">{emailError}</div>
                    )}
                  </form>
                </>
              ) : (
                <main
                  className="info-content-div"
                  style={{ height: `${100}%` }}
                >
                  <div className="error-msg">No Data Found</div>
                </main>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default LinkDonationComponent;
