/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import RecurringModal from "../../components/recurring-tab/RecurringModal";

const Recurring = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => {
  // states
  const [recurringError, setRecurringError] = useState(null);
  const [recurringLoader, setRecurringLoader] = useState(true);
  const [recurringList, setRecurringList] = useState(null);

  // navigate
  const navigate = useNavigate();

  // get recurring data
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      // hitting api
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios
        .get(`${hostName}api/v1/donor/recurring`, axiosConfig)
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            setRecurringList(res.data.data);
            setRecurringError(null);
          } else {
            setRecurringError("You do not have recurring transactions.");
            setRecurringList(null);
          }
        })
        .catch((err) => {
          setRecurringError(err.message);
          setRecurringList(null);
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
        })
        .finally(() => setRecurringLoader(false));
    } else {
      navigate("/signin");
    }
  }, []);

  // handle recurring modal
  const [recurringModal, setRecurringModal] = useState(false);
  const [recurringDetails, setRecurringDetails] = useState(null);
  const handleRecurringModal = (recurring) => {
    setRecurringModal(true);
    setRecurringDetails(recurring);
  };

  // to hide body scroll for recurring modal
  useEffect(() => {
    if (recurringModal === true) {
      document.body.style.overflow = "hidden";
    } else if (recurringModal === false) {
      document.body.style.overflow = "auto";
    }
  }, [recurringModal]);

  // translation
  const { t } = useTranslation();

  return (
    <>
      <div className={userData ? "main-container logged-in" : "main-container"}>
        <Header
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          handleToggle={handleToggle}
          dropdownToggle={dropdownToggle}
          handleDropdownToggle={handleDropdownToggle}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setTaxReceiptModal={setTaxReceiptModal}
          setLinkDonationModal={setLinkDonationModal}
        />
        <div className="dashboard-container">
          <Sidebar userData={userData} toggled={toggled} />
          <div className="home-content">
            {/* before loader... after either error or data */}
            {recurringLoader ? (
              <main className="info-content-div" style={{ height: `${100}%` }}>
                <div className="loading-screen">
                  <ClapSpinner
                    size={30}
                    color="#007c16"
                    loading={recurringLoader}
                  />
                </div>
              </main>
            ) : (
              <>
                {recurringError ? (
                  <main
                    className="info-content-div"
                    style={{ height: `${100}%` }}
                  >
                    <div className="error-msg" style={{ marginTop: 0 }}>
                      {recurringError}
                    </div>
                  </main>
                ) : (
                  <div className="recurring-container">
                    {recurringList &&
                      recurringList.map((recurring) => (
                        <div
                          className="recurring-item-box"
                          key={recurring.recurringTag}
                        >
                          {/* organization box */}
                          <div className="recurring-organization-box">
                            <div className="recurring-organization-img-div">
                              <img
                                src={recurring.organizationLogo}
                                alt="organization-logo"
                                className="recurring-organization-img"
                              />
                            </div>
                            <h3 className="recurring-organization-name">
                              {recurring.organizationName}
                            </h3>
                          </div>
                          {/* campaign image */}
                          <div className="recurring-campaign-img-div">
                            <img
                              className="recurring-campaign-img"
                              src={recurring.campaignImage}
                              alt="campaign"
                            />
                          </div>
                          {/* campiagn name */}
                          <h4 className="recurring-campaign">
                            {recurring.campaign}
                          </h4>
                          {/* create date and time */}
                          <section className="recurring-date">
                            <div className="recurring-attribute">DATE</div>
                            <div className="attribute-value">
                              {recurring.createDateTime}
                            </div>
                          </section>
                          {/* billing period */}
                          <section className="recurring-date billing">
                            <div className="recurring-attribute">
                              {t("Recurring.billing")}
                            </div>
                            <div className="attribute-value">
                              {recurring.billingPeriod}
                            </div>
                          </section>
                          {/* amount */}
                          <section className="recurring-date billing">
                            <div className="recurring-attribute">
                              {t("Recurring.amount")}
                            </div>
                            <div className="attribute-value">
                              {recurring.currencySymbol}
                              {recurring.amount}
                            </div>
                          </section>
                          {/* status */}
                          <section className="recurring-date billing">
                            <div className="recurring-attribute">
                              {t("Recurring.status")}
                            </div>
                            <div className="attribute-value">
                              {recurring.status}
                            </div>
                          </section>
                          <br />
                          <MDBBtn
                            type="button"
                            className="recurring-details-btn"
                            onClick={() => handleRecurringModal(recurring)}
                          >
                            {t("Recurring.view_details")}
                          </MDBBtn>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {recurringModal && (
        <RecurringModal
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          setRecurringModal={setRecurringModal}
          recurringDetails={recurringDetails}
        />
      )}
    </>
  );
};

export default Recurring;
