import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { MDBBtn, MDBBtnGroup, MDBRadio } from "mdb-react-ui-kit";
import { ClapSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import Tippy from "@tippyjs/react";
import logo from "../../images/logo.png";
import Select from "react-select";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import apiKey from "../private/private";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const RegisterationModal = ({
  setRegisterationModal,
  hostName,
  setErrorOnClose,
  countries,
  usaStates,
  provinces,
  code,
  setData,
  donorEmail,
}) => {
  // close modal
  const handleCloseRegisterationModal = () => {
    setRegisterationModal(false);
    setErrorOnClose(
      `Register the account with email ${donorEmail} to download the tax receipt.`
    );
  };

  // translation
  const { t } = useTranslation();

  // hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
    options: {
      componentRestrictions: {
        country: ["ca", "us"],
      },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  // states
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [placesPopup, setPlacesPopup] = useState(false);

  // input change
  const handleInputChange = (evt) => {
    const inputValue = evt.target.value;
    setStreetAddress(inputValue);
    getPlacePredictions({ input: inputValue });
    setSelectedPrediction(null);
    setPlacesPopup(true);
  };

  // prediction click
  const handlePredictionClick = (prediction) => {
    setSelectedPrediction(prediction);
    setPlacesPopup(false);

    // Extract address components from the prediction
    placesService?.getDetails(
      {
        placeId: prediction.place_id,
      },
      (place) => {
        const addressComponents = place.address_components;
        let mergedStreetAddress = "";

        // Loop through address components to find street number and route
        addressComponents.forEach((component) => {
          switch (component.types[0]) {
            case "street_number":
            case "route":
              mergedStreetAddress += `${component.long_name} `;
              break;
            case "locality":
              setCity(component.long_name);
              break;
            case "administrative_area_level_1":
              setProvinceOrState(component.short_name);
              break;
            case "country":
              setCountry(component.long_name);
              break;
            case "postal_code":
              setPostalZipCode(component.long_name);
              break;
            default:
              console.warn(
                "Unhandled address component type:",
                component.types[0]
              );
              // You can add additional handling for unhandled address component types here
              break;
          }
        });

        // Update the street address state variable with the merged street number and route
        setStreetAddress(mergedStreetAddress.trim());
      }
    );
  };

  // form fields
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email] = useState(donorEmail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [provinceOrState, setProvinceOrState] = useState("");
  const [postalZipCode, setPostalZipCode] = useState("");
  const [pattern, setPattern] = useState("");

  useEffect(() => {
    if (country === "United States") {
      setPattern("^\\d{5}$|^\\d{5}-\\d{4}$|^\\d{5} \\d{4}$");
    } else {
      setPattern("^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$");
    }
  }, [country]);

  // donor type
  const [donorType, setDonorType] = useState("I");
  const handleDonorTypeChange = (event) => {
    setDonorType(event.target.id);
    setBusinessName("");
  };

  // business name
  const [businessName, setBusinessName] = useState("");

  // submit event states
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);

  // retry count
  const [retryCount, setRetryCount] = useState(0);

  // form submit
  const handleRegister = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if (!emailRegex.test(email)) {
      setError("Email field should be a valid email address.");
      setPlacesPopup(false);
    } else if (!passwordRegex.test(password)) {
      setError("Password must be atleast 8 alphanumeric characters.");
      setPlacesPopup(false);
    } else if (confirmPassword !== password) {
      setError(
        "Please confirm your password. Make sure it matches the password you entered."
      );
      setPlacesPopup(false);
    } else {
      setError(null);
      setLoader(true);
      setLoaderMessage("Registering your profile...");
      const data = {
        firstName,
        middleInitials: middleName,
        lastName,
        phone: phoneNo,
        email,
        accountPassword: password,
        streetAddress,
        city,
        country,
        provinceState: provinceOrState,
        postalZipCode,
        allowContact: "1",
        notifyNewCampaign: "1",
        accountType: donorType,
      };

      if (donorType === "B") {
        data.businessName = businessName;
      }
      axios
        .post(`${hostName}api/v1/auth/register`, data)
        .then(() => {
          // download tax receipt
          setLoaderMessage(
            "Registration Completed! Now downloading your tax receipt...Please Wait"
          );

          // Retry loading reCAPTCHA script on button click
          const retryLoadReCaptchaScript = () => {
            if (retryCount < 3) {
              setRetryCount((prevCount) => prevCount + 1);
              loadReCaptchaScript();
            } else {
              setLoader(false);
              setLoaderMessage(null);
              setErrorOnClose(
                "Unable to load reCAPTCHA after multiple retries. Refreshing the page might fix the issue."
              );
              setRegisterationModal(false);
            }
          };

          // load recaptcha
          const loadReCaptchaScript = () => {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js";
            script.async = true;
            script.defer = true;
            script.onload = initializeRecaptcha;
            document.head.appendChild(script);
          };

          // initialize recaptcha
          const initializeRecaptcha = () => {
            console.log("reCAPTCHA script loaded");
            console.log("window.grecaptcha:", window.grecaptcha);
            console.log(
              "typeof window.grecaptcha.execute:",
              typeof window.grecaptcha.execute
            );
            if (
              window.grecaptcha &&
              typeof window.grecaptcha.execute === "function"
            ) {
              // Use grecaptcha.execute here
              window.grecaptcha
                .execute("6LelmPUjAAAAAFA5Ks_St3J5yxjK4Jce7VBKsJgW", {
                  action: "CHECKOUT",
                })
                .then((token) => {
                  if (token) {
                    axios
                      .get(
                        `${hostName}api/v1/taxReceipt/download?code=${code}&recaptchaToken=${token}`,
                        { responseType: "arraybuffer" }
                      )
                      .then((res) => {
                        if (res.status === 200) {
                          // Convert the response data to a Blob
                          const blob = new Blob([res.data], {
                            type: "application/pdf",
                          });

                          // Create a URL for the Blob
                          const url = URL.createObjectURL(blob);

                          // Set the URL to your state or wherever you need it
                          setData(url);

                          // error on close
                          setErrorOnClose(null);
                        }
                      })
                      .catch((err) => {
                        setErrorOnClose(
                          `${err.message} in downloading the tax receipt. Refresh the page to retry downloading your tax receipt.`
                        );
                      })
                      .finally(() => {
                        setLoader(false);
                        setLoaderMessage(null);
                        setRegisterationModal(false);
                      });
                  } else {
                    setLoader(false);
                    setLoaderMessage(null);
                    setErrorOnClose(
                      "No reCAPTCHA token found. Refresh the page to retry downloading your tax receipt."
                    );
                    setRegisterationModal(false);
                  }
                })
                .catch((err) => {
                  setLoader(false);
                  setLoaderMessage(null);
                  setErrorOnClose(
                    `${err.message} in executing reCAPTCHA. Refresh the page to retry downloading your tax receipt.`
                  );
                  setRegisterationModal(false);
                });
            } else {
              retryLoadReCaptchaScript();
            }
          };

          // Check if grecaptcha is already loaded, if not, load the script
          if (window.grecaptcha) {
            initializeRecaptcha();
          } else {
            loadReCaptchaScript();
          }
        })
        .catch((err) => {
          setLoader(false);
          setLoaderMessage(null);
          setPlacesPopup(false);
          if (err.response.data.errors.email) {
            setError(err.response.data.errors.email[0]);
          } else {
            setError(err.message);
          }
        });
    }
  };

  // handle country change
  const handleCountryChange = (label) => {
    setCountry(label);
    setProvinceOrState("");
    setPostalZipCode("");
  };

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop registeration"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          className="registeration-modal"
        >
          <button
            type="button"
            onClick={() => handleCloseRegisterationModal(false)}
            className="close-registeration-modal"
            disabled={loader}
          >
            X
          </button>

          {loader && (
            <div className="registeration-modal-loading-screen">
              <ClapSpinner size={30} color="#007c16" loading={loader} />
              <p>{loaderMessage}</p>
            </div>
          )}

          <div className="auth-logo">
            <img src={logo} alt="logo" />
          </div>

          <form
            className="auth-form"
            autoComplete="off"
            onSubmit={handleRegister}
          >
            <h3>{t("Signup.registerTaxReceipt")}</h3>
            <hr />

            <h5 className="auth-heading">{t("Signup.donor_type")}</h5>
            <MDBBtnGroup className="custom-btn-group">
              <MDBRadio
                btn
                btnColor={`${
                  donorType === "I" ? "theme-green" : "transparent"
                }`}
                id="I"
                name="donorTypeOptions"
                wrapperTag="span"
                label={t("Signup.individual")}
                checked={donorType === "I"}
                onChange={handleDonorTypeChange}
              />
              <MDBRadio
                btn
                btnColor={`${
                  donorType === "B" ? "theme-green" : "transparent"
                }`}
                id="B"
                name="donorTypeOptions"
                wrapperClass="mx-2"
                wrapperTag="span"
                label={t("Signup.business")}
                checked={donorType === "B"}
                onChange={handleDonorTypeChange}
              />
            </MDBBtnGroup>

            {donorType === "B" && (
              <>
                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Signup.business_name")}</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      required={donorType === "B"}
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      placeholder={t("Signup.business_name")}
                    />
                  </div>
                </div>
                <br />
              </>
            )}

            <h5 className="auth-heading">
              {donorType === "B"
                ? `${t("Signup.contact")}`
                : `${t("Signup.profile")}`}
            </h5>
            {/* first middle last name */}
            <div className="custom-row">
              <div className="custom-col">
                <label>{t("Signup.first_name")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.first_name")}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="custom-col">
                <label>{t("Signup.middle_name")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  placeholder={t("Signup.middle_name")}
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>
              <div className="custom-col">
                <label>{t("Signup.last_name")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.last_name")}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            {/* email and phone */}
            <div className="custom-row">
              <div className="custom-col">
                <label>{t("Signup.phone")}</label>
                <input
                  type="tel"
                  inputMode="numeric"
                  pattern="^[0-9]{10,15}$"
                  title="Please enter a valid phone number with 10 to 15 digits."
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.phone")}
                  value={phoneNo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*$/.test(value) && value.length <= 15) {
                      setPhoneNo(value);
                    }
                  }}
                />
              </div>
              <div className="custom-col">
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span>{t("Signup.email")}</span>
                  <Tippy content={t("Signup.emailInfoMsg")}>
                    <span style={{ marginLeft: `${5}px` }}>
                      <Icon icon={alertCircle} size={18} />
                    </span>
                  </Tippy>
                </label>
                <input
                  className="edit-media-inputs"
                  type="email"
                  placeholder={t("Signup.email")}
                  value={email}
                  readOnly
                />
              </div>
            </div>

            {/* password and confirm password */}
            <div className="custom-row">
              <div className="custom-col">
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span>{t("Signup.password")}</span>
                  <Tippy content={t("Signup.passwordInfoMsg")}>
                    <span style={{ marginLeft: `${5}px` }}>
                      <Icon icon={alertCircle} size={18} />
                    </span>
                  </Tippy>
                </label>
                <input
                  type="password"
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </div>
              <div className="custom-col">
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span>{t("Signup.confirm_password")}</span>
                  <Tippy content={t("Signup.confirmPasswordInfoMsg")}>
                    <span style={{ marginLeft: `${5}px` }}>
                      <Icon icon={alertCircle} size={18} />
                    </span>
                  </Tippy>
                </label>
                <input
                  type="password"
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.confirm_password")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <h5 className="auth-heading">{t("Signup.address")}</h5>

            {/* city and street address */}
            <div className="custom-row">
              <div className="custom-col">
                <label>{t("Signup.street_address")}</label>
                <div className="email-input-container">
                  <input
                    type="text"
                    className="edit-media-inputs"
                    required
                    placeholder={t("Signup.street_address")}
                    value={streetAddress}
                    onChange={handleInputChange}
                    autoComplete="new-password"
                  />
                  {!isPlacePredictionsLoading &&
                    placePredictions.length > 0 &&
                    !selectedPrediction &&
                    placesPopup && (
                      <div className="suggestions-popup address">
                        {placePredictions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="suggestion-item"
                            onClick={() => handlePredictionClick(suggestion)}
                          >
                            <div className="suggestion-email">
                              {suggestion.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
              <div className="custom-col">
                <label>{t("Signup.city")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  required
                  placeholder={t("Signup.city")}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            {/* country province/state zip/postal */}
            <div className="custom-row">
              <div className="custom-col">
                <label>{t("Signup.country")}</label>
                <Select
                  value={countries.find((option) => option.label === country)}
                  onChange={(option) => handleCountryChange(option.label)}
                  options={countries}
                  styles={styles}
                  required
                  menuPlacement="top"
                />
              </div>
              {country === "Canada" ? (
                <div className="custom-col">
                  <label>{t("Signup.province")}</label>
                  <Select
                    value={
                      provinces.find(
                        (option) => option.value === provinceOrState
                      ) || ""
                    }
                    onChange={(option) => setProvinceOrState(option.value)}
                    options={provinces}
                    styles={styles}
                    required
                    menuPlacement="top"
                  />
                </div>
              ) : country === "United States" ? (
                <div className="custom-col">
                  <label>{t("Signup.state")}</label>
                  <Select
                    value={
                      usaStates.find(
                        (option) => option.value === provinceOrState
                      ) || ""
                    }
                    onChange={(option) => setProvinceOrState(option.value)}
                    options={usaStates}
                    styles={styles}
                    required
                    menuPlacement="top"
                  />
                </div>
              ) : (
                <div className="custom-col">
                  <label>{t("Signup.province")}</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    required
                    placeholder={t("Signup.province")}
                    value={provinceOrState}
                    onChange={(e) => setProvinceOrState(e.target.value)}
                  />
                </div>
              )}

              <div className="custom-col">
                <label>
                  {country === "United States"
                    ? t("Signup.zip_code")
                    : t("Signup.postal_code")}
                </label>
                <input
                  type="text"
                  pattern={pattern}
                  title={
                    country === "United States"
                      ? "Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                      : "Please enter a valid postal code (e.g., A1A 1A1)."
                  }
                  className="edit-media-inputs"
                  required
                  placeholder={
                    country === "United States"
                      ? t("Signup.zip_code")
                      : t("Signup.postal_code")
                  }
                  value={postalZipCode}
                  onChange={(e) => setPostalZipCode(e.target.value)}
                />
              </div>
            </div>

            {error && <div className="error-msg">{error}</div>}

            {/* submit btn */}
            <div
              className="register-btn-div registeration-modal"
              style={{ marginBottom: "50px" }}
            >
              <MDBBtn
                type="submit"
                rounded
                className="register-btn"
                disabled={loader}
              >
                {t("Signup.register")}
              </MDBBtn>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
export default RegisterationModal;
