import React, { useState } from "react";
import Icon from "react-icons-kit";
import { basic_world } from "react-icons-kit/linea/basic_world";
import { basic_smartphone } from "react-icons-kit/linea/basic_smartphone";
import { basic_mail } from "react-icons-kit/linea/basic_mail";
import { basic_compass } from "react-icons-kit/linea/basic_compass";
import { ClapSpinner } from "react-spinners-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrganizationDetails = ({
  hostName,
  userData,
  setLoggedInUserData,
  organizationDetails,
  setCharityPath,
  setCharityDetails,
  currentQuery,
  setQuantity,
}) => {
  // states
  const [btnLoader, setBtnLoader] = useState(false);

  // navigate
  const navigate = useNavigate();

  // handle follow
  const handleFollow = (organization) => {
    if (!btnLoader) {
      if (!userData) {
        navigate("/signin");
      } else {
        setBtnLoader(true);
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        };
        axios
          .post(
            `${hostName}api/v1/donor/follow?organizationTag=${organization.tagNumber}`,
            {},
            axiosConfig
          )
          .then((res) => {
            organization.isFollowing = res.data.data.isFollowing;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              navigate("/signin");
            }
          })
          .finally(() => setBtnLoader(false));
      }
    }
  };

  // selector btns
  const [contactStep, setContactStep] = useState(false);

  // campaign click
  const handleCampaignClick = (campaign) => {
    setQuantity(1);
    setCharityDetails(campaign);
    const { name } = campaign;
    const word = name.replace(/[^a-zA-Z0-9]/g, "");
    const lowercase = word.toLowerCase();
    setCharityPath(lowercase);
    if (currentQuery) {
      navigate(`${lowercase}${currentQuery}`, { replace: true });
    } else {
      navigate(`${lowercase}`, { replace: true });
    }
  };

  // translation
  const { t } = useTranslation();

  return (
    <div className="home-content">
      {organizationDetails ? (
        <div className="cards-container single-card">
          <div className="custom-card single-card">
            <header className="card-header">
              <div className="card-leftside single-card">
                <div className="card-logo">
                  <img src={organizationDetails.logo} alt="card-logo" />
                </div>
                <div className="title-and-city-country">
                  <div className="organization-name">
                    {organizationDetails.name}{" "}
                    {organizationDetails.locationName && (
                      <>- {organizationDetails.locationName}</>
                    )}
                  </div>
                  <div className="city-and-country single-card">
                    {organizationDetails.city},{" "}
                    {organizationDetails.currencyCode}
                  </div>
                </div>
              </div>
              <MDBBtn
                className="follow-btn"
                onClick={() => handleFollow(organizationDetails)}
                disabled={btnLoader}
              >
                {organizationDetails.isFollowing
                  ? t("OrganizationDetails.following")
                  : t("OrganizationDetails.follow")}
                {btnLoader && (
                  <div className="btn-loader">
                    <ClapSpinner size={24} color="#fff" loading={btnLoader} />
                  </div>
                )}
              </MDBBtn>
            </header>

            <div className="campaigns-or-contact">
              <button
                type="button"
                onClick={() => setContactStep(false)}
                className={`selector-btns${contactStep ? "" : " active"}`}
              >
                {t("OrganizationDetails.campaigns").toUpperCase()}
              </button>
              <button
                type="button"
                onClick={() => setContactStep(true)}
                className={`selector-btns${contactStep ? " active" : ""}`}
              >
                {t("OrganizationDetails.contact")}
              </button>
            </div>

            {!contactStep ? (
              <>
                {organizationDetails.campaigns?.length > 0 ? (
                  <>
                    <div className="campaign-msg">
                      {t("OrganizationDetails.select_a_campaign")}
                    </div>
                    <div className="campaign-box">
                      {organizationDetails.campaigns
                        .filter((campaign) => !campaign.hidden)
                        .map((campaign) => {
                          const percentage = (
                            (campaign.raisedAmount / campaign.targetAmount) *
                            100
                          ).toFixed(2);
                          const width =
                            percentage >= 100 || !isFinite(percentage)
                              ? "100%"
                              : `${percentage}%`;
                          const backgroundColor = "#007c16";
                          return (
                            <div
                              className="campaign"
                              onClick={() => handleCampaignClick(campaign)}
                              key={campaign.tagNumber}
                            >
                              <div className="campaign-icon">
                                <img
                                  className="campaign-icon-img"
                                  alt="campaign-icon"
                                  src={campaign.icon.filename}
                                />
                              </div>
                              <div className="campaign-text">
                                {campaign.name}
                              </div>
                              {campaign.targetAmount > 0 && (
                                <div className="campaign-progress-bar">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width,
                                      background: backgroundColor,
                                    }}
                                  ></div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <div className="error-msg">
                    This organization has no campaigns associated with it.
                  </div>
                )}
              </>
            ) : (
              <div className="contact-box">
                <h3 className="get-in-touch-heading">
                  {t("OrganizationDetails.get_in_touch")}
                </h3>
                <div className="contact-info-box">
                  {/* name */}
                  <h6>{organizationDetails.name}</h6>
                  {/* streetAddress and unit no */}
                  <div className="p-row">
                    <div>
                      <Icon icon={basic_compass} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.streetAddress}{" "}
                      {organizationDetails.unitNumber}
                    </div>
                  </div>
                  {/* city province and postal code */}
                  <div className="p-row">
                    <div className="empty-div"></div>
                    <div className="p-row-text">
                      {organizationDetails.city}{" "}
                      {organizationDetails.provinceState}{" "}
                      {organizationDetails.postalZipCode}
                    </div>
                  </div>
                  {/* country */}
                  <div className="p-row">
                    <div className="empty-div"></div>
                    <div className="p-row-text">
                      {organizationDetails.country}
                    </div>
                  </div>
                  {/* phone */}
                  <div className="p-row">
                    <div>
                      <Icon icon={basic_smartphone} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.phone}
                    </div>
                  </div>
                  {/* email */}
                  <div className="p-row" style={{ overflow: "hidden" }}>
                    <div>
                      <Icon icon={basic_mail} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.email ? (
                        <a href={`mailto:${organizationDetails.email}`}>
                          {organizationDetails.email}
                        </a>
                      ) : (
                        <span>{t("Content.not_available")}</span>
                      )}
                    </div>
                  </div>
                  {/* website */}
                  <div className="p-row" style={{ overflow: "hidden" }}>
                    <div>
                      <Icon icon={basic_world} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.website ? (
                        <a href={organizationDetails.website} target="blank">
                          {organizationDetails.website}
                        </a>
                      ) : (
                        <span>{t("Content.not_available")}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="error-msg">No Organization Found</div>
      )}
    </div>
  );
};

export default OrganizationDetails;
