import React, { useEffect } from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import CharityDetails from "../../components/searchOrganization/CharityDetails";
import DonationModal from "../../components/searchOrganization/DonationModal";

const Charity = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  organizationPath,
  organizationDetails,
  charityPath,
  charityDetails,
  donationAmount,
  setDonationAmount,
  amountsWithoutZero,
  donationModal,
  setDonationModal,
  setTaxReceiptModal,
  setLinkDonationModal,
  vTag,
  currentQuery,
  quantity,
  setQuantity,
}) => {
  // to hide body scroll for donation modal
  useEffect(() => {
    if (donationModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [donationModal]);

  return (
    <>
      <div className={userData ? "main-container logged-in" : "main-container"}>
        <Header
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          handleToggle={handleToggle}
          dropdownToggle={dropdownToggle}
          handleDropdownToggle={handleDropdownToggle}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          organizationPath={organizationPath}
          organizationDetails={organizationDetails}
          charityPath={charityPath}
          charityDetails={charityDetails}
          setTaxReceiptModal={setTaxReceiptModal}
          setLinkDonationModal={setLinkDonationModal}
        />
        <div className="dashboard-container">
          <Sidebar userData={userData} toggled={toggled} />
          <CharityDetails
            organizationDetails={organizationDetails}
            charityDetails={charityDetails}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            amountsWithoutZero={amountsWithoutZero}
            setDonationModal={setDonationModal}
            currentQuery={currentQuery}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </div>
      </div>
      {donationModal && (
        <DonationModal
          hostName={hostName}
          userData={userData}
          organizationDetails={organizationDetails}
          charityDetails={charityDetails}
          donationAmount={donationAmount}
          setDonationAmount={setDonationAmount}
          amountsWithoutZero={amountsWithoutZero}
          setDonationModal={setDonationModal}
          setLoggedInUserData={setLoggedInUserData}
          vTag={vTag}
          quantity={quantity}
        />
      )}
    </>
  );
};

export default Charity;
