import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import axios from "axios";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import logo from "../../images/logo.png";
import { CustomTextField } from "./CustomTextField";

function getEmail() {
  const email = localStorage.getItem("userEmail");
  if (email) {
    return email;
  }

  return null;
}

const ResetPassword = ({ hostName, setResetSuccessMsg, setStep }) => {
  // get user email
  const [userEmail] = useState(getEmail());

  // navigate
  const navigate = useNavigate();

  // general states
  const [loader, setLoader] = useState(false);

  // reset password event states
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const handleNewPassword = (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    setResetPasswordError("");
    if (newPassword === confirmNewPassword) {
      if (passwordRegex.test(newPassword)) {
        setLoader(true);
        axios
          .put(`${hostName}api/v1/auth/resetPassword`, {
            email: userEmail,
            resetCode: otp,
            newPassword,
            confirmPassword: confirmNewPassword,
          })
          .then((res) => {
            setLoader(false);
            setResetSuccessMsg(res.data.message);
            setStep(0);
            setOtp("");
            setNewPassword("");
            setConfirmNewPassword("");
            setResetPasswordError("");
            navigate("/signin");
            localStorage.removeItem("userEmail");
          })
          .catch((err) => {
            setLoader(false);
            if (err.message !== "Network Error") {
              if (err.response.data.message !== "") {
                setResetPasswordError(err.response.data.message);
              }
            } else {
              setResetPasswordError(err.message);
            }
          });
      } else {
        setResetPasswordError(
          "Password must be atleast 8 alphanumeric characters"
        );
      }
    } else {
      setResetPasswordError(
        "Confirm Password does not match with the new password"
      );
    }
  };

  // language code
  const [lang, setLang] = useState();
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language) {
      setLang(language);
    }
  }, [lang]);

  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);
  };

  const { t } = useTranslation();

  return (
    <div className="background">
      <div className="box login">
        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={!(loader === false)}
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={!(loader === false)}
          >
            English
          </button>
        )}

        {loader && (
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={loader} />
          </div>
        )}

        <div className="auth-logo">
          <img src={logo} alt="logo" />
        </div>

        {/* reset password form */}
        <form
          className="auth-form"
          autoComplete="off"
          onSubmit={handleNewPassword}
        >
          <h3>{t(t("Auth.reset_password"))}</h3>

          <CustomTextField
            name="otp"
            id="otp"
            label="OTP"
            type="text"
            variant="standard"
            fullWidth
            required
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            readOnly={!!loader}
          />
          <br />
          <br />
          <CustomTextField
            label={t("Auth.new_password")}
            type="password"
            variant="standard"
            fullWidth
            required
            autoComplete="off"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            readOnly={!!loader}
          />
          <br />
          <br />

          <CustomTextField
            label={t("Auth.confirm_password")}
            type="password"
            variant="standard"
            fullWidth
            required
            autoComplete="off"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            readOnly={!!loader}
          />

          <div className="auth-action-btns-div">
            <button
              type="button"
              className="forget-password"
              onClick={() => navigate("/signin")}
              disabled={!!loader}
            >
              {t("Auth.login")}
            </button>
            <MDBBtn
              type="submit"
              rounded
              className="login-btn"
              disabled={!!loader}
            >
              {t("Auth.reset")}
            </MDBBtn>
          </div>

          {resetPasswordError !== "" && (
            <div className="error-msg">{resetPasswordError}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
