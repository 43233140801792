import axios from 'axios';
import { hostName } from '../../App';

export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_LOADING_SCREEN = 'GET_LOADING_SCREEN';
export const GET_ERR_MSG = 'GET_ERR_MSG';
export const CLEAN_DATA = 'CLEAN_DATA';

export const getErrorMsg = (errMsg) => ({
  type: GET_ERR_MSG,
  payload: errMsg,
});

export const setLoading = (status) => ({
  type: GET_LOADING_SCREEN,
  payload: status,
});

// logged out search
export const getSearchData = (userInput) => (dispatch) => {
  if (userInput !== '') {
    dispatch(setLoading(true));
    axios.get(`${hostName}api/v1/search/organization?name=${userInput}`).then((res) => {
      dispatch(setLoading(false));
      if (res.data.data.length > 0) {
        dispatch({
          type: GET_SEARCH_DATA,
          payload: res.data.data,
        });
      } else {
        dispatch(getErrorMsg('No Data Found'));
      }
    }).catch((err) => {
      dispatch(setLoading(false));
      dispatch(getErrorMsg(err.message));
    });
  } else {
    dispatch({
      type: GET_SEARCH_DATA,
      payload: [],
    });
  }
};

// logged in search
export const getSearchDataLoggedIn = (userInput, userEmail) => (dispatch) => {
  if (userInput !== '') {
    dispatch(setLoading(true));
    axios.get(`${hostName}api/v1/search/organization?name=${userInput}&email=${userEmail}`).then((res) => {
      dispatch(setLoading(false));
      if (res.data.data.length > 0) {
        dispatch({
          type: GET_SEARCH_DATA,
          payload: res.data.data,
        });
      } else {
        dispatch(getErrorMsg('No Data Found'));
      }
    }).catch((err) => {
      dispatch(setLoading(false));
      dispatch(getErrorMsg(err.message));
    });
  } else {
    dispatch({
      type: GET_SEARCH_DATA,
      payload: [],
    });
  }
};

// clean data or initial store
export const cleanData = () => ({
  type: CLEAN_DATA,
});
