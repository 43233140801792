/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ClapSpinner } from "react-spinners-kit";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const RequestNewModal = ({
  hostName,
  setRequestNewModal,
  user,
  orgOptions,
  taxReceiptsList,
  setTaxReceiptsList,
}) => {
  // request new
  const [requestNewLoading, setRequestNewLoading] = useState(false);
  const [requestNewError, setRequestNewError] = useState(null);

  // handle close request new modal
  const handleCloseRequestNewModal = () => {
    if (!requestNewLoading) {
      setRequestNewModal(false);
    }
  };

  // translation
  const { t } = useTranslation();

  // states
  const [year, setYear] = useState((new Date().getFullYear() - 1).toString()); // current year - 1

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setYear(value);
    }
  };

  // organization
  const [org, setOrg] = useState(null);

  // handle org change
  const handleOrgChange = (value) => {
    if (org !== value) {
      setOrg(value);
    }
  };

  // navigate
  const navigate = useNavigate();

  // event
  const handleRequestNew = (e) => {
    e.preventDefault();
    setRequestNewLoading(true);
    setRequestNewError(null);
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios
      .post(
        `${hostName}api/v1/donor/createTaxReceiptEntry`,
        {
          year,
          organizationTag: org,
        },
        axiosConfig
      )
      .then((res) => {
        const prevRecords = [...taxReceiptsList];
        prevRecords.unshift(res.data.data);
        setTaxReceiptsList((prevRecords) => [res.data.data, ...prevRecords]);
        setRequestNewModal(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/signin");
        } else if (
          err.response.data.messageDetails
        ) {
          setRequestNewError(err.response.data.messageDetails);
        } else {
          setRequestNewError(err.message);
        }
      })
      .finally(() => setRequestNewLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="tax-receipt-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="tax-receipt-box">
            <header className="tax-receipt-modal-header">
              <h4>{t("TaxReceipts.requestNew")}</h4>
              <button
                type="button"
                className="tax-cross-icon"
                onClick={handleCloseRequestNewModal}
              >
                X
              </button>
            </header>
            <form
              autoComplete="off"
              style={{ marginTop: "10px" }}
              onSubmit={handleRequestNew}
            >
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("TaxReceipts.selectYear")}</label>
                  <Select
                    value={yearOptions.find((option) => option.value === year)}
                    onChange={(option) => {
                      handleYearChange(option ? option.value : null);
                    }}
                    options={yearOptions}
                    styles={styles}
                    menuPosition="fixed"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>{t("TaxReceipts.selectOrg")}</label>
                  <Select
                    value={orgOptions.find((option) => option.value === org)}
                    onChange={(option) => {
                      handleOrgChange(option ? option.value : null);
                    }}
                    options={orgOptions}
                    styles={styles}
                    menuPosition="fixed"
                  />
                </MDBCol>
              </MDBRow>
              {requestNewError && (
                <div className="error-msg">{requestNewError}</div>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseRequestNewModal}
                >
                  {t("TaxReceipts.cancel")}
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={requestNewLoading}
                >
                  {requestNewLoading ? (
                    <ClapSpinner
                      size={22}
                      color="#fff"
                      loading={requestNewLoading}
                    />
                  ) : (
                    <>{t("TaxReceipts.submit")}</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default RequestNewModal;
