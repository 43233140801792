/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import { useNavigate } from "react-router";
import axios from "axios";
import { ClapSpinner } from "react-spinners-kit";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { basic_download } from "react-icons-kit/linea/basic_download";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import toast, { Toaster } from "react-hot-toast";
import RequestNewModal from "../../components/tax-receipt/RequestNewModal";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// get user
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const TaxReceipts = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => {
  // per page options array
  const perPageOptions = [
    {
      value: "05",
      label: "05",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // user state
  const [user] = useState(getUser());

  // setting up pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState("20");

  // get donations states
  const [taxReceiptsError, setTaxReceiptsError] = useState(null);
  const [taxReceiptsLoader, setTaxReceiptsLoader] = useState(true);
  const [taxReceiptsList, setTaxReceiptsList] = useState(null);

  // navigate
  const navigate = useNavigate();

  // get all tax receipts
  useEffect(() => {
    if (user) {
      // loader true
      setTaxReceiptsLoader(true);

      // axios config
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      // hitting api
      axios
        .get(
          `${hostName}api/v1/donor/getAllTaxReceipts?page=${currentPage}&pageSize=${perPage}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            setTaxReceiptsList(res.data.data);
            setTaxReceiptsError(null);
            setTotalPages(res.data.links.lastPage);
            setCurrentPage(res.data.links.currentPage);
            setPerPage(res.data.links.perPage);
          } else {
            setTaxReceiptsError("You do not have tax receipts.");
            setTaxReceiptsList(null);
          }
        })
        .catch((err) => {
          setTaxReceiptsError(err.message);
          setTaxReceiptsList(null);
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
        })
        .finally(() => setTaxReceiptsLoader(false));
    } else {
      navigate("/signin");
    }
  }, [userData, perPage, currentPage]);

  // handle per page
  const handlePerPage = (value) => {
    if (perPage != value) {
      setPerPage(value);
      setCurrentPage(1);
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    if (currentPage != value) {
      setCurrentPage(value);
    }
  };

  // translation
  const { t } = useTranslation();

  // request new
  const [requestNewModal, setRequestNewModal] = useState(false);

  // to hide body scroll for receipt modal
  useEffect(() => {
    if (requestNewModal === true) {
      document.body.style.overflow = "hidden";
    } else if (requestNewModal === false) {
      document.body.style.overflow = "auto";
    }
  }, [requestNewModal]);

  // org
  const [orgOptions, setOrgOptions] = useState([]);
  const [orgLoading, setOrgLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(`${hostName}api/v1/donor/follow`, axiosConfig)
        .then((res) => {
          // Handle options
          const options = res.data.map((option) => ({
            value: option.organizationTag,
            label: option.name,
          }));
          setOrgOptions(options);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setOrgLoading(false));
    } else {
      navigate("/signin");
    }
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [orgLoading, taxReceiptsLoader];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptCode, setDownloadReceiptCode] = useState(null);

  // retry count
  const [retryCount, setRetryCount] = useState(0);

  // handle download receipt
  const handleDownloadReceipt = (data) => {
    // initialize states
    setDownloadReceiptLoading(true);
    setDownloadReceiptCode(data.code);

    // Retry loading reCAPTCHA script on button click
    const retryLoadReCaptchaScript = () => {
      if (retryCount < 3) {
        setRetryCount((prevCount) => prevCount + 1);
        loadReCaptchaScript();
      } else {
        toast.error(
          "Unable to load reCAPTCHA after multiple retries. Refreshing the page might fix the issue.",
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setDownloadReceiptLoading(false);
      }
    };

    // load recaptcha
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = initializeRecaptcha;
      document.head.appendChild(script);
    };

    // initialize recaptcha
    const initializeRecaptcha = () => {
      console.log("reCAPTCHA script loaded");
      console.log("window.grecaptcha:", window.grecaptcha);
      console.log(
        "typeof window.grecaptcha.execute:",
        typeof window.grecaptcha.execute
      );
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.execute === "function"
      ) {
        // Use grecaptcha.execute here
        window.grecaptcha
          .execute("6LelmPUjAAAAAFA5Ks_St3J5yxjK4Jce7VBKsJgW", {
            action: "CHECKOUT",
          })
          .then((token) => {
            if (token) {
              axios
                .get(
                  `${hostName}api/v1/taxReceipt/download?code=${data.code}&recaptchaToken=${token}`,
                  { responseType: "arraybuffer" }
                )
                .then((res) => {
                  if (res.status === 200) {
                    setDownloadReceiptLoading(false);
                    // download pdf
                    const url = window.URL.createObjectURL(
                      new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${data.code}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                  }
                })
                .catch((err) => {
                  setDownloadReceiptLoading(false);
                  toast.error(
                    `${err.message} in downloading the tax receipt. Refresh the page to retry downloading your tax receipt.`,
                    {
                      position: "top-right",
                      autoClose: false,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                });
            } else {
              setDownloadReceiptLoading(false);
              toast.error(
                "No ReCAPTCHA Token Found. Refresh the page to retry downloading your tax receipt.",
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          })
          .catch((err) => {
            setDownloadReceiptLoading(false);
            toast.error(
              `${err.message} in executing reCAPTCHA. Refresh the page to retry downloading your tax receipt.`,
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          });
      } else {
        retryLoadReCaptchaScript();
      }
    };

    // Check if grecaptcha is already loaded, if not, load the script
    if (window.grecaptcha) {
      initializeRecaptcha();
    } else {
      loadReCaptchaScript();
    }
  };

  return (
    <>
      <Toaster />
      <div className={userData ? "main-container logged-in" : "main-container"}>
        <Header
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          handleToggle={handleToggle}
          dropdownToggle={dropdownToggle}
          handleDropdownToggle={handleDropdownToggle}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setTaxReceiptModal={setTaxReceiptModal}
          setLinkDonationModal={setLinkDonationModal}
        />
        <div className="dashboard-container">
          <Sidebar userData={userData} toggled={toggled} />
          <div className="home-content">
            {loadings ? (
              <main className="info-content-div" style={{ height: `${100}%` }}>
                <div
                  className="loading-screen"
                  style={{ background: "#f8f8f8" }}
                >
                  <ClapSpinner size={30} color="#007c16" loading={loadings} />
                </div>
              </main>
            ) : (
              <>
                <div className="media-heading-button-container">
                  <h6 className="h6">{t("TaxReceipts.taxReceiptsHeading")}</h6>
                  <div className="add-and-filter-btns-div">
                    <div className="action-btns-div">
                      <button
                        type="button"
                        className="action-buttons"
                        onClick={() => setRequestNewModal(true)}
                      >
                        {t("TaxReceipts.requestNew")}
                      </button>
                    </div>
                  </div>
                </div>
                {/* error */}
                {taxReceiptsError ? (
                  <main
                    className="info-content-div"
                    style={{ height: `${100}%` }}
                  >
                    <div className="error-msg" style={{ marginTop: 0 }}>
                      {taxReceiptsError}
                    </div>
                  </main>
                ) : (
                  <>
                    {taxReceiptsList && taxReceiptsList.length > 0 && (
                      <>
                        <MDBTable responsive>
                          <MDBTableHead>
                            <tr className="table-head">
                              <th
                                scope="col"
                                className="table-header-th"
                                style={{ textAlign: "center" }}
                              >
                                {t("TaxReceipts.year")}
                              </th>
                              <th scope="col" className="table-header-th">
                                {t("TaxReceipts.organization")}
                              </th>
                              <th scope="col" className="table-header-th">
                                {t("TaxReceipts.code")}
                              </th>
                              <th scope="col" className="table-header-th">
                                {t("TaxReceipts.receiptNumber")}
                              </th>
                              <th scope="col" className="table-header-th">
                                {t("TaxReceipts.issueDate")}
                              </th>
                              <th
                                scope="col"
                                className="table-header-th"
                                style={{ textAlign: "center" }}
                              >
                                {t("TaxReceipts.state")}
                              </th>
                              <th
                                scope="col"
                                className="table-header-th"
                                style={{ textAlign: "center" }}
                              >
                                {t("TaxReceipts.download")}
                              </th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            {taxReceiptsList.map((data, index) => (
                              <tr
                                key={data.tagNumber}
                                className="table-row"
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#fff" : "#f8f8f8",
                                }}
                              >
                                <td style={{ textAlign: "center" }}>
                                  {data.year}
                                </td>
                                <td>{data.organization}</td>
                                <td>{data.code}</td>
                                <td>{data.receiptNumber}</td>
                                <td>{data.issueDate}</td>
                                <td style={{ textAlign: "center" }}>
                                  <Tippy
                                    content={
                                      data.state === "Issued" ? (
                                        <>{t("TaxReceipts.issued")}</>
                                      ) : data.state === "Prepared" ? (
                                        <>{t("TaxReceipts.prepared")}</>
                                      ) : (
                                        data.state ===
                                          "Waiting For Profile" && (
                                          <>{t("TaxReceipts.waiting")}</>
                                        )
                                      )
                                    }
                                  >
                                    <img
                                      src={data.stateImage}
                                      alt="state"
                                      style={{
                                        width: 30 + "px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tippy>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {downloadReceiptLoading &&
                                  downloadReceiptCode === data.code ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#007c16",
                                      }}
                                    >
                                      <ClapSpinner
                                        loading={downloadReceiptLoading}
                                        color="#007c16"
                                        size={30}
                                      />
                                    </div>
                                  ) : (
                                    <span
                                      onClick={() =>
                                        handleDownloadReceipt(data)
                                      }
                                      style={{
                                        opacity: data.code ? 1 : 0.5,
                                        pointerEvents: data.code
                                          ? "auto"
                                          : "none",
                                        cursor: "pointer",
                                        color: "#007c16",
                                      }}
                                    >
                                      <Icon icon={basic_download} size={30} />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </MDBTableBody>
                        </MDBTable>
                      </>
                    )}
                  </>
                )}
                <>
                  {taxReceiptsList &&
                    taxReceiptsList.length > 0 &&
                    !loadings && (
                      <div className="transactions-pagination">
                        <div className="per-page-and-pagination">
                          <div className="per-page">
                            <label>{t("Donations.rows_per_page")}</label>
                            <Select
                              value={perPageOptions.find(
                                (option) => option.value === perPage
                              )}
                              onChange={(option) => handlePerPage(option.value)}
                              options={perPageOptions}
                              styles={styles}
                              menuPlacement="top"
                            />
                          </div>
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                </>
              </>
            )}
          </div>
        </div>
      </div>
      {requestNewModal && (
        <RequestNewModal
          hostName={hostName}
          setRequestNewModal={setRequestNewModal}
          user={user}
          orgOptions={orgOptions}
          taxReceiptsList={taxReceiptsList}
          setTaxReceiptsList={setTaxReceiptsList}
        />
      )}
    </>
  );
};

export default TaxReceipts;
