/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Icon from "react-icons-kit";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import Tippy from "@tippyjs/react";
import { useNavigate, Link } from "react-router-dom";
import { MDBBtn, MDBBtnGroup, MDBRadio } from "mdb-react-ui-kit";
import axios from "axios";
import { ClapSpinner } from "react-spinners-kit";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.png";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import apiKey from "../../components/private/private";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const Signup = ({ hostName }) => {
  // navigate
  const navigate = useNavigate();

  // form fields
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [provinceOrState, setProvinceOrState] = useState();
  const [postalZipCode, setPostalZipCode] = useState("");
  const [pattern, setPattern] = useState("");

  useEffect(() => {
    if (country === "United States") {
      setPattern("^\\d{5}$|^\\d{5}-\\d{4}$|^\\d{5} \\d{4}$");
    } else {
      setPattern("^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$");
    }
  }, [country]);

  // getting all options at once
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
    ])
      .then(([countriesRes, usaStatesRes, provincesRes]) => {
        // Handle countries
        const countryOptions = countriesRes.data.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        setCountries(countryOptions);

        // Handle USA states
        const usaStatesOptions = usaStatesRes.data.map((state) => ({
          value: state.code,
          label: state.name,
        }));
        setUsaStates(usaStatesOptions);

        // Handle Canada provinces
        const provincesOptions = provincesRes.data.map((province) => ({
          value: province.code,
          label: province.name,
        }));
        setProvinces(provincesOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // handle country change
  const handleCountryChange = (label) => {
    setCountry(label);
    setProvinceOrState("");
    setPostalZipCode("");
  };

  // hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
    options: {
      componentRestrictions: {
        country: ["ca", "us"],
      },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  // states
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [placesPopup, setPlacesPopup] = useState(false);

  // input change
  const handleInputChange = (evt) => {
    const inputValue = evt.target.value;
    setStreetAddress(inputValue);
    getPlacePredictions({ input: inputValue });
    setSelectedPrediction(null);
    setPlacesPopup(true);
  };

  // prediction click
  const handlePredictionClick = (prediction) => {
    setSelectedPrediction(prediction);
    setPlacesPopup(false);

    // Extract address components from the prediction
    placesService?.getDetails(
      {
        placeId: prediction.place_id,
      },
      (place) => {
        const addressComponents = place.address_components;
        let mergedStreetAddress = "";

        // Loop through address components to find street number and route
        addressComponents.forEach((component) => {
          switch (component.types[0]) {
            case "street_number":
            case "route":
              mergedStreetAddress += `${component.long_name} `;
              break;
            case "locality":
              setCity(component.long_name);
              break;
            case "administrative_area_level_1":
              setProvinceOrState(component.short_name);
              break;
            case "country":
              setCountry(component.long_name);
              break;
            case "postal_code":
              setPostalZipCode(component.long_name);
              break;
            default:
              console.warn(
                "Unhandled address component type:",
                component.types[0]
              );
              // You can add additional handling for unhandled address component types here
              break;
          }
        });

        // Update the street address state variable with the merged street number and route
        setStreetAddress(mergedStreetAddress.trim());
      }
    );
  };

  // donor type
  const [donorType, setDonorType] = useState("I");
  const handleDonorTypeChange = (event) => {
    setDonorType(event.target.id);
    setBusinessName("");
  };

  // business name
  const [businessName, setBusinessName] = useState("");

  // form submit
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const handleRegister = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if (!emailRegex.test(email)) {
      setError("Email field should be a valid email address.");
      setPlacesPopup(false);
    } else if (!passwordRegex.test(password)) {
      setError("Password must be atleast 8 alphanumeric characters.");
      setPlacesPopup(false);
    } else if (confirmPassword !== password) {
      setError(
        "Please confirm your password. Make sure it matches the password you entered."
      );
      setPlacesPopup(false);
    } else {
      setError(null);
      setLoader(true);
      axios
        .get(`${hostName}api/v1/auth/exist?email=${email}`)
        .then((res) => {
          if (!res.data.exist) {
            const data = {
              firstName,
              middleInitials: middleName,
              lastName,
              phone: phoneNo,
              email,
              accountPassword: password,
              streetAddress,
              city,
              country,
              provinceState: provinceOrState,
              postalZipCode,
              allowContact: "1",
              notifyNewCampaign: "1",
              accountType: donorType,
            };

            if (donorType === "B") {
              data.businessName = businessName;
            }
            axios
              .post(`${hostName}api/v1/auth/register`, data)
              .then(() => {
                setLoader(false);
                navigate("/signin");
              })
              .catch((err) => {
                setLoader(false);
                setError(err.message);
                setPlacesPopup(false);
              });
          } else {
            setLoader(false);
            setError("Email already exist.");
            setPlacesPopup(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          setError(err.message);
          setPlacesPopup(false);
        });
    }
  };

  // link click
  const handleLinkClick = (e) => {
    if (loader) {
      e.preventDefault();
    }
  };

  // language code
  const [lang, setLang] = useState();
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language) {
      setLang(language);
    }
  }, [lang]);

  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);
  };

  // translation
  const { t } = useTranslation();

  return (
    <div className="background">
      <div className="box">
        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={loader}
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={loader}
          >
            English
          </button>
        )}

        {loader && (
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={loader} />
          </div>
        )}

        <div className="auth-logo">
          <img src={logo} alt="logo" />
        </div>

        <form
          className="auth-form"
          autoComplete="off"
          onSubmit={handleRegister}
        >
          <h3>{t("Signup.register")}</h3>
          <hr />

          <h5 className="auth-heading">{t("Signup.donor_type")}</h5>
          <MDBBtnGroup className="custom-btn-group">
            <MDBRadio
              btn
              btnColor={`${donorType === "I" ? "theme-green" : "transparent"}`}
              id="I"
              name="donorTypeOptions"
              wrapperTag="span"
              label={t("Signup.individual")}
              checked={donorType === "I"}
              onChange={handleDonorTypeChange}
            />
            <MDBRadio
              btn
              btnColor={`${donorType === "B" ? "theme-green" : "transparent"}`}
              id="B"
              name="donorTypeOptions"
              wrapperClass="mx-2"
              wrapperTag="span"
              label={t("Signup.business")}
              checked={donorType === "B"}
              onChange={handleDonorTypeChange}
            />
          </MDBBtnGroup>

          {donorType === "B" && (
            <>
              <div className="custom-row">
                <div className="custom-col">
                  <label>{t("Signup.business_name")}</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    required={donorType === "B"}
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    placeholder={t("Signup.business_name")}
                  />
                </div>
              </div>
              <br />
            </>
          )}

          <h5 className="auth-heading">
            {donorType === "B"
              ? `${t("Signup.contact")}`
              : `${t("Signup.profile")}`}
          </h5>
          {/* first middle last name */}
          <div className="custom-row">
            <div className="custom-col">
              <label>{t("Signup.first_name")}</label>
              <input
                type="text"
                className="edit-media-inputs"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t("Signup.first_name")}
              />
            </div>
            <div className="custom-col">
              <label>{t("Signup.middle_name")}</label>
              <input
                type="text"
                className="edit-media-inputs"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                placeholder={t("Signup.middle_name")}
              />
            </div>
            <div className="custom-col">
              <label>{t("Signup.last_name")}</label>
              <input
                type="text"
                className="edit-media-inputs"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t("Signup.last_name")}
              />
            </div>
          </div>

          {/* email and phone */}
          <div className="custom-row">
            <div className="custom-col">
              <label>{t("Signup.phone")}</label>
              <input
                type="tel"
                inputMode="numeric"
                pattern="^[0-9]{10,15}$"
                title="Please enter a valid phone number with 10 to 15 digits."
                className="edit-media-inputs"
                required
                value={phoneNo}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) && value.length <= 15) {
                    setPhoneNo(value);
                  }
                }}
                placeholder={t("Signup.phone")}
              />
            </div>
            <div className="custom-col">
              <label style={{ display: "flex", alignItems: "center" }}>
                <span>{t("Signup.email")}</span>
                <Tippy content={t("Signup.emailInfoMsg")}>
                  <span style={{ marginLeft: `${5}px` }}>
                    <Icon icon={alertCircle} size={18} />
                  </span>
                </Tippy>
              </label>
              <input
                type="email"
                className="edit-media-inputs"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("Signup.email")}
              />
            </div>
          </div>

          {/* password and confirm password */}
          <div className="custom-row">
            <div className="custom-col">
              <label style={{ display: "flex", alignItems: "center" }}>
                <span>{t("Signup.password")}</span>
                <Tippy content={t("Signup.passwordInfoMsg")}>
                  <span style={{ marginLeft: `${5}px` }}>
                    <Icon icon={alertCircle} size={18} />
                  </span>
                </Tippy>
              </label>
              <input
                type="password"
                className="edit-media-inputs"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("Signup.password")}
                autoComplete="new-password"
              />
            </div>
            <div className="custom-col">
              <label style={{ display: "flex", alignItems: "center" }}>
                <span>{t("Signup.confirm_password")}</span>
                <Tippy content={t("Signup.confirmPasswordInfoMsg")}>
                  <span style={{ marginLeft: `${5}px` }}>
                    <Icon icon={alertCircle} size={18} />
                  </span>
                </Tippy>
              </label>
              <input
                type="password"
                className="edit-media-inputs"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t("Signup.confirm_password")}
              />
            </div>
          </div>
          <br />

          <h5 className="auth-heading">{t("Signup.address")}</h5>

          {/* city and street address */}
          <div className="custom-row">
            <div className="custom-col">
              <label>{t("Signup.street_address")}</label>
              <div className="email-input-container">
                <input
                  type="text"
                  className="edit-media-inputs"
                  required
                  value={streetAddress}
                  onChange={handleInputChange}
                  placeholder={t("Signup.street_address")}
                  autoComplete="new-address"
                />
                {!isPlacePredictionsLoading &&
                  placePredictions.length > 0 &&
                  !selectedPrediction &&
                  placesPopup && (
                    <div className="suggestions-popup address">
                      {placePredictions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="suggestion-item"
                          onClick={() => handlePredictionClick(suggestion)}
                        >
                          <div className="suggestion-email">
                            {suggestion.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <div className="custom-col">
              <label>{t("Signup.city")}</label>
              <input
                type="text"
                className="edit-media-inputs"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder={t("Signup.city")}
              />
            </div>
          </div>

          {/* country province/state zip/postal */}
          <div className="custom-row">
            <div className="custom-col">
              <label>{t("Signup.country")}</label>
              <Select
                value={countries.find((option) => option.label === country)}
                onChange={(option) => handleCountryChange(option.label)}
                options={countries}
                styles={styles}
                required
                menuPlacement="top"
              />
            </div>
            {country === "Canada" ? (
              <div className="custom-col">
                <label>{t("Signup.province")}</label>
                <Select
                  value={
                    provinces.find(
                      (option) => option.value === provinceOrState
                    ) || ""
                  }
                  onChange={(option) => setProvinceOrState(option.value)}
                  options={provinces}
                  styles={styles}
                  required
                  menuPlacement="top"
                />
              </div>
            ) : country === "United States" ? (
              <div className="custom-col">
                <label>{t("Signup.state")}</label>
                <Select
                  value={
                    usaStates.find(
                      (option) => option.value === provinceOrState
                    ) || ""
                  }
                  onChange={(option) => setProvinceOrState(option.value)}
                  options={usaStates}
                  styles={styles}
                  required
                  menuPlacement="top"
                />
              </div>
            ) : (
              <div className="custom-col">
                <label>{t("Signup.province")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  required
                  value={provinceOrState}
                  onChange={(e) => setProvinceOrState(e.target.value)}
                  placeholder={t("Signup.province")}
                />
              </div>
            )}

            <div className="custom-col">
              <label>
                {country === "United States"
                  ? t("Signup.zip_code")
                  : t("Signup.postal_code")}
              </label>
              <input
                type="text"
                pattern={pattern}
                title={
                  country === "United States"
                    ? "Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                    : "Please enter a valid postal code (e.g., A1A 1A1)."
                }
                className="edit-media-inputs"
                required
                placeholder={
                  country === "United States"
                    ? t("Signup.zip_code")
                    : t("Signup.postal_code")
                }
                value={postalZipCode}
                onChange={(e) => setPostalZipCode(e.target.value)}
              />
            </div>
          </div>

          {error && <div className="error-msg">{error}</div>}

          {/* submit btn */}
          <div className="register-btn-div" style={{ marginBottom: "50px" }}>
            <div className="go-login-text">
              {t("Signup.already_have_account")}{" "}
              <Link
                className="green-link"
                to="/signin"
                onClick={handleLinkClick}
              >
                {t("Signup.signin")}
              </Link>
            </div>
            <MDBBtn
              type="submit"
              rounded
              className="register-btn"
              disabled={loader}
            >
              {t("Signup.register")}
            </MDBBtn>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
