import {
  CLEAN_DATA,
  GET_ERR_MSG, GET_LOADING_SCREEN, GET_SEARCH_DATA,
} from './searchActions';

const initialState = {
  error: '',
  loader: false,
  searchArray: [],
};

const searchOrganization = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_DATA:
      return {
        error: '',
        loader: false,
        searchArray: action.payload,
      };
    case GET_LOADING_SCREEN:
      return {
        error: '',
        loader: action.payload,
        searchArray: [],
      };
    case GET_ERR_MSG:
      return {
        error: action.payload,
        loader: false,
        searchArray: [],
      };
    case CLEAN_DATA:
      return {
        error: '',
        loader: false,
        searchArray: [],
      };
    default:
      return state;
  }
};

export default searchOrganization;
