/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import ProfileForm from "../../components/profile/ProfileForm";

const Profile = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => {
  return (
    <div className={userData ? "main-container logged-in" : "main-container"}>
      <Header
        hostName={hostName}
        userData={userData}
        setLoggedInUserData={setLoggedInUserData}
        handleToggle={handleToggle}
        dropdownToggle={dropdownToggle}
        handleDropdownToggle={handleDropdownToggle}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setTaxReceiptModal={setTaxReceiptModal}
        setLinkDonationModal={setLinkDonationModal}
      />
      <div className="dashboard-container">
        <Sidebar userData={userData} toggled={toggled} />
        <ProfileForm
          hostName={hostName}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
        />
      </div>
    </div>
  );
};

export default Profile;
