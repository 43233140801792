/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";

// react icons kit
import Icon from "react-icons-kit";
import { arrows_rotate } from "react-icons-kit/linea/arrows_rotate";
import { ecommerce_dollar } from "react-icons-kit/linea/ecommerce_dollar";
import { ecommerce_graph_decrease } from "react-icons-kit/linea/ecommerce_graph_decrease";
import { ecommerce_graph_increase } from "react-icons-kit/linea/ecommerce_graph_increase";
import { ecommerce_graph2 } from "react-icons-kit/linea/ecommerce_graph2";
import { ecommerce_gift } from "react-icons-kit/linea/ecommerce_gift";
import { basic_heart } from "react-icons-kit/linea/basic_heart";

// i18
import { useTranslation } from "react-i18next";

// react router dom
import { useNavigate } from "react-router-dom";

// spinner kit
import { ClapSpinner } from "react-spinners-kit";

// react chartjs
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

// tippy
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

// mdb
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

// Import Swiper React components and css
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard } from "swiper/core";
import "swiper/css";

// doughnut
import Doughnut from "./Doughnut";

// images
import arrowsRefresh from "../../images/arrows-refresh.png";

// simplebar
import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import "simplebar/dist/simplebar.css";

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

// use swiper keybpard
SwiperCore.use([Keyboard]);

// get years
function getYears() {
  const emptyArray = [];
  const date = new Date();
  let yyyy = date.getFullYear();
  for (let i = 1; i <= 5; i += 1) {
    yyyy -= 1;
    const correctYearIndex = yyyy + 1;
    emptyArray.push(correctYearIndex);
  }
  return emptyArray;
}

// get years for non tax
function getYearsNonTax() {
  const emptyArray = [];
  const date = new Date();
  let yyyy = date.getFullYear();
  for (let i = 1; i <= 5; i += 1) {
    yyyy -= 1;
    const correctYearIndex = yyyy + 1;
    emptyArray.push(correctYearIndex);
  }
  return emptyArray;
}

// get years for by campaigns
function getYearsByCampaign() {
  const emptyArray = [];
  const date = new Date();
  let yyyy = date.getFullYear();
  for (let i = 1; i <= 5; i += 1) {
    yyyy -= 1;
    const correctYearIndex = yyyy + 1;
    emptyArray.push(correctYearIndex);
  }
  return emptyArray;
}

// default year
function getDefaultYear() {
  const date = new Date();
  const month = date.getMonth();
  const correctMonthIndex = month + 1;
  let currentYYYY = date.getFullYear();
  if (correctMonthIndex <= 4) {
    currentYYYY -= 1;
  } else {
    // eslint-disable-next-line no-self-assign
    currentYYYY = currentYYYY;
  }
  return currentYYYY;
}

// default year non tax
function getDefaultYearForNonTaxable() {
  const date = new Date();
  const month = date.getMonth();
  const correctMonthIndex = month + 1;
  let currentYYYY = date.getFullYear();
  if (correctMonthIndex <= 4) {
    currentYYYY -= 1;
  } else {
    // eslint-disable-next-line no-self-assign
    currentYYYY = currentYYYY;
  }
  return currentYYYY;
}

// default year by campaigns
function getDefaultYearByCampaigns() {
  const date = new Date();
  const month = date.getMonth();
  const correctMonthIndex = month + 1;
  let currentYYYY = date.getFullYear();
  if (correctMonthIndex <= 4) {
    currentYYYY -= 1;
  } else {
    // eslint-disable-next-line no-self-assign
    currentYYYY = currentYYYY;
  }
  return currentYYYY;
}

// get success msg from LS
function getSuccessMsg() {
  const successMsg = localStorage.getItem("successMsg");
  if (successMsg) {
    return successMsg;
  }
  return null;
}

const DashboardComponent = ({ hostName, setLoggedInUserData }) => {
  // navigate
  const navigate = useNavigate();

  // successMsg state
  const [successMsg] = useState(getSuccessMsg());

  // numbers states
  const [noOfDonation, setNoOfDonation] = useState("0.00");
  const [donationAmount, setDonationAmount] = useState("0.00");
  const [minimumAmount, setMinimumAmount] = useState("0.00");
  const [maximumAmount, setMaximumAmount] = useState("0.00");
  const [averageAmount, setAverageAmount] = useState("0.00");
  const [activeRecurring, setActiveRecurring] = useState("0.00");
  const [closedRecurring, setClosedRecurring] = useState("0.00");
  const [noOfOrganizations, setNoOfOrganizations] = useState("0.00");
  const [currencySymbol, setCurrencySymbol] = useState("");

  // api work
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(`${hostName}api/v1/dashboard/numbers`, axiosConfig)
        .then((res) => {
          setNoOfDonation(res.data.numberOfDonation);
          setDonationAmount(res.data.donationAmount);
          setMinimumAmount(res.data.minimumAmount);
          setMaximumAmount(res.data.maximumAmount);
          setAverageAmount(res.data.averageAmount);
          setActiveRecurring(res.data.activeRecurring);
          setClosedRecurring(res.data.closedRecurring);
          setNoOfOrganizations(res.data.numberOfOrganization);
          setCurrencySymbol(res.data.currencySymbol);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
        });
    }
  }, []);

  // recentDonations
  const [recentDonations, setRecentDonations] = useState(null);
  const [recentDonationsLoader, setRecentDonationsLoader] = useState(true);
  const [recentDonationsError, setRecentDonationsError] = useState(null);

  // api work
  function getRecentDonations() {
    setRecentDonations(null);
    setRecentDonationsLoader(true);
    setRecentDonationsError(null);
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(`${hostName}api/v1/dashboard/recentTransactions`, axiosConfig)
        .then((res) => {
          if (res.data.data.length > 0) {
            setRecentDonations(res.data.data);
          } else {
            setRecentDonationsError("No Donations Found");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setRecentDonationsError(err.message);
          }
        })
        .finally(() => setRecentDonationsLoader(false));
    }
  }

  // initial call
  useEffect(() => {
    getRecentDonations();
  }, []);

  // Last Top Nth Summary
  const [lastTopData, setLastTopData] = useState(null);
  const [lastTopDataLoader, setLastTopDataLoader] = useState(true);
  const [lastTopDataError, setLastTopDataError] = useState(null);

  // api work
  function getLastTopNthSummary() {
    setLastTopData(null);
    setLastTopDataLoader(true);
    setLastTopDataError(null);
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const language = localStorage.getItem("i18nextLng");
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(
          `${hostName}api/v1/dashboard/lastTopNthSummary?language=${language}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setLastTopData({
              labels: res.data.data.map(
                (individualData) => individualData.month
              ),
              datasets: [
                {
                  label: "Amount",
                  data: res.data.data.map(
                    (individualData) => individualData.amount
                  ),
                  backgroundColor: "#fff",
                  borderColor: "#007c16",
                },
              ],
            });
          } else {
            setLastTopDataError("No data exist");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setLastTopDataError(err.message);
          }
        })
        .finally(() => setLastTopDataLoader(false));
    }
  }

  // initial call
  useEffect(() => {
    getLastTopNthSummary();
  }, []);

  // Taxable Donations
  const [taxableDonations, setTaxableDonations] = useState(null);
  const [taxableDonationsLoader, setTaxableDonationsLoader] = useState(true);
  const [taxableDonationsError, setTaxableDonationsError] = useState(null);
  const [years] = useState(getYears());
  const [defaultYear, setDefaultYear] = useState(getDefaultYear());

  // api work
  function getTaxableDonations() {
    setTaxableDonations(null);
    setTaxableDonationsLoader(true);
    setTaxableDonationsError(null);
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(
          `${hostName}api/v1/dashboard/taxable?year=${defaultYear}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setTaxableDonations({
              labels: res.data.data.map(
                (individualData) => individualData.organizationName
              ),
              datasets: [
                {
                  label: "Amount",
                  data: res.data.data.map(
                    (individualData) => individualData.amount
                  ),
                  backgroundColor: "rgba(0, 124, 22, 0.2)",
                  borderColor: "rgba(0, 124, 22, 1)",
                  borderWidth: 1,
                },
              ],
            });
          } else {
            setTaxableDonationsError("No data exist for the given year");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setTaxableDonationsError(err.message);
          }
        })
        .finally(() => setTaxableDonationsLoader(false));
    }
  }

  // initial render
  useEffect(() => {
    getTaxableDonations();
  }, [defaultYear]);

  // non taxable donations
  const [nontaxableDonations, setNonTaxableDonations] = useState(null);
  const [nontaxableDonationsLoader, setNonTaxableDonationsLoader] =
    useState(true);
  const [nontaxableDonationsError, setNonTaxableDonationsError] =
    useState(null);
  const [yearsNonTax] = useState(getYearsNonTax());
  const [defaultYearForNonTax, setDefaultYearForNonTax] = useState(
    getDefaultYearForNonTaxable()
  );

  // api work
  function getNonTaxableDonations() {
    setNonTaxableDonations(null);
    setNonTaxableDonationsLoader(true);
    setNonTaxableDonationsError(null);
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(
          `${hostName}api/v1/dashboard/nonTaxable?year=${defaultYearForNonTax}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setNonTaxableDonations({
              labels: res.data.data.map(
                (individualData) => individualData.organizationName
              ),
              datasets: [
                {
                  label: "Amount",
                  data: res.data.data.map(
                    (individualData) => individualData.amount
                  ),
                  backgroundColor: "rgba(0, 124, 22, 0.2)",
                  borderColor: "rgba(0, 124, 22, 1)",
                  borderWidth: 1,
                },
              ],
            });
          } else {
            setNonTaxableDonationsError("No data exist for the given year");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setNonTaxableDonationsError(err.message);
          }
        })
        .finally(() => setNonTaxableDonationsLoader(false));
    }
  }

  // initial render
  useEffect(() => {
    getNonTaxableDonations();
  }, [defaultYearForNonTax]);

  // by campaigns donations
  const [byCampaignsDonations, setByCampaignsDonations] = useState(null); // array of objects, will be used in doughnut
  const [byCampaignsDonationsLoader, setByCampaignsDonationsLoader] =
    useState(true); // loader
  const [byCampaignsDonationsError, setByCampaignsDonationsError] =
    useState(null); // error
  const [yearsByCampaigns] = useState(getYearsByCampaign()); // year options
  const [defaultYearByCampaigns, setDefaultYearByCampaigns] = useState(
    getDefaultYearByCampaigns() // default or selected year, can be changed from dropdown
  );

  // organizations
  const [organizationOptions, setOrganizationOptions] = useState([]); // org options
  const [allOrgKeys, setAllOrgKeys] = useState([]); // org options with all keys
  const [selectedOrganization, setSelectedOrganization] = useState(null); // selected org, can be changed from dropdown

  // api work
  function getCampaigns() {
    setByCampaignsDonations(null);
    setByCampaignsDonationsLoader(true);
    setByCampaignsDonationsError(null);
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get(
          `${hostName}api/v1/dashboard/byCampaign?year=${defaultYearByCampaigns}`,
          axiosConfig
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setAllOrgKeys(res.data.data);
            const organizations = res.data.data.map(
              (individualOrganization) =>
                individualOrganization.organizationName
            );
            const removeDuplicates = [...new Set(organizations)]; // removeDuplicates is just filtering the object to get only org names
            setOrganizationOptions(removeDuplicates);
            setSelectedOrganization(removeDuplicates[0]);
            const filteredOrganizations = res.data.data.filter(
              (individualOrganization) =>
                individualOrganization.organizationName === removeDuplicates[0]
            );
            function getRandomColors() {
              const borderColors = [
                "rgba(0,124,22,1)",
                "rgba(81,200,120,1)",
                "rgba(170,186,159,1)",
                "rgba(132,155,101,1)",
                "rgba(61,178,100,1)",
                "rgba(158,205,126,1)",
                "rgba(142,187,78,1)",
                "rgba(108,158,55,1)",
                "rgba(118,165,67,1)",
                "rgba(66,109,20,1)",
              ];
              const bgColors = [
                "rgba(0,124,22,0.1)",
                "rgba(81,200,120,0.1)",
                "rgba(170,186,159,0.1)",
                "rgba(132,155,101,0.1)",
                "rgba(61,178,100,0.1)",
                "rgba(158,205,126,0.1)",
                "rgba(142,187,78,0.1)",
                "rgba(108,158,55,0.1)",
                "rgba(118,165,67,0.1)",
                "rgba(66,109,20,0.1)",
              ];
              const slicedBorderColors = borderColors.slice(
                0,
                filteredOrganizations.length
              );
              const slicedBgColors = bgColors.slice(
                0,
                filteredOrganizations.length
              );
              return [slicedBorderColors, slicedBgColors];
            }
            const randomColors = getRandomColors();
            setByCampaignsDonations({
              labels: filteredOrganizations.map(
                (individualData) => individualData.campaign
              ),
              datasets: [
                {
                  label: "Amount",
                  data: filteredOrganizations.map((individualData) =>
                    Number(individualData.amount)
                  ),
                  borderColor: randomColors[0],
                  backgroundColor: randomColors[1],
                  borderWidth: 2,
                  hoverOffset: 4,
                },
              ],
            });
          } else {
            setByCampaignsDonationsError("No data exist for the given year");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setByCampaignsDonationsError(err.message);
          }
        })
        .finally(() => setByCampaignsDonationsLoader(false));
    }
  }

  // initial render
  useEffect(() => {
    getCampaigns();
  }, [defaultYearByCampaigns]);

  // handle select
  const handleSelectedOrganization = (value) => {
    setSelectedOrganization(value);
    if (allOrgKeys.length > 0 && selectedOrganization !== value) {
      const filteredOrganizations = allOrgKeys.filter(
        (individualOrganization) =>
          individualOrganization.organizationName === value
      );
      function getRandomColors() {
        const borderColors = [
          "rgba(0,124,22,1)",
          "rgba(81,200,120,1)",
          "rgba(170,186,159,1)",
          "rgba(132,155,101,1)",
          "rgba(61,178,100,1)",
          "rgba(158,205,126,1)",
          "rgba(142,187,78,1)",
          "rgba(108,158,55,1)",
          "rgba(118,165,67,1)",
          "rgba(66,109,20,1)",
        ];
        const bgColors = [
          "rgba(0,124,22,0.1)",
          "rgba(81,200,120,0.1)",
          "rgba(170,186,159,0.1)",
          "rgba(132,155,101,0.1)",
          "rgba(61,178,100,0.1)",
          "rgba(158,205,126,0.1)",
          "rgba(142,187,78,0.1)",
          "rgba(108,158,55,0.1)",
          "rgba(118,165,67,0.1)",
          "rgba(66,109,20,0.1)",
        ];
        const slicedBorderColors = borderColors.slice(
          0,
          filteredOrganizations.length
        );
        const slicedBgColors = bgColors.slice(0, filteredOrganizations.length);
        return [slicedBorderColors, slicedBgColors];
      }
      const randomColors = getRandomColors();
      setByCampaignsDonations({
        labels: filteredOrganizations.map(
          (individualData) => individualData.campaign
        ),
        datasets: [
          {
            label: "Amount",
            data: filteredOrganizations.map((individualData) =>
              Number(individualData.amount)
            ),
            borderColor: randomColors[0],
            backgroundColor: randomColors[1],
            borderWidth: 2,
            hoverOffset: 4,
          },
        ],
      });
    }
  };

  // translation
  const { t } = useTranslation();

  return (
    <>
      {/* successMsg */}
      {successMsg && <div className="user-message">{successMsg}</div>}

      {/* main heading */}
      <h1 className="main-heading">{t("Stats.dashboard")}</h1>

      {/* stats (left) and recent-donations box (right) */}
      <div className="stats-recent-donations-box">
        <div className="stats-last-top-box">
          {/* stats */}
          <div className="numbers-data">
            <Swiper
              slidesPerView={4}
              loop
              keyboard
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              {/* card */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={ecommerce_gift} size={28} />
                  </div>
                  <h6>{t("Stats.noOfDonations")}</h6>
                  <span className="number-itself">{noOfDonation}</span>
                </div>
              </SwiperSlide>

              {/* card 2 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={ecommerce_dollar} size={28} />
                  </div>
                  <h6>{t("Stats.donationAmount")}</h6>
                  <span className="number-itself">
                    {currencySymbol}
                    {donationAmount}
                  </span>
                </div>
              </SwiperSlide>

              {/* card 3 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={ecommerce_graph_decrease} size={28} />
                  </div>
                  <h6>{t("Stats.minimumAmount")}</h6>
                  <span className="number-itself">
                    {currencySymbol}
                    {minimumAmount}
                  </span>
                </div>
              </SwiperSlide>

              {/* card 4 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={ecommerce_graph_increase} size={28} />
                  </div>
                  <h6>{t("Stats.maximumAmount")}</h6>
                  <span className="number-itself">
                    {currencySymbol}
                    {maximumAmount}
                  </span>
                </div>
              </SwiperSlide>

              {/* card 5 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={ecommerce_graph2} size={28} />
                  </div>
                  <h6>{t("Stats.averageAmount")}</h6>
                  <span className="number-itself">
                    {currencySymbol}
                    {averageAmount}
                  </span>
                </div>
              </SwiperSlide>

              {/* card 6 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={arrows_rotate} size={28} />
                  </div>
                  <h6>{t("Stats.activeRecurring")}</h6>
                  <span className="number-itself">{activeRecurring}</span>
                </div>
              </SwiperSlide>

              {/* card 7 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <img
                      src={arrowsRefresh}
                      alt="arrows-refresh"
                      width={32}
                      height={32}
                    />
                  </div>
                  <h6>{t("Stats.closedRecurring")}</h6>
                  <span className="number-itself">{closedRecurring}</span>
                </div>
              </SwiperSlide>

              {/* card 8 */}
              <SwiperSlide>
                <div className="numbers-card">
                  <div className="numbers-card-icon">
                    <Icon icon={basic_heart} size={28} />
                  </div>
                  <h6>{t("Stats.myOrganizations")}</h6>
                  <span className="number-itself">{noOfOrganizations}</span>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* last top Nth summary */}
          <div className="last-top-box">
            <header className="recent-donations-header">
              <h3>{t("LastTopNSummary.lastTopNSummary")}</h3>
              <button type="button" onClick={getLastTopNthSummary}>
                <span style={{ marginRight: `${8}px` }}>
                  <Icon icon={arrows_rotate} size={16} />
                </span>
              </button>
            </header>
            <main className="recent-donations-box">
              {lastTopDataLoader ? (
                <div className="recent-donations-loader">
                  <ClapSpinner
                    size={30}
                    color="#007c16"
                    loading={lastTopDataLoader}
                  />
                </div>
              ) : (
                <>
                  {lastTopDataError ? (
                    <div className="error-msg">{lastTopDataError}</div>
                  ) : (
                    <>
                      {lastTopData && (
                        <div className="chart-container">
                          <Line
                            data={lastTopData}
                            options={{
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  position: "bottom",
                                  labels: {
                                    usePointStyle: true,
                                    pointStyle: "circle",
                                  },
                                },
                                tooltip: {
                                  backgroundColor: "#fff",
                                  titleColor: "#000",
                                  bodyColor: "#000",
                                },
                                datalabels: {
                                  display: false,
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </main>
          </div>
        </div>

        {/* recent box */}
        <div className="recent-box">
          {/* recent donations */}
          <header className="recent-donations-header">
            <h3>{t("RecentDonations.recentDonations")}</h3>
            <button type="button" onClick={getRecentDonations}>
              <span style={{ marginRight: `${8}px` }}>
                <Icon icon={arrows_rotate} size={16} />
              </span>
            </button>
          </header>
          <main className="recent-donations-box">
            {recentDonationsLoader ? (
              <div className="recent-donations-loader">
                <ClapSpinner
                  size={30}
                  color="#007c16"
                  loading={recentDonationsLoader}
                />
              </div>
            ) : (
              <>
                {recentDonationsError ? (
                  <div className="error-msg">{recentDonationsError}</div>
                ) : (
                  <>
                    {recentDonations && recentDonations.length > 0 && (
                      <>
                        {recentDonations.map((recentDonation) => (
                          <div
                            className="recent-donations-card"
                            key={recentDonation.tagNumber}
                          >
                            <div className="campaign-img-and-details-div">
                              <Tippy content={recentDonation.campaign}>
                                <div className="recent-campaign-img">
                                  <img
                                    src={recentDonation.campaignLogo}
                                    alt="campaignLogo"
                                  />
                                </div>
                              </Tippy>
                              <div className="recent-details-box">
                                <h5>{recentDonation.campaign}</h5>
                                <h5>{recentDonation.organization}</h5>
                                <h5>
                                  {recentDonation.currencySymbol}{" "}
                                  {recentDonation.approvedAmount}
                                </h5>
                                <h5 className="transaction-date">
                                  {recentDonation.transactionDate}
                                </h5>
                              </div>
                            </div>

                            <Tippy content={recentDonation.cardBrand}>
                              <div className="recent-card-img">
                                <img
                                  src={recentDonation.cardImage}
                                  alt="card"
                                />
                              </div>
                            </Tippy>
                            {/*<Tippy
                              content={
                                recentDonation.taxDeductible === true
                                  ? `${t("RecentDonations.taxDeductible")}`
                                  : `${t("RecentDonations.notTaxDeductible")}`
                              }
                            >
                              <div className="recent-card-img tax">
                                <img
                                  src={recentDonation.taxDeductibleImage}
                                  alt="tax-deductible"
                                />
                              </div>
                            </Tippy>*/}
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </main>
        </div>
      </div>

      {/* taxable and non taxable donations container */}
      <div className="tax-and-nontax-container">
        {/* Taxable donations */}
        <div className="taxable-container">
          <header className="recent-donations-header">
            <h3>{t("TaxableDonations.taxableDonations")}</h3>
            <MDBDropdown>
              <MDBDropdownToggle className="taxable-select">
                {defaultYear || ""}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                {years.map((year) => (
                  <MDBDropdownItem
                    link
                    key={year}
                    id={year}
                    onClick={(e) => setDefaultYear(Number(e.currentTarget.id))}
                  >
                    {year}
                  </MDBDropdownItem>
                ))}
              </MDBDropdownMenu>
            </MDBDropdown>
          </header>
          <main className="recent-donations-box">
            {taxableDonationsLoader ? (
              <div className="recent-donations-loader">
                <ClapSpinner
                  size={30}
                  color="#007c16"
                  loading={taxableDonationsLoader}
                />
              </div>
            ) : (
              <>
                {taxableDonationsError ? (
                  <div className="error-msg">{taxableDonationsError}</div>
                ) : (
                  <>
                    {taxableDonations && (
                      <div className="chart-container">
                        <Bar
                          data={taxableDonations}
                          options={{
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "bottom",
                                labels: {
                                  usePointStyle: true,
                                  pointStyle: "circle",
                                },
                              },
                              tooltip: {
                                backgroundColor: "#fff",
                                titleColor: "#000",
                                bodyColor: "#000",
                              },
                              datalabels: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </main>
        </div>
        {/* NonTaxable donations */}
        <div className="nontaxable-container">
          <header className="recent-donations-header">
            <h3>{t("TaxableDonations.nontaxableDonations")}</h3>
            <MDBDropdown>
              <MDBDropdownToggle className="taxable-select">
                {defaultYearForNonTax || ""}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                {yearsNonTax.map((year) => (
                  <MDBDropdownItem
                    link
                    key={year}
                    id={year}
                    onClick={(e) =>
                      setDefaultYearForNonTax(Number(e.currentTarget.id))
                    }
                  >
                    {year}
                  </MDBDropdownItem>
                ))}
              </MDBDropdownMenu>
            </MDBDropdown>
          </header>
          <main className="recent-donations-box">
            {nontaxableDonationsLoader ? (
              <div className="recent-donations-loader">
                <ClapSpinner
                  size={30}
                  color="#007c16"
                  loading={nontaxableDonationsLoader}
                />
              </div>
            ) : (
              <>
                {nontaxableDonationsError ? (
                  <div className="error-msg">{nontaxableDonationsError}</div>
                ) : (
                  <>
                    {nontaxableDonations && (
                      <div className="chart-container">
                        <Bar
                          data={nontaxableDonations}
                          options={{
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "bottom",
                                labels: {
                                  usePointStyle: true,
                                  pointStyle: "circle",
                                },
                              },
                              tooltip: {
                                backgroundColor: "#fff",
                                titleColor: "#000",
                                bodyColor: "#000",
                              },
                              datalabels: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </main>
        </div>
      </div>

      <div className="bycampaigns-container">
        {/* by campaigns */}
        <div className="by-campaigns-box">
          <header className="recent-donations-header">
            <h3>{t("ByCampaigns.byCampaigns")}</h3>
            <MDBDropdown>
              <MDBDropdownToggle className="taxable-select">
                {defaultYearByCampaigns || ""}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                {yearsByCampaigns.map((year) => (
                  <MDBDropdownItem
                    link
                    key={year}
                    id={year}
                    onClick={(e) =>
                      setDefaultYearByCampaigns(Number(e.currentTarget.id))
                    }
                  >
                    {year}
                  </MDBDropdownItem>
                ))}
              </MDBDropdownMenu>
            </MDBDropdown>
          </header>
          <main className="recent-donations-box">
            {byCampaignsDonationsLoader ? (
              <div className="recent-donations-loader">
                <ClapSpinner
                  size={30}
                  color="#007c16"
                  loading={byCampaignsDonationsLoader}
                />
              </div>
            ) : (
              <>
                {byCampaignsDonationsError ? (
                  <div className="error-msg">{byCampaignsDonationsError}</div>
                ) : (
                  <>
                    {byCampaignsDonations && (
                      <>
                        <div className="organization-select-div">
                          <label className="organization-select-label">
                            {t("ByCampaigns.filterByOrganizationNames")}
                          </label>
                          <MDBDropdown>
                            <MDBDropdownToggle className="organization-select">
                              {selectedOrganization || ""}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu
                              className="custom-menu"
                              data-simplebar
                              id="sidebar"
                            >
                              {organizationOptions.map((organization) => (
                                <MDBDropdownItem
                                  link
                                  key={organization}
                                  id={organization}
                                  onClick={(e) =>
                                    handleSelectedOrganization(
                                      e.currentTarget.id
                                    )
                                  }
                                >
                                  {organization}
                                </MDBDropdownItem>
                              ))}
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </div>
                        <div className="chart-container doughnut">
                          <Doughnut data={byCampaignsDonations} />
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
