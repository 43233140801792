/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-icons-kit";
import { arrows_left } from "react-icons-kit/linea/arrows_left";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { ClapSpinner } from "react-spinners-kit";
import axios from "axios";
import logo from "../../images/logo.png";
import Select from "react-select";
import RegisterEmbeddedDonor from "./RegisterEmbeddedDonor";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

export const AddInformation = ({
  hostName,
  organizationDetails,
  step,
  setStep,
  amount,
  newAmount,
  setNewAmount,
  amountValid,
  howOften,
  setHowOften,
  recurringValue,
  validRecurring,
  specifyTime,
  fees,
  setFees,
  isChecked,
  cardHolderName,
  setCardHolderName,
  cardHolderEmail,
  setCardHolderEmail,
  setSuccessMsg,
  setPaymentSuccess,
  lang,
  handleLanguage,
  paths,
  vTag,
  currentQuery,
  startDate,
  quantity,
  charityDetails,
}) => {
  // translation
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // if user cheats and directly come to this step, navigate the user
  useEffect(() => {
    if (!charityDetails.enableQuantity) {
      if (!amountValid || !validRecurring) {
        setStep(0);
        if (currentQuery) {
          navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, {
            replace: true,
          });
        } else {
          navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
        }
      }
    }
  }, []);

  // component step
  const [componentStep, setComponentStep] = useState("payment");

  // Function to generate the month options
  const generateMonthOptions = () => {
    const monthOptions = [];

    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : `${i}`;
      monthOptions.push({
        value: monthValue,
        label: monthValue,
      });
    }

    return monthOptions;
  };

  // Generate the month options
  const expiryMonthOptions = generateMonthOptions();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Function to generate the expiry year options
  const generateExpiryYearOptions = (startYear, numberOfYears) => {
    const expiryYearOptions = [];

    for (let i = 0; i < numberOfYears; i++) {
      const year = startYear + i;
      expiryYearOptions.push({
        value: String(year).slice(-2),
        label: String(year),
      });
    }

    return expiryYearOptions;
  };

  // Generate the expiry year options for the next 15 years
  const expiryYearOptions = generateExpiryYearOptions(currentYear, 15);

  // donation event states
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [nodeTag, setNodeTag] = useState("");
  const [campaignTag, setCampaignTag] = useState("");
  const [transactionError, setTransactionError] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setNodeTag(organizationDetails.node.tagNumber);
    setCampaignTag(charityDetails.tagNumber);
  }, [organizationDetails]);

  // option states
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  // retry count
  const [retryCount, setRetryCount] = useState(0);

  // new amount and fees
  useEffect(() => {
    if (charityDetails.enableQuantity) {
      setNewAmount(
        parseInt(quantity) * parseFloat(charityDetails.minimumAmount)
      );
      setFees(0);
      setHowOften("ONETIME");
    } else {
      const feesExist = parseFloat(charityDetails.fees) > 0;

      if (feesExist && isChecked) {
        const feeAmount = new BigNumber(parseFloat(charityDetails.fees))
          .dividedBy(100)
          .times(parseFloat(amount))
          .toFixed(2);

        const updatedAmount = new BigNumber(parseFloat(amount))
          .plus(feeAmount)
          .toFixed(2);

        setFees(feeAmount);
        setNewAmount(updatedAmount);
      } else {
        setNewAmount(amount);
        setFees(0);
      }
    }
  }, [isChecked, charityDetails.enableQuantity]);

  // Common function to load and initialize reCAPTCHA
  const loadAndInitializeRecaptcha = () => {
    // Retry loading reCAPTCHA script on button click
    const retryLoadReCaptchaScript = () => {
      if (retryCount < 3) {
        setRetryCount((prevCount) => prevCount + 1);
        loadReCaptchaScript();
      } else {
        setLoader(false);
        setTransactionError(
          "Unable to load reCAPTCHA after multiple retries. Please try again later."
        );
      }
    };

    // Load reCAPTCHA script
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = initializeRecaptcha;
      document.head.appendChild(script);
    };

    // Initialize reCAPTCHA
    const initializeRecaptcha = () => {
      console.log("reCAPTCHA script loaded");
      console.log("window.grecaptcha:", window.grecaptcha);
      console.log(
        "typeof window.grecaptcha.execute:",
        typeof window.grecaptcha.execute
      );
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.execute === "function"
      ) {
        window.grecaptcha
          .execute("6LelmPUjAAAAAFA5Ks_St3J5yxjK4Jce7VBKsJgW", {
            action: "CHECKOUT",
          })
          .then((token) => {
            if (token) {
              // Process payment based on the type (onetime or recurring)
              const paymentData =
                howOften === "ONETIME"
                  ? {
                      frequency: "once",
                      numbers: 1,
                    }
                  : {
                      billingPeriod: howOften,
                      noOfRecurring: recurringValue,
                      startDate: formatDate(startDate),
                    };

              axios
                .post(
                  howOften === "ONETIME"
                    ? `${hostName}api/v1/transaction/byRawCard`
                    : `${hostName}api/v1/transaction/recurringByCard`,
                  {
                    ...paymentData,
                    campaignTag,
                    amount: parseFloat(newAmount),
                    nodeTag,
                    cardHolderName,
                    cardNumber,
                    expiryMonth,
                    expiryYear,
                    ccv: cvc,
                    email: cardHolderEmail,
                    fee: parseFloat(fees),
                    recaptchaToken: token,
                    ...(vTag && { vTag }),
                  }
                )
                .then((res) => {
                  setSuccessMsg(res.data.message);
                  setStep(step + 1);
                  setPaymentSuccess(true);
                })
                .catch((err) => {
                  if (err.response.data.message) {
                    setTransactionError(err.response.data.message);
                  } else {
                    setTransactionError(err.message);
                  }
                })
                .finally(() => setLoader(false));
            } else {
              setLoader(false);
              setTransactionError(
                "No reCAPTCHA token found. Please try again later."
              );
            }
          })
          .catch((err) => {
            setLoader(false);
            setTransactionError(
              `${err.message} in executing reCAPTCHA. Please try again later.`
            );
          });
      } else {
        retryLoadReCaptchaScript();
      }
    };

    // Check if grecaptcha is already loaded, if not, load the script
    if (window.grecaptcha) {
      initializeRecaptcha();
    } else {
      loadReCaptchaScript();
    }
  };

  // Handle donate
  const handleDonate = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(cardHolderEmail)) {
      if (organizationDetails.forceRegistration === "Y") {
        setLoader(true);
        setTransactionError(null);
        // Check if email exists
        axios
          .get(`${hostName}api/v1/auth/exist?email=${cardHolderEmail}`)
          .then((res) => {
            if (res.data.exist) {
              loadAndInitializeRecaptcha();
            } else {
              // Handle registration options
              Promise.all([
                axios.get(`${hostName}api/v1/public/country`),
                axios.get(`${hostName}api/v1/public/state?countryCode=US`),
                axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
              ])
                .then(([countriesRes, usaStatesRes, provincesRes]) => {
                  const countryOptions = countriesRes.data.map((country) => ({
                    value: country.code,
                    label: country.name,
                  }));
                  setCountries(countryOptions);

                  const usaStatesOptions = usaStatesRes.data.map((state) => ({
                    value: state.code,
                    label: state.name,
                  }));
                  setUsaStates(usaStatesOptions);

                  const provincesOptions = provincesRes.data.map(
                    (province) => ({
                      value: province.code,
                      label: province.name,
                    })
                  );
                  setProvinces(provincesOptions);

                  setComponentStep("register");
                })
                .catch((error) => {
                  setTransactionError(
                    `${error.message} in fetching countries block of code.`
                  );
                })
                .finally(() => setLoader(false));
            }
          })
          .catch((err) => {
            setLoader(false);
            setTransactionError(
              `${err.message} in verifying email existence. Please try again later.`
            );
          });
      } else if (organizationDetails.forceRegistration === "N") {
        setLoader(true);
        setTransactionError(null);
        loadAndInitializeRecaptcha();
      } else {
        console.log("force registration attribute not available");
      }
    } else {
      setTransactionError("Email field should be a valid email address.");
    }
  };

  // percentage
  const [percentage, setPercentage] = useState(null);
  const [width, setWidth] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  useEffect(() => {
    if (charityDetails) {
      const calculatedPercentage = (
        (charityDetails.raisedAmount / charityDetails.targetAmount) *
        100
      ).toFixed(2);
      const calculatedWidth =
        calculatedPercentage >= 100 || !isFinite(calculatedPercentage)
          ? "100%"
          : `${calculatedPercentage}%`;
      const calculatedBackgroundColor = "#007c16";

      setPercentage(calculatedPercentage);
      setWidth(calculatedWidth);
      setBackgroundColor(calculatedBackgroundColor);
    }
  }, [charityDetails]);

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  return (
    <div className="home-content">
      <div className="embed-charity-details-container">
        {/* loader */}
        {loader && (
          <div className="loading-screen" style={{ zIndex: 1000 }}>
            <ClapSpinner size={30} color="#007c16" loading={loader} />
          </div>
        )}

        {/* header */}
        <header className="embed-charity-details-header">
          <div
            onClick={() =>
              charityDetails.enableQuantity
                ? setStep(1)
                : setStep(parseFloat(charityDetails.fees) > 0 ? 3 : 2)
            }
            className="arrow-left-span"
          >
            <Icon icon={arrows_left} size={32} />
          </div>
          <h5 className="choose-amount">
            {componentStep === "payment"
              ? t("Embed.add_your_information")
              : t("Embed.register_profile")}
          </h5>
        </header>

        {/* charity logo and organization name */}
        <div className="embed-logo-container">
          <div className="embed-campaign-logo">
            <img src={charityDetails.icon.filename} alt="card-logo" />
          </div>
          <div className="embed-charity-organization-name">
            <div className="embed-charity-name">{charityDetails.name}</div>
            <div className="embed-organization-name">
              {organizationDetails.name}
            </div>
          </div>
        </div>

        {charityDetails.targetAmount > 0 && (
          <>
            {width && backgroundColor && (
              <div className="campaign-details-progress-div">
                <div
                  className="progress-bar"
                  style={{
                    width,
                    background: backgroundColor,
                  }}
                ></div>
              </div>
            )}
          </>
        )}

        {charityDetails.targetAmount > 0 && (
          <div className="campaign-collection">
            <div className="collection-box">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.raisedAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.raised")}</h6>
            </div>

            <div className="collection-box">
              {percentage && (
                <>
                  <h4>
                    {percentage >= 100 || !isFinite(percentage)
                      ? 100
                      : percentage}
                    %
                  </h4>
                  <h6>{t("CampaignDetails.collection")}</h6>
                </>
              )}
            </div>
            <div className="collection-box last">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.targetAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.target")}</h6>
            </div>
          </div>
        )}

        {/* who is giving today */}
        <div className="who-is-giving-today">
          <h5>{t("Embed.who_is_giving_today")}</h5>
          <p>{t("Embed.we_will_never_share")}</p>
        </div>

        {/* step specific content */}
        {componentStep === "register" ? (
          <RegisterEmbeddedDonor
            hostName={hostName}
            setStep={setStep}
            donationAmount={newAmount}
            howOften={howOften}
            recurringValue={recurringValue}
            fees={fees}
            cardHolderName={cardHolderName}
            donorEmail={cardHolderEmail}
            cardNumber={cardNumber}
            expiryMonth={expiryMonth}
            expiryYear={expiryYear}
            cvc={cvc}
            setSuccessMsg={setSuccessMsg}
            setPaymentSuccess={setPaymentSuccess}
            setComponentStep={setComponentStep}
            loader={loader}
            setLoader={setLoader}
            setTransactionError={setTransactionError}
            campaignTag={campaignTag}
            nodeTag={nodeTag}
            countries={countries}
            usaStates={usaStates}
            provinces={provinces}
            vTag={vTag}
            startDate={startDate}
            formatDate={formatDate}
          />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleDonate}
            style={{
              paddingRight: `${10}px`,
              paddingLeft: `${10}px`,
            }}
          >
            <div className="card-row">
              <div className="card-col">
                <label>{t("DonationModal.cardholder_name")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value)}
                  required
                  placeholder={t("DonationModal.cardholder_name")}
                />
              </div>
            </div>

            <div className="card-row">
              <div className="card-col">
                <label>{t("DonationModal.email")}</label>
                <input
                  type="email"
                  className="edit-media-inputs"
                  value={cardHolderEmail}
                  onChange={(e) => setCardHolderEmail(e.target.value)}
                  required
                  placeholder={t("DonationModal.email")}
                />
              </div>
            </div>

            <div className="card-row">
              <div className="card-col">
                <label>{t("DonationModal.card_number")}</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  required
                  placeholder={t("DonationModal.card_number")}
                  pattern=".{15,}"
                  title="Please enter a card number with at least 15 characters"
                />
              </div>
            </div>

            {/* {vTag && (
              <div className="card-row">
                <div className="card-col">
                  <label>vTag</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={vTag}
                    readOnly
                  />
                </div>
              </div>
            )} */}

            <div className="card-row">
              <div className="card-col">
                <label>{t("DonationModal.expiry_month")}</label>
                <Select
                  styles={styles}
                  required
                  menuPlacement="top"
                  value={expiryMonthOptions.find(
                    (option) => option.value === expiryMonth
                  )}
                  onChange={(option) => setExpiryMonth(option.value)}
                  options={expiryMonthOptions}
                />
              </div>
              <div className="card-col">
                <label>{t("DonationModal.expiry_year")}</label>
                <Select
                  styles={styles}
                  required
                  menuPlacement="top"
                  value={expiryYearOptions.find(
                    (option) => option.value === expiryYear
                  )}
                  onChange={(option) => setExpiryYear(option.value)}
                  options={expiryYearOptions}
                />
              </div>
              <div className="card-col">
                <label>CVC</label>
                <input
                  type="text"
                  className="edit-media-inputs"
                  value={cvc}
                  onChange={(e) => setCvc(e.target.value)}
                  required
                  placeholder="CVC"
                />
              </div>
            </div>

            {/* here is what */}
            <div className="who-is-giving-today">
              <h5>{t("Embed.here_is_what")}</h5>
            </div>

            {/* donation summary */}
            <main className="transaction-info-box">
              <div
                className="transaction-info"
                onClick={() => setStep(1)}
                style={{
                  pointerEvents: loader ? "none" : "auto",
                }}
              >
                <h5 style={{ fontWeight: 600 }}>
                  {charityDetails.donationCampaign ? (
                    <>{t("Embed.donation_summary")}</>
                  ) : (
                    <>{t("Embed.payment_summary")}</>
                  )}
                </h5>
                <h5
                  style={{
                    fontSize: `${14}px`,
                    color: "red",
                    cursor: "pointer",
                  }}
                >
                  <Icon icon={software_pencil} size={18} />{" "}
                  {charityDetails.donationCampaign ? (
                    <>{t("Embed.edit_donation")}</>
                  ) : (
                    <>{t("Embed.edit_payment")}</>
                  )}
                </h5>
              </div>
              <div className="transaction-info">
                <h5>
                  {charityDetails.enableQuantity
                    ? `${t("CampaignDetails.cost_per_unit")}`
                    : `${t("DonationModal.amount")}`}
                </h5>
                <h5>
                  {organizationDetails.currencySymbol}
                  {charityDetails.enableQuantity
                    ? `${charityDetails.minimumAmount}`
                    : `${amount}`}
                </h5>
              </div>
              {parseFloat(charityDetails.fees) > 0 &&
                !charityDetails.enableQuantity && (
                  <>
                    <div className="transaction-info">
                      <h5>{t("DonationModal.fee")}</h5>
                      <h5>
                        {organizationDetails.currencySymbol}
                        {fees}
                      </h5>
                    </div>
                    <div className="transaction-info">
                      <h5>{t("DonationModal.total_amount")}</h5>
                      <h5>
                        {organizationDetails.currencySymbol}
                        {newAmount}
                      </h5>
                    </div>
                  </>
                )}
              {charityDetails.enableQuantity && (
                <>
                  <div className="transaction-info">
                    <h5>{t("CampaignDetails.quantity")}</h5>
                    <h5>{quantity}</h5>
                  </div>
                  <div className="transaction-info">
                    <h5>{t("DonationModal.total_amount")}</h5>
                    <h5>
                      {organizationDetails.currencySymbol}
                      {newAmount}
                    </h5>
                  </div>
                </>
              )}
              <div className="transaction-info">
                <h5>{t("DonationModal.billing_period")}</h5>
                <h5>{howOften}</h5>
              </div>
              {howOften !== "ONETIME" && (
                <>
                  {recurringValue && (
                    <div className="transaction-info">
                      <h5>{t("DonationModal.no_of_recurring")}</h5>
                      {specifyTime ? (
                        <h5>{recurringValue}</h5>
                      ) : (
                        <h5>{t("DonationModal.until_you_specify")}</h5>
                      )}
                    </div>
                  )}
                  <div className="transaction-info">
                    <h5>{t("DonationModal.start_date")}</h5>
                    <h5>{formatDate(startDate)}</h5>
                  </div>
                </>
              )}
            </main>

            {/* error */}
            {transactionError && (
              <div className="error-msg" style={{ width: 100 + "%" }}>
                {transactionError}
              </div>
            )}

            {/* continue btn */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: `${20}px`,
                paddingTop: `${20}px`,
              }}
            >
              <button type="submit" className="continue-btn" disabled={loader}>
                {charityDetails.donationCampaign ? (
                  <>{t("Embed.donate_now")}</>
                ) : (
                  <>{t("Embed.pay_now")}</>
                )}
                <span>
                  <Icon icon={arrows_right} size={28} />
                </span>
              </button>
            </div>
          </form>
        )}

        {/* indicators */}
        <div className="indicators-box">
          <span
            className="indicators"
            onClick={() => {
              setStep(0);
              if (currentQuery) {
                navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, {
                  replace: true,
                });
              } else {
                navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
              }
            }}
            style={{
              pointerEvents: loader ? "none" : "auto",
            }}
          />
          <span
            className="indicators"
            onClick={() => setStep(1)}
            style={{
              pointerEvents: loader ? "none" : "auto",
            }}
          />
          {!charityDetails.enableQuantity && (
            <>
              <span
                className="indicators"
                onClick={() => setStep(2)}
                style={{
                  pointerEvents: loader ? "none" : "auto",
                }}
              />
              {parseFloat(charityDetails.fees) > 0 && (
                <span className="indicators" onClick={() => setStep(3)} />
              )}
            </>
          )}
          <span className="indicators active" />
        </div>

        {/* footer */}
        <div className="embedded-footer-box">
          <div>
            <span>
              <Icon icon={basic_lock} size={22} />
            </span>
            <span className="secure-donation">
              {t("OrganizationDetails.secure_donation")}
            </span>
          </div>

          <div className="powered-by">
            {t("Embed.powered_by")}
            <img src={logo} alt="logo" />
          </div>

          {lang === "en" ? (
            <button
              type="button"
              id="fr"
              onClick={(e) => handleLanguage(e.currentTarget.id)}
              className="change-lang-btn embed"
              disabled={loader}
            >
              française
            </button>
          ) : (
            <button
              type="button"
              id="en"
              onClick={(e) => handleLanguage(e.currentTarget.id)}
              className="change-lang-btn embed"
              disabled={loader}
            >
              English
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddInformation;
