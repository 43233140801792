import { ClapSpinner } from "react-spinners-kit";

const CacheLoading = () => {
  return (
    <div className="cache-loading">
        <ClapSpinner loading={true} size={30}/>
    </div>
  );
};

export default CacheLoading;
