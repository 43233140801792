import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const ReceiptModal = ({ setReceiptModal, receiptDetails }) => {
  // getting organization and charity link
  const [organizationLink, setOrganizationLink] = useState(null);
  const [charityLink, setCharityLink] = useState(null);
  useEffect(() => {
    // organization link
    if (receiptDetails.organizationShortName) {
      const clean = receiptDetails.organizationShortName.replace(
        /[^a-zA-Z0-9]/g,
        ""
      );
      const lowercase = clean.toLowerCase();
      setOrganizationLink(lowercase);
      // charity link
      const charityName = receiptDetails.campaign;
      const trimming = charityName.replace(/[^a-zA-Z0-9]/g, "");
      const lowercaseCharity = trimming.toLowerCase();
      setCharityLink(lowercaseCharity);
    } else {
      setOrganizationLink(null);
      setCharityLink(null);
    }
  }, [receiptDetails]);

  // view pdf
  const [step, setStep] = useState(1);
  const viewPDF = () => {
    setStep(step + 1);
  };

  // download pdf
  const [downloadLoader, setDownloadLoader] = useState(false);
  const downloadReceipt = () => {
    setDownloadLoader(true);
    const capture = document.querySelector(".actual-receipt");
    html2canvas(capture, {
      logging: true,
      letterRender: 1,
      useCORS: true,
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setDownloadLoader(false);
      if (organizationLink !== null) {
        doc.save(`${organizationLink}-${charityLink}.pdf`);
      } else {
        doc.save("receipt.pdf");
      }
    });
  };

  // print pdf
  const printReceipt = () => {
    window.print();
  };

  // translation
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="receipt-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {/* header */}
          <header className="receipt-modal-header">
            <h4>{t("ReceiptModal.transaction_details")}</h4>
            <button
              type="button"
              className="cross-icon donations"
              disabled={downloadLoader}
              onClick={() => setReceiptModal(false)}
            >
              X
            </button>
          </header>
          {step === 1 && (
            <>
              <div className="receipt-modal-body">
                {/* organization/charity with amount */}
                <div className="receipt-info">
                  <div>
                    <h4>{receiptDetails.organization}</h4>
                    <h6 className="receipt-campaign">
                      {receiptDetails.campaign}
                    </h6>
                  </div>
                  <div>
                    <h2>${receiptDetails.approvedAmount}</h2>
                  </div>
                </div>

                {/* date source and type */}
                <div className="receipt-other-info">
                  <div className="receipt-row">
                    <h5 className="receipt-key">DATE</h5>
                    <h5 className="receipt-value">
                      {receiptDetails.inputDate}
                    </h5>
                  </div>
                  <div className="receipt-row">
                    <h5 className="receipt-key">{t("ReceiptModal.source")}</h5>
                    <h5 className="receipt-value">{receiptDetails.nodeType}</h5>
                  </div>
                  <div className="receipt-row">
                    <h5 className="receipt-key">{t("ReceiptModal.type")}</h5>
                    <h5 className="receipt-value">{receiptDetails.cardType}</h5>
                  </div>
                </div>

                {/* card image and account */}
                <div className="receipt-card-info">
                  <div className="receipt-card-img-div">
                    <img
                      src={receiptDetails.cardImage}
                      alt="card"
                      className="card-img"
                    />
                  </div>
                  <h5>{receiptDetails.cardAccount}</h5>
                </div>

                {/* links */}
                {organizationLink && (
                  <>
                    {/* open campaign */}
                    <Link
                      to={`/${organizationLink}/${charityLink}`}
                      className="goto-info"
                    >
                      <span>{t("ReceiptModal.open_campaign")}</span>
                      <span>
                        <Icon icon={arrowRight} size={22} />
                      </span>
                    </Link>

                    {/* open charity */}
                    <Link to={`/${organizationLink}`} className="goto-info">
                      <span>{t("ReceiptModal.open_charity")}</span>
                      <span>
                        <Icon icon={arrowRight} size={22} />
                      </span>
                    </Link>
                  </>
                )}

                {/* download and close button */}
                <div className="download-and-close-btn-div">
                  <MDBBtn
                    type="button"
                    className="text-dark"
                    color="light"
                    onClick={() => setReceiptModal(false)}
                  >
                    {t("ReceiptModal.close")}
                  </MDBBtn>
                  <MDBBtn
                    type="button"
                    className="view-pdf-btn"
                    onClick={viewPDF}
                  >
                    {t("ReceiptModal.view_receipt")}
                  </MDBBtn>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <div className="receipt-box">
              {/* actual receipt */}
              <div className="actual-receipt">
                {/* organization logo */}
                <div className="receipt-organization-logo">
                  <img alt="logo" src={receiptDetails.organizationLogo} />
                </div>

                {/* organization name */}
                <h5>{receiptDetails.organization}</h5>

                {/* street address and unit number */}
                <h6>
                  {receiptDetails.organizationStreetAddress}{" "}
                  {receiptDetails.organizationUnitNumber}
                </h6>

                {/* city province postal code */}
                <h6>
                  {receiptDetails.organizationCity}{" "}
                  {receiptDetails.organizationProvinceState}{" "}
                  {receiptDetails.organizationPostalZipCode}
                </h6>

                {/* email-phone-and-website */}
                <div className="phone-and-website">
                  <p>
                    <a href={`mailto:${receiptDetails.organizationEmail}`}>
                      {receiptDetails.organizationEmail}
                    </a>
                  </p>
                  <p>{receiptDetails.organizationPhone}</p>
                  {receiptDetails.organizationWebsite &&
                    receiptDetails.organizationWebsite !== "" && (
                      <p>
                        <a
                          href={receiptDetails.organizationWebsite}
                          target="blank"
                        >
                          {receiptDetails.organizationWebsite}
                        </a>
                      </p>
                    )}
                </div>

                <div className="colored-row first">
                  <span>{t("ReceiptModal.payment_method")}</span>
                  <span>{t("ReceiptModal.card_number")}</span>
                </div>

                <div className="data-row">
                  <span className="font-weight">{receiptDetails.cardType}</span>
                  <span>{receiptDetails.cardAccount}</span>
                </div>

                <div className="colored-row">
                  <span>{t("ReceiptModal.campaign")}</span>
                  <span>{t("ReceiptModal.amount")}</span>
                </div>

                <div className="data-row">
                  <span className="font-weight">{receiptDetails.campaign}</span>
                  <span>$ {receiptDetails.approvedAmount}</span>
                </div>

                <div className="colored-row">
                  <span>{t("ReceiptModal.transaction_details_donations")}</span>
                  <span />
                </div>

                <div className="data-row border-bottom">
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.MID")}:
                    </span>{" "}
                    {receiptDetails.merchantId}
                  </span>
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.sequence")} #:
                    </span>{" "}
                    {receiptDetails.sequenceNumber}
                  </span>
                </div>

                <div className="data-row border-bottom">
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.invoice")} #:
                    </span>{" "}
                    {receiptDetails.invoice}
                  </span>
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.created")}:
                    </span>{" "}
                    {receiptDetails.inputDate}
                  </span>
                </div>
                <div className="data-row border-bottom">
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.authentication")} #:
                    </span>{" "}
                    {receiptDetails.authenticationNumber}
                  </span>
                  <span>
                    <span className="font-weight">
                      {t("ReceiptModal.batch")} #:
                    </span>{" "}
                    {receiptDetails.batchNumber}
                  </span>
                </div>
                <div className="data-row border-bottom">
                  <span className="font-weight">
                    Transaction: APPROVED - 00
                  </span>
                  <span />
                </div>
                <div className="colored-row">
                  <span>{receiptDetails.organizationFooterNote}</span>
                  <span />
                </div>
              </div>
              {/* receipt action */}
              <div className="receipt-actions-div">
                <div className="actions-left">
                  <MDBBtn
                    type="button"
                    className="text-dark"
                    color="light"
                    onClick={() => setStep(step - 1)}
                    disabled={downloadLoader}
                  >
                    {t("ReceiptModal.back")}
                  </MDBBtn>
                </div>
                <div className="actions-right">
                  <MDBBtn
                    type="button"
                    className="receipt-modal-download-button"
                    onClick={downloadReceipt}
                    disabled={downloadLoader}
                  >
                    {downloadLoader ? (
                      <span>{t("ReceiptModal.downloading")}</span>
                    ) : (
                      <span>{t("ReceiptModal.download")}</span>
                    )}
                  </MDBBtn>
                  <MDBBtn
                    type="button"
                    onClick={printReceipt}
                    className="receipt-modal-print-button"
                    disabled={downloadLoader}
                  >
                    {t("ReceiptModal.print")}
                  </MDBBtn>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ReceiptModal;
