/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../css/DownloadTaxReceiptByCode.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { arrows_down } from "react-icons-kit/linea/arrows_down";
import { arrows_up } from "react-icons-kit/linea/arrows_up";
import RegisterationModalInTaxReceipt from "./RegisterationModalInTaxReceipt";
// worker
import { Worker } from "@react-pdf-viewer/core";
// Import the viewer component
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
// Import the viewer styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// download plugin
import { getFilePlugin } from "@react-pdf-viewer/get-file";
// print plugin
import { printPlugin } from "@react-pdf-viewer/print";
// print plugin styles
import "@react-pdf-viewer/print/lib/styles/index.css";
// zoom plugin
import { zoomPlugin } from "@react-pdf-viewer/zoom";
// zoom plugin styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";

// get current path
function getCurrentPath() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const fullPath = location.pathname;
  // get second path
  const secondPath = fullPath.split("/")[2];
  return secondPath;
}

const DownloadTaxReceiptByCodeComponent = ({ hostName }) => {
  // download plugin instances
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => {
      // `file.name` is the URL of the opened file
      const fileName = file.name.substring(file.name.lastIndexOf("/") + 1);
      return `${fileName}.pdf`;
    },
  });
  const { Download } = getFilePluginInstance;

  // print plugin instance
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  // zoom plugin instance
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, CurrentScale, Zoom } =
    zoomPluginInstance;
  const isMobile = window.innerWidth <= 600;

  // states
  const [code] = useState(getCurrentPath());
  const [registerationModal, setRegisterationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // to hide body scroll for receipt modal
  useEffect(() => {
    if (registerationModal === true) {
      document.body.style.overflow = "hidden";
    } else if (registerationModal === false) {
      document.body.style.overflow = "auto";
    }
  }, [registerationModal]);

  // option states
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(false);

  // retry states
  const [retryCount, setRetryCount] = useState(0);

  // email
  const [donorEmail, setDonorEmail] = useState(null);

  // hit download tax receipt api
  useEffect(() => {
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = initializeRecaptcha;
      document.head.appendChild(script);
    };

    const initializeRecaptcha = () => {
      console.log("reCAPTCHA script loaded");
      console.log("window.grecaptcha:", window.grecaptcha);
      console.log(
        "typeof window.grecaptcha.execute:",
        typeof window.grecaptcha.execute
      );
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.execute === "function"
      ) {
        // Use grecaptcha.execute here
        window.grecaptcha
          .execute("6LelmPUjAAAAAFA5Ks_St3J5yxjK4Jce7VBKsJgW", {
            action: "CHECKOUT",
          })
          .then((token) => {
            if (token) {
              axios
                .get(
                  `${hostName}api/v1/taxReceipt/download?code=${code}&recaptchaToken=${token}`,
                  { responseType: "arraybuffer" }
                )
                .then((res) => {
                  if (res.status === 200) {
                    // Convert the response data to a Blob
                    const blob = new Blob([res.data], {
                      type: "application/pdf",
                    });

                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);

                    // Set the URL to your state or wherever you need it
                    setData(url);
                  }
                })
                .catch((err) => {
                  if (err.response && err.response.status === 404) {
                    const data = JSON.parse(
                      new TextDecoder().decode(err.response.data)
                    );
                    setDonorEmail(data.data.email);
                    // set all options loading true
                    setAllOptionsLoading(true);
                    // get all options
                    Promise.all([
                      axios.get(`${hostName}api/v1/public/country`),
                      axios.get(
                        `${hostName}api/v1/public/state?countryCode=US`
                      ),
                      axios.get(
                        `${hostName}api/v1/public/state?countryCode=ca`
                      ),
                    ])
                      .then(([countriesRes, usaStatesRes, provincesRes]) => {
                        // Handle countries
                        const countryOptions = countriesRes.data.map(
                          (country) => ({
                            value: country.code,
                            label: country.name,
                          })
                        );
                        setCountries(countryOptions);

                        // Handle USA states
                        const usaStatesOptions = usaStatesRes.data.map(
                          (state) => ({
                            value: state.code,
                            label: state.name,
                          })
                        );
                        setUsaStates(usaStatesOptions);

                        // Handle Canada provinces
                        const provincesOptions = provincesRes.data.map(
                          (province) => ({
                            value: province.code,
                            label: province.name,
                          })
                        );
                        setProvinces(provincesOptions);

                        // set error and registration modal
                        setError(null);
                        setRegisterationModal(true);
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => setAllOptionsLoading(false));
                  } else if (err.response && err.response.status === 400) {
                    setError("Invalid Tax Receipt Code / No Data Found");
                  } else {
                    setError(
                      `${err.message} in downloading the tax receipt. Refresh the page to retry downloading your tax receipt.`
                    );
                  }
                })
                .finally(() => setLoading(false));
            } else {
              setError(
                "No reCAPTCHA token found. Refresh the page to retry downloading your tax receipt."
              );
              setLoading(false);
            }
          })
          .catch((err) => {
            setError(
              `${err.message} in executing reCAPTCHA. Refresh the page to retry downloading your tax receipt.`
            );
            setLoading(false);
          });
      } else {
        if (retryCount < 3) {
          setRetryCount((prevCount) => prevCount + 1);
          loadReCaptchaScript(); // Retry initialization
        } else {
          setError(
            "Unable to load reCAPTCHA after multiple retries. Refreshing the page might fix the issue."
          );
          setLoading(false);
        }
      }
    };

    // Check if grecaptcha is already loaded, if not, load the script
    if (window.grecaptcha) {
      initializeRecaptcha();
    } else {
      loadReCaptchaScript();
    }
  }, [retryCount]);

  // one loading state
  const [oneLoading, setOneLoading] = useState(true);
  const allLoadings = [allOptionsLoading, loading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setOneLoading(isAnyChildLoading);
  }, [allLoadings]);

  // dropdown
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown if clicked outside of it
  const handleGlobalClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Add global click event listener when the component mounts
    document.addEventListener("click", handleGlobalClick);

    // Remove global click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, []);

  return (
    <>
      {oneLoading ? (
        <main className="info-content-div" style={{ height: `${100}%` }}>
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={oneLoading} />
          </div>
        </main>
      ) : (
        <>
          {error ? (
            <main className="info-content-div" style={{ height: `${100}%` }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="error-msg" style={{ marginTop: 0 }}>
                  {error}
                </div>

                {error ===
                  `Register the account with email ${donorEmail} to download the tax receipt.` && (
                  <MDBBtn
                    type="button"
                    onClick={() => setRegisterationModal(true)}
                    style={{ background: "#007c16", marginTop: 15 + "px" }}
                  >
                    Register
                  </MDBBtn>
                )}
              </div>
            </main>
          ) : (
            <>
              {data && !registerationModal && (
                <div
                  style={{
                    marginBottom: 20 + "px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div
                      className="rpv-core__viewer"
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div className="pdf-toolbar-container">
                        <div />
                        <div className="zoom-div">
                          <ZoomOutButton />
                          <div
                            style={{
                              marginTop: "3px",
                              marginLeft: "7px",
                              marginRight: "3px",
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={toggleDropdown}
                            ref={dropdownRef}
                          >
                            <CurrentScale />
                            <span>
                              <Icon
                                icon={isDropdownOpen ? arrows_up : arrows_down}
                                size={24}
                              />
                            </span>
                            {isDropdownOpen && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "0",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ccc",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: "1000",
                                  width: "150px",
                                  height: "fit-content",
                                }}
                                className="pdf-dropdown-container-div"
                              >
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() =>
                                        props.onZoom(
                                          SpecialZoomLevel.ActualSize
                                        )
                                      }
                                    >
                                      Actual size
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() =>
                                        props.onZoom(SpecialZoomLevel.PageWidth)
                                      }
                                    >
                                      Page Width
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(0.4)}
                                    >
                                      40%
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(0.8)}
                                    >
                                      80%
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(1.2)}
                                    >
                                      120%
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(1.6)}
                                    >
                                      160%
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(2.4)}
                                    >
                                      240%
                                    </div>
                                  )}
                                </Zoom>
                                <Zoom>
                                  {(props) => (
                                    <div
                                      style={{
                                        padding: "8px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      onClick={() => props.onZoom(3.2)}
                                    >
                                      320%
                                    </div>
                                  )}
                                </Zoom>
                              </div>
                            )}
                          </div>
                          <ZoomInButton />
                        </div>
                        <div>
                          <Download>
                            {(props) => (
                              <button
                                style={{
                                  backgroundColor: "#007c16",
                                  border: "none",
                                  borderRadius: "4px",
                                  color: "#ffffff",
                                  cursor: "pointer",
                                  padding: "8px",
                                }}
                                onClick={props.onClick}
                              >
                                Download
                              </button>
                            )}
                          </Download>
                          <Print>
                            {(props) => (
                              <button
                                style={{
                                  backgroundColor: "#007c16",
                                  border: "none",
                                  borderRadius: "4px",
                                  color: "#ffffff",
                                  cursor: "pointer",
                                  padding: "8px",
                                  marginLeft: "10px",
                                }}
                                onClick={props.onClick}
                              >
                                Print
                              </button>
                            )}
                          </Print>
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          overflow: "hidden",
                        }}
                      >
                        <Viewer
                          fileUrl={data}
                          plugins={[
                            getFilePluginInstance,
                            printPluginInstance,
                            zoomPluginInstance,
                          ]}
                          defaultScale={
                            isMobile ? 0.6 : SpecialZoomLevel.ActualSize
                          }
                        />
                      </div>
                    </div>
                  </Worker>
                </div>
              )}
            </>
          )}
        </>
      )}
      {registerationModal && (
        <RegisterationModalInTaxReceipt
          setRegisterationModal={setRegisterationModal}
          hostName={hostName}
          setErrorOnClose={setError}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          code={code}
          setData={setData}
          donorEmail={donorEmail}
        />
      )}
    </>
  );
};

export default DownloadTaxReceiptByCodeComponent;
