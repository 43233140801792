import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";

const Doughnut = ({ data, shadow = false }) => {
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (shadow) {
        ChartJS.defaults.doughnutWithShadow = ChartJS.defaults.doughnut;
        ChartJS.controllers.doughnutWithShadow =
          ChartJS.controllers.doughnut.extend({
            draw(ease) {
              ChartJS.controllers.doughnut.prototype.draw.call(this, ease);
              const {
                chart: { ctx },
              } = this;
              ctx.save();
              ctx.shadowColor = "rgba(0,0,0,0.15)";
              ctx.shadowBlur = 10;
              ctx.shadowOffsetX = 0;
              ctx.shadowOffsetY = 10;
              ctx.responsive = true;
              ChartJS.controllers.doughnut.prototype.draw.apply(
                this,
                arguments
              );
              ctx.restore();
            },
          });
      }
      const context = chartContainer.current.getContext("2d");

      // options
      const customOptions = {
        radiusBackground: {
          color: "#d1d1d1",
        },
        maintainAspectRatio: false,
        cutout: "80%",
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              padding: 30,
            },
          },
          tooltip: {
            backgroundColor: "#fff",
            titleColor: "#000",
            bodyColor: "#000",
            bodySpacing: 10,
            xPadding: 15,
            yPadding: 15,
            cornerRadius: 0.15,
          },
          datalabels: {
            display: false,
          },
        },
      };

      // plugins
      const centerTextPlugin = {
        beforeDraw(chart) {
          const width = chart.chartArea.right;
          const height = chart.chartArea.bottom;
          const { ctx } = chart;
          ctx.restore();

          let activeLabel = chart.data.labels[0];
          let activeValue = chart.data.datasets[0].data[0];
          const meta = chart.getDatasetMeta(0);
          const { total } = meta;
          let activePercentage = parseFloat(
            ((activeValue / total) * 100).toFixed(2)
          );
          activePercentage = chart.legend.hidden ? 0 : activePercentage;

          if (chart.pointAvailable) {
            activeLabel = chart.data.labels[chart.pointIndex];
            activeValue =
              chart.data.datasets[chart.pointDataIndex].data[chart.pointIndex];
            const meta = chart.getDatasetMeta(0);
            const { total } = meta;

            activePercentage = parseFloat(
              ((activeValue / total) * 100).toFixed(2)
            );
            activePercentage = chart.legend.legendItems[chart.pointIndex].hidden
              ? 0
              : activePercentage;
          }

          ctx.font = "28px Nunito, sans-serif";
          ctx.textBaseline = "middle";

          const text = `${activePercentage}%`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;
          ctx.fillText(text, textX, textY);

          ctx.font = "14px Nunito, sans-serif";
          ctx.textBaseline = "middle";

          const text2 = activeLabel;
          const textX2 = Math.round((width - ctx.measureText(text2).width) / 2);
          const textY2 = height / 2 - 30;
          ctx.fillText(text2, textX2, textY2);

          ctx.save();
        },
        // afterDraw will also work
        beforeEvent(chart) {
          if (chart._active.length > 0) {
            const firstPoint = chart._active[0];
            if (firstPoint) {
              chart.pointIndex = firstPoint.index;
              chart.pointDataIndex = firstPoint.datasetIndex;
              chart.pointAvailable = true;
            }
          }
        },
      };

      const newChartInstance = new ChartJS(context, {
        type: shadow ? "doughnutWithShadow" : "doughnut",
        options: customOptions,
        plugins: [centerTextPlugin],
        data,
      });
      setChartInstance(newChartInstance);
      return () => {
        newChartInstance.destroy();
      };
    }
  }, [chartContainer, data, shadow]);

  return <canvas ref={chartContainer} id="myDoughnutChart" />;
};

export default Doughnut;
