/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-icons-kit";
import { arrows_left } from "react-icons-kit/linea/arrows_left";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import logo from "../../images/logo.png";
import sparks from "../../images/sparks.png";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

export const FeeScreen = ({
  organizationDetails,
  step,
  setStep,
  amount,
  newAmount,
  setNewAmount,
  amountValid,
  validRecurring,
  fees,
  setFees,
  isChecked,
  setIsChecked,
  lang,
  handleLanguage,
  paths,
  currentQuery,
  charityDetails
}) => {
  // translation
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // if user cheats and directly come to this step, navigate the user
  useEffect(() => {
    if (!amountValid || !validRecurring) {
      setStep(0);
      if (currentQuery) {
        navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, { replace: true });
      } else {
        navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
      }
    }
  }, []);

  // handle continue
  const handleContinue = () => {
    setStep(step + 1);
  };

  // percentage
  const [percentage, setPercentage] = useState(null);
  const [width, setWidth] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  useEffect(() => {
    if (charityDetails) {
      const calculatedPercentage = (
        (charityDetails.raisedAmount / charityDetails.targetAmount) *
        100
      ).toFixed(2);
      const calculatedWidth =
        calculatedPercentage >= 100 || !isFinite(calculatedPercentage)
          ? "100%"
          : `${calculatedPercentage}%`;
      const calculatedBackgroundColor = "#007c16";

      setPercentage(calculatedPercentage);
      setWidth(calculatedWidth);
      setBackgroundColor(calculatedBackgroundColor);
    }
  }, [charityDetails]);

  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // new amount and fees
  useEffect(() => {
    const feesExist = parseFloat(charityDetails.fees) > 0;

    if (feesExist && isChecked) {
      const feeAmount = new BigNumber(parseFloat(charityDetails.fees))
        .dividedBy(100)
        .times(parseFloat(amount))
        .toFixed(2);

      const updatedAmount = new BigNumber(parseFloat(amount))
        .plus(feeAmount)
        .toFixed(2);

      setFees(feeAmount);
      setNewAmount(updatedAmount);
    } else {
      setNewAmount(amount);
      setFees(0);
    }
  }, [isChecked]);

  return (
    <div className="home-content">
      <div className="embed-charity-details-container">
        <header className="embed-charity-details-header">
          <div onClick={() => setStep(step - 1)} className="arrow-left-span">
            <Icon icon={arrows_left} size={32} />
          </div>
          <h5 className="choose-amount">{t("Embed.transaction_costs")}</h5>
        </header>

        <div className="embed-logo-container">
          <div className="embed-campaign-logo">
            <img src={charityDetails.icon.filename} alt="card-logo" />
          </div>
          <div className="embed-charity-organization-name">
            <div className="embed-charity-name">{charityDetails.name}</div>
            <div className="embed-organization-name">
              {organizationDetails.name}
            </div>
          </div>
        </div>

        {charityDetails.targetAmount > 0 && (
          <>
            {width && backgroundColor && (
              <div className="campaign-details-progress-div">
                <div
                  className="progress-bar"
                  style={{
                    width,
                    background: backgroundColor,
                  }}
                ></div>
              </div>
            )}
          </>
        )}

        {charityDetails.targetAmount > 0 && (
          <div className="campaign-collection">
            <div className="collection-box">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.raisedAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.raised")}</h6>
            </div>

            <div className="collection-box">
              {percentage && (
                <>
                  <h4>
                    {percentage >= 100 || !isFinite(percentage)
                      ? 100
                      : percentage}
                    %
                  </h4>
                  <h6>{t("CampaignDetails.collection")}</h6>
                </>
              )}
            </div>
            <div className="collection-box last">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.targetAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.target")}</h6>
            </div>
          </div>
        )}

        <div className={`checkbox-container ${isChecked ? "checked" : ""}`}>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span>{t("Embed.cover_transaction_costs")}</span>
            {isChecked && <img src={sparks} alt="sparks" />}
          </label>
        </div>

        <p className="embed-charity-msg">
          {isChecked ? (
            <>
              {t("Embed.by_covering")} {organizationDetails.currencySymbol}
              {fees} {t("Embed.message")}
            </>
          ) : (
            <>{t("Embed.would_cover")}</>
          )}
        </p>

        <h1 className="new-amount">
          {organizationDetails.currencySymbol}
          {newAmount}
        </h1>

        {/* continue btn */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: `${25}px`,
          }}
        >
          <button
            type="button"
            className="continue-btn"
            onClick={handleContinue}
          >
            {t("CampaignDetails.continue")}
            <span>
              <Icon icon={arrows_right} size={28} />
            </span>
          </button>
        </div>
      </div>

      {/* indicators */}
      <div className="indicators-box">
        <span
          className="indicators"
          onClick={() => {
            setStep(0);
            if (currentQuery) {
              navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, {
                replace: true,
              });
            } else {
              navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
            }
          }}
        />
        <span className="indicators" onClick={() => setStep(1)} />
        <span className="indicators" onClick={() => setStep(2)} />
        <span className="indicators active" />
        <span className="indicators" onClick={handleContinue} />
      </div>

      {/* footer */}
      <div className="embedded-footer-box">
        <div>
          <span>
            <Icon icon={basic_lock} size={22} />
          </span>
          <span className="secure-donation">
            {t("OrganizationDetails.secure_donation")}
          </span>
        </div>

        <div className="powered-by">
          {t("Embed.powered_by")}
          <img src={logo} alt="logo" />
        </div>

        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            English
          </button>
        )}
      </div>
    </div>
  );
};
export default FeeScreen;
