/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import DashboardComponent from "../../components/dashboard-tab/DashboardComponent";
import { useNavigate } from "react-router-dom";

// get user from LS
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const Dashboard = ({
  hostName,
  userData,
  setLoggedInUserData,
  toggled,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  setTaxReceiptModal,
  setLinkDonationModal,
}) => {
  // user state from LS
  const [userLS] = useState(getUser());

  // navigate
  const navigate = useNavigate();

  // redirect to login if no user
  useEffect(() => {
    if (!userLS) {
      navigate("/signin");
    }
  }, []);

  return (
    <div className={userData ? "main-container logged-in" : "main-container"}>
      <Header
        hostName={hostName}
        userData={userData}
        setLoggedInUserData={setLoggedInUserData}
        handleToggle={handleToggle}
        dropdownToggle={dropdownToggle}
        handleDropdownToggle={handleDropdownToggle}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setTaxReceiptModal={setTaxReceiptModal}
        setLinkDonationModal={setLinkDonationModal}
      />
      <div className="dashboard-container">
        <Sidebar userData={userData} toggled={toggled} />
        <div className="home-content">
          <DashboardComponent
            hostName={hostName}
            setLoggedInUserData={setLoggedInUserData}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
