import React from "react";

import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

// react icons kit
import { Icon } from "react-icons-kit";
import { basic_home } from "react-icons-kit/linea/basic_home";
import { ecommerce_creditcard } from "react-icons-kit/linea/ecommerce_creditcard";
import { ecommerce_wallet } from "react-icons-kit/linea/ecommerce_wallet";
import { arrows_rotate_anti } from "react-icons-kit/linea/arrows_rotate_anti";
import { software_layout_sidebar_left } from "react-icons-kit/linea/software_layout_sidebar_left";
import { basic_postcard } from "react-icons-kit/linea/basic_postcard";
import { ecommerce_receipt } from "react-icons-kit/linea/ecommerce_receipt";

// simplebar
import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import "simplebar/dist/simplebar.css";

const Sidebar = ({ userData, toggled }) => {
  // translator
  const { t } = useTranslation();

  // getting searchLoader from searchReducer
  const searchResult = useSelector((state) => state.searchReducer);
  const searchLoader = searchResult.loader;

  // links click
  const handleLinkClick = (e) => {
    if (userData === null || searchLoader) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={toggled ? "sidebar-box toggled" : "sidebar-box"}
      data-simplebar
      id="sidebar"
    >
      <NavLink to="/" className="link-div">
        <Icon icon={basic_home} size={42} />
        <span className="link-text">{t("Sidebar.home")}</span>
      </NavLink>

      <NavLink
        to="/dashboard"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={software_layout_sidebar_left} size={42} />
        <span className="link-text">{t("Sidebar.dashboard")}</span>
      </NavLink>

      <NavLink
        to="/donations"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={ecommerce_creditcard} size={42} />
        <span className="link-text">{t("Sidebar.donations")}</span>
      </NavLink>

      <NavLink
        to="/recurring"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={arrows_rotate_anti} size={42} />
        <span className="link-text">{t("Sidebar.recurring")}</span>
      </NavLink>

      <NavLink
        to="/wallet"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={ecommerce_wallet} size={42} />
        <span className="link-text">{t("Sidebar.wallet")}</span>
      </NavLink>

      <NavLink
        to="/tax-receipts"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={ecommerce_receipt} size={42} />
        <span className="link-text" style={{ whiteSpace: "nowrap" }}>
          {t("Sidebar.taxReceipts")}
        </span>
      </NavLink>

      <NavLink
        to="/profile"
        className={userData !== null ? "link-div" : "link-div disabled"}
        onClick={handleLinkClick}
      >
        <Icon icon={basic_postcard} size={42} />
        <span className="link-text">{t("Sidebar.profile")}</span>
      </NavLink>
    </div>
  );
};

export default Sidebar;
