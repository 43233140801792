/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ClapSpinner } from "react-spinners-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBCheckbox } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const TaxReceiptModal = ({
  hostName,
  setLoggedInUserData,
  setTaxReceiptModal,
}) => {
  // navigate
  const navigate = useNavigate();
  // general states
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [taxRecord, setTaxRecord] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);
  const [organizationObj, setOrganizationObj] = useState(null);
  const [step, setStep] = useState(1);
  const [checkedItems, setCheckedItems] = useState([]);
  const [tags, setTags] = useState([]);
  // get tax receipt organization
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionError, setTransactionsError] = useState("");
  // pdf
  const [pdfLoader, setPdfLoader] = useState(false);
  const [pdfError, setPdfError] = useState("");
  // send email
  const [sendEmail, setSendEmail] = useState(false);

  // setting up pop up height
  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);

    return () => {
      window.removeEventListener("resize", documentHeight);
    };
  });

  // backdrop click
  const handleBackdropClick = (e) => {
    if (
      e.target.classList.contains("backdrop") ||
      e.target.classList.contains("tax-cross-icon")
    ) {
      if (
        loader === false &&
        transactionLoader === false &&
        pdfLoader === false
      ) {
        setTaxReceiptModal(false);
      } else {
        setTaxReceiptModal(true);
      }
    } else {
      setTaxReceiptModal(true);
    }
  };

  // fetching tax receipt organization
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      // hitting api
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoader(true);
      axios
        .get(`${hostName}api/v1/donor/taxReceiptOrganizations`, axiosConfig)
        .then((res) => {
          setLoader(false);
          if (res.data.data.length > 0) {
            setTaxRecord(res.data.data);
            setError("");
          } else {
            setError(
              "You do not have tax record history. Contact your charity"
            );
          }
        })
        .catch((err) => {
          setLoader(false);
          setError(err.message);
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            setTaxReceiptModal(false);
            navigate("/signin");
          }
        });
    }
  }, []);

  // handle organization change
  const [consolidated, setConsolidated] = useState(false);
  const handleOrganizationChange = (value) => {
    setOrganization(value);
    taxRecord.forEach((record) => {
      if (record.organizationName === value) {
        setYears(record.years);
        setYear(record.years[0]);
        setOrganizationObj(record);
        setStep(2);
        if (record.consolidateTaxReceipt === true) {
          setConsolidated(true);
        } else {
          setConsolidated(false);
        }
      }
    });
  };

  // getting transactions record
  useEffect(() => {
    if (organizationObj !== null) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        // hitting api
        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setTransactionLoader(true);
        setTransactions([]);
        axios
          .get(
            `${hostName}api/v1/donor/taxReceiptTransactions?organizationTag=${organizationObj.organizationTag}&year=${year}`,
            axiosConfig
          )
          .then((res) => {
            setTransactionLoader(false);
            if (res.data.data.length > 0) {
              const emptyArray = [];
              const { data } = res.data;
              data.forEach((individualData) => {
                if (consolidated) {
                  individualData.checked = true;
                } else {
                  individualData.checked = false;
                }
                emptyArray.push(individualData);
                setTransactions(data);
              });
              setTransactionsError("");
            } else {
              setTransactionsError("No Record Found");
            }
          })
          .catch((err) => {
            setTransactionsError(err.message);
            setTransactionLoader(false);
            if (err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              setTaxReceiptModal(false);
              navigate("/signin");
            }
          });
      }
    }
  }, [organization, year, step]);

  // select all
  const handleSelectAll = (e) => {
    const emptyArray = [];
    transactions.forEach((individualTransaction) => {
      if (e.target.checked) {
        individualTransaction.checked = true;
      } else {
        individualTransaction.checked = false;
      }
      emptyArray.push(individualTransaction);
    });

    setTransactions(emptyArray);
  };

  // individual checkboxes
  const handleCheckboxes = (index, e) => {
    const items = [...transactions];
    const item = items[index];
    if (e.target.checked) {
      item.checked = true;
    } else {
      item.checked = false;
    }
    items[index] = item;
    setTransactions(items);
  };

  // filtering only checked items
  useEffect(() => {
    const filteredItems = transactions.filter(
      (individualTransaction) => individualTransaction.checked === true
    );
    setCheckedItems(filteredItems);
  }, [transactions]);

  // getting transaction tags from checked items
  useEffect(() => {
    const emptyArray = [];
    checkedItems.forEach((item) => {
      const { transactionTag } = item;
      emptyArray.push(transactionTag);
    });
    setTags(emptyArray);
  }, [checkedItems]);

  // view download pdf
  const handleViewDownloadPDf = () => {
    setPdfLoader(true);
    setPdfError("");
    let user = localStorage.getItem("user");
    const language = localStorage.getItem("i18nextLng");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      if (consolidated) {
        axios
          .get(
            `${hostName}api/v1/donor/taxReceipt?organizationTag=${organizationObj.organizationTag}&year=${year}&language=${language}&consolidateTaxReceipt=${consolidated}&sendEmail=${sendEmail}`,
            axiosConfig
          )
          .then((res) => {
            setPdfLoader(false);

            // download pdf
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${organizationObj.organizationName} ${year}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            setPdfError(err.message);
            setPdfLoader(false);
            if (err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              setTaxReceiptModal(false);
              navigate("/signin");
            }
          });
      } else {
        axios
          .get(
            `${hostName}api/v1/donor/taxReceipt?organizationTag=${organizationObj.organizationTag}&year=${year}&language=${language}&consolidateTaxReceipt=${consolidated}&transactionTags[]=${tags}&sendEmail=${sendEmail}`,
            axiosConfig
          )
          .then((res) => {
            setPdfLoader(false);

            // download pdf
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${organizationObj.organizationName} ${year}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            setPdfError(err.message);
            setPdfLoader(false);
            if (err.response.status === 401) {
              localStorage.removeItem("user");
              setLoggedInUserData(null);
              setTaxReceiptModal(false);
              navigate("/signin");
            }
          });
      }
    }
  };

  // translation
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        className="backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
        onClick={handleBackdropClick}
      >
        <motion.div
          className="tax-receipt-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {step === 1 ? (
            <div className="tax-receipt-box">
              {loader ? (
                <div className="loading-screen">
                  <ClapSpinner size={30} color="#6fb327" loading={loader} />
                </div>
              ) : (
                <>
                  <header className="tax-receipt-modal-header">
                    <h4>{t("TaxReceiptModal.download_tax_receipt")}</h4>
                    <button
                      type="button"
                      className="tax-cross-icon"
                      onClick={() => setTaxReceiptModal(false)}
                      disabled={loader || transactionLoader || pdfLoader}
                    >
                      X
                    </button>
                  </header>
                  <div className="tax-receipt-modal-body">
                    {error ? (
                      <div className="error-msg">{error}</div>
                    ) : (
                      <>
                        {taxRecord.length > 0 && (
                          <div className="selects">
                            <h6 className="select-msg">
                              {t("TaxReceiptModal.select_organization_year")}
                            </h6>
                            <label>{t("TaxReceiptModal.organization")}</label>
                            <select
                              className="form-control"
                              required
                              value={organization || ""}
                              onChange={(e) =>
                                handleOrganizationChange(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                {t("TaxReceiptModal.select_organization")}
                              </option>
                              {taxRecord.map((option) => (
                                <option
                                  key={option.organizationTag}
                                  value={option.organizationName}
                                >
                                  {option.organizationName}
                                </option>
                              ))}
                            </select>
                            <br />
                            <label>{t("TaxReceiptModal.year")}</label>
                            <select
                              className="form-control"
                              required
                              value={year || ""}
                              onChange={(e) => setYear(e.target.value)}
                              disabled={organization === null}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="tax-receipt-box step-2" style={{ padding: 0 }}>
              {loader ? (
                <div className="loading-screen">
                  <ClapSpinner size={30} color="#6fb327" loading={loader} />
                </div>
              ) : (
                <>
                  <header className="tax-receipt-modal-header">
                    <h4>{t("TaxReceiptModal.download_tax_receipt")}</h4>
                    <button
                      type="button"
                      className="tax-cross-icon"
                      onClick={() => setTaxReceiptModal(false)}
                      disabled={loader || transactionLoader || pdfLoader}
                    >
                      X
                    </button>
                  </header>
                  <div className="tax-receipt-modal-body screen-2">
                    {error ? (
                      <div className="error-msg">{error}</div>
                    ) : (
                      <>
                        <div className="screen-left">
                          {taxRecord.length > 0 && (
                            <div className="selects">
                              <h6 className="select-msg">
                                {t("TaxReceiptModal.select_organization_year")}
                              </h6>
                              <label>{t("TaxReceiptModal.organization")}</label>
                              <select
                                className="form-control"
                                required
                                value={organization || ""}
                                onChange={(e) =>
                                  handleOrganizationChange(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  {t("TaxReceiptModal.select_organization")}
                                </option>
                                {taxRecord.map((option) => (
                                  <option
                                    key={option.organizationTag}
                                    value={option.organizationName}
                                  >
                                    {option.organizationName}
                                  </option>
                                ))}
                              </select>
                              <br />
                              <label>{t("TaxReceiptModal.year")}</label>
                              <select
                                className="form-control"
                                required
                                value={year || ""}
                                onChange={(e) => setYear(e.target.value)}
                                disabled={organization === null}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <br />
                              <MDBCheckbox
                                onChange={() => setSendEmail(!sendEmail)}
                                name="flexCheck"
                                value=""
                                id="flexCheckDefault"
                                label={t("TaxReceiptModal.send_email")}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            transactionLoader
                              ? "screen-right padding"
                              : "screen-right"
                          }
                        >
                          {transactionLoader ? (
                            <div className="loading-screen">
                              <ClapSpinner
                                size={30}
                                color="#6fb327"
                                loading={transactionLoader}
                              />
                            </div>
                          ) : (
                            <>
                              {transactionError ? (
                                <div className="error-msg">
                                  {transactionError}
                                </div>
                              ) : (
                                <>
                                  {transactions.length > 0 && (
                                    <>
                                      <div className="select-all-box">
                                        <div className="select-container">
                                          <MDBCheckbox
                                            name="flexCheck"
                                            value=""
                                            id="flexCheckDefault"
                                            onChange={(e) => handleSelectAll(e)}
                                            checked={
                                              transactions.length ===
                                                checkedItems.length ||
                                              !!consolidated
                                            }
                                            disabled={!!consolidated}
                                          />
                                          <h5 className="select-container-h5">
                                            {t("TaxReceiptModal.select_all")}
                                          </h5>
                                        </div>
                                        <div className="select-tracker">
                                          {checkedItems.length}/
                                          {transactions.length}
                                        </div>
                                      </div>
                                      <div className="transactions-container">
                                        {transactions.map(
                                          (transaction, index) => (
                                            <div
                                              className="transactions-container-row"
                                              key={transaction.transactionTag}
                                            >
                                              <div className="row-left">
                                                <MDBCheckbox
                                                  name="flexCheck"
                                                  value=""
                                                  id="flexCheckDefault"
                                                  checked={transaction.checked}
                                                  disabled={!!consolidated}
                                                  onChange={(e) =>
                                                    handleCheckboxes(index, e)
                                                  }
                                                />
                                                <div>
                                                  <h5>
                                                    {transaction.campaign}
                                                  </h5>
                                                  <h6>
                                                    {
                                                      transaction.transactionDate
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className="row-right">
                                                <h5>
                                                  {transaction.currencySymbol}
                                                </h5>
                                                <h5>{transaction.amount}</h5>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {transactions.length > 0 && (
                    <div className="view-download-div">
                      <MDBBtn
                        className="view-download-btn"
                        onClick={handleViewDownloadPDf}
                        disabled={!!pdfLoader}
                      >
                        {pdfLoader ? (
                          <>{t("TaxReceiptModal.downloading")}</>
                        ) : (
                          <>{t("TaxReceiptModal.download")}</>
                        )}
                      </MDBBtn>
                      {pdfError && <div>{pdfError}</div>}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TaxReceiptModal;
