import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)({
  '& label': {
    color: '#c9cfd1',
    letterSpacing: '0.09em',
    fontSize: '15px',
  },
  '& label.Mui-focused': {
    color: '#007c16',
    letterSpacing: '0.09em',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#c9cfd1',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: '#007c16',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#007c16',
  },
  '& .MuiInputBase-input': {
    fontSize: '15px',
    letterSpacing: '0.09em',
  },
});
