import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useTranslation } from "react-i18next";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdatePhotoPopup = ({
  hostName,
  token,
  userData,
  setLoggedInUserData,
  setUpdatePhotoPopup,
  photoURL,
  photoFile,
}) => {
  // translator
  const { t } = useTranslation();

  // close modal
  const handleCloseModal = () => {
    if (!loading) {
      setUpdatePhotoPopup(false);
    }
  };

  // navigate
  const navigate = useNavigate();

  // photo upload states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // photo upload event
  const handlePhotoUpload = () => {
    setLoading(true);
    setError(null);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // Create a FormData object
    const formData = new FormData();
    formData.append("profileImage", photoFile);

    axios
      .post(
        `${hostName}api/v1/donor/updateProfileImage?_method=PUT`,
        formData,
        axiosConfig
      )
      .then((res) => {
        toast.success(`${res.data.messageDetails}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setUpdatePhotoPopup(false);

        // Update userData.profileImage to new dp url
        const updatedUser = {
          ...userData,
          profileImage: res.data.data,
        };

        // Save the updated user data to localStorage
        localStorage.setItem("user", JSON.stringify(updatedUser));

        // Update the state with the new userData
        setLoggedInUserData(updatedUser);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else {
          setError(
            err.response.data.messageDetails ||
              err.response.data.message ||
              err.message
          );
        }
      })
      .finally(() => setLoading(false));
  };

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AnimatePresence>
      <motion.div
        className="blurry-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="popup-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="popup-header">
            <div>{t("Profile.update_profile_img")}</div>
            <div className="close-icon" onClick={handleCloseModal}>
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="popup-body">
            <div className="dp-container">
              {/* {isLoaded && <>...</>} */}
              <img
                src={photoURL}
                alt="dp"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 1000);
                }}
                style={{ display: isLoaded ? "block" : "none" }}
              />
            </div>

            {error && (
              <div className="error-msg" style={{ width: "100%" }}>
                {error}
              </div>
            )}

            <MDBBtn
              type="button"
              className="save-btn"
              onClick={handlePhotoUpload}
              disabled={!isLoaded || loading}
            >
              {loading ? `${t("Profile.saving")}` : `${t("Profile.save")}`}
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdatePhotoPopup;
