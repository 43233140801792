/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";

// react router dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// sidebar pages
import Home from "./pages/sidebar/Home";
import Dashboard from "./pages/sidebar/Dashboard";
import Donations from "./pages/sidebar/Donations";
import Recurring from "./pages/sidebar/Recurring";
import Wallet from "./pages/sidebar/Wallet";
import TaxReceipts from "./pages/sidebar/TaxReceipts";
import Profile from "./pages/sidebar/Profile";

// header pages (auth)
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";

// search pages
import Organization from "./pages/search/Organization";
import Charity from "./pages/search/Charity";

// direct-hit page
import NotFound from "./pages/directHit/NotFound";
import ResetPassword from "./pages/auth/ResetPassword";
import TaxReceiptModal from "./components/tax-receipt/TaxReceiptModal";
import LinkReceiptModal from "./components/link-receipt/LinkReceiptModal";

// linkDonation
import LinkDonation from "./pages/linkDonation/LinkDonation";

// download tax receipt by code
import DownloadTaxReceiptByCode from "./pages/downloadTaxReceipt/DownloadTaxReceiptByCode";

// cache buster
import CacheBuster from "react-cache-buster";
// packagejson
import packageFiles from "../package.json";
// cache loading
import CacheLoading from "./CacheLoading";

// hostname global declaration
export const hostName = "https://donorapi.mervice.ca/";

// getting user data
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

function App() {
  // userData state from LS
  const [userData, setUserData] = useState(getUser());

  // userData trigger
  const [loggedInUserData, setLoggedInUserData] = useState(null);

  // getting userData from LS
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    } else {
      setUserData(null);
    }
  }, [loggedInUserData]);

  // sidebar toggling
  const [toggled, setToggled] = useState(false);

  const handleToggle = () => {
    setToggled(!toggled);
  };

  // dropdown toggling
  const [dropdownToggle, setDropdownToggle] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownToggle(!dropdownToggle);
  };

  const closeDropDown = (e) => {
    if (e.target.classList.contains("user-profile")) {
      setDropdownToggle(!dropdownToggle);
    } else if (
      e.target.classList.contains("custom-menu") ||
      e.target.classList.contains("menu-name") ||
      e.target.classList.contains("menu-items-div") ||
      e.target.classList.contains("menu-items-link") ||
      e.target.classList.contains("menu-items") ||
      e.target.classList.contains("menu-items logout") ||
      e.target.classList.contains("logout-text")
    ) {
      setDropdownToggle(true);
    } else if (e.target.classList.contains("tax-receipt")) {
      setDropdownToggle(false);
    } else {
      setDropdownToggle(false);
    }
  };

  // search form in header
  const [searchValue, setSearchValue] = useState("");

  // organization and charity
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [charityDetails, setCharityDetails] = useState(null);

  // paths
  const [organizationPath, setOrganizationPath] = useState(null);
  const [charityPath, setCharityPath] = useState(null);

  // donation
  const [donationAmount, setDonationAmount] = useState("");
  const [donationModal, setDonationModal] = useState(false);

  // handle amounts without 0
  const [amountsWithoutZero, setAmountsWithoutZero] = useState([]);

  useEffect(() => {
    if (charityDetails?.amounts?.length > 0) {
      setAmountsWithoutZero(
        charityDetails.amounts.filter((amount) => amount !== 0)
      );
    }
  }, [charityDetails]);

  // quantity
  const [quantity, setQuantity] = useState(1);

  // search animation
  const [searchClass, setSearchClass] = useState(false);
  const toggleSearchAnimation = () => {
    setSearchClass(true);
    setTimeout(() => {
      setSearchClass(false);
    }, 1500);
  };

  // reset password state
  const [resetSuccessMsg, setResetSuccessMsg] = useState("");
  const [step, setStep] = useState(0);

  // close side bar on clicking anywhere on screen
  const wrapperRef = useRef();

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (wrapperRef.current.contains(e.target)) {
        if (
          e.target.id === "toggler" ||
          e.target.parentElement.id === "toggler" ||
          e.target.parentElement.parentElement.id === "toggler" ||
          e.target.parentElement.parentElement.parentElement.id === "toggler"
        ) {
          if (toggled === true) {
            setToggled(false);
          }
        } else if (
          e.target.id === "sidebar" ||
          e.target.parentElement.id === "sidebar" ||
          e.target.classList.contains("link-div") ||
          e.target.parentElement.classList.contains("link-div") ||
          e.target.parentElement.parentElement.classList.contains("link-div") ||
          e.target.classList.contains("link-text")
        ) {
          setToggled(true);
        } else {
          setToggled(false);
        }
      }
    };

    document.addEventListener("click", handleMenuClick);

    return () => {
      document.removeEventListener("click", handleMenuClick);
    };
  }, []);

  // tax receipt modal
  const [taxReceiptModal, setTaxReceiptModal] = useState(false);

  // to hide body scroll for tax-receipt modal
  useEffect(() => {
    if (taxReceiptModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [taxReceiptModal]);

  // link receipt modal
  const [linkDonationModal, setLinkDonationModal] = useState(false);

  // to hide body scroll for link-receipt modal
  useEffect(() => {
    if (linkDonationModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [linkDonationModal]);

  // is production
  const isProduction = process.env.NODE_ENV === "production";

  // destructure version from packageFiles
  const { version } = packageFiles;

  // Override the pushState and replaceState methods to dispatch a custom event
  const overrideHistoryMethods = () => {
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (state, title, url) {
      const result = originalPushState.apply(this, arguments);
      window.dispatchEvent(new Event("pushstate"));
      window.dispatchEvent(new Event("locationchange"));
      return result;
    };

    window.history.replaceState = function (state, title, url) {
      const result = originalReplaceState.apply(this, arguments);
      window.dispatchEvent(new Event("replacestate"));
      window.dispatchEvent(new Event("locationchange"));
      return result;
    };

    window.addEventListener("popstate", () => {
      window.dispatchEvent(new Event("locationchange"));
    });
  };

  overrideHistoryMethods();

  // location
  const [locationData, setLocationData] = useState({
    fullPath: window.location.pathname,
    query: window.location.search,
    vTag: new URLSearchParams(window.location.search).get("vTag"),
    paths: window.location.pathname
      .split("/")
      .filter(Boolean)
      .map((path) => path.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase()),
  });

  useEffect(() => {
    const handleLocationChange = () => {
      setLocationData({
        fullPath: window.location.pathname,
        query: window.location.search,
        vTag: new URLSearchParams(window.location.search).get("vTag"),
        paths: window.location.pathname
          .split("/")
          .filter(Boolean)
          .map((path) => path.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase()),
      });
    };

    // Listen for changes in the location
    window.addEventListener("locationchange", handleLocationChange);

    return () => {
      // Clean up the event listeners
      window.removeEventListener("locationchange", handleLocationChange);
    };
  }, []);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="wrapper" onClick={closeDropDown} ref={wrapperRef}>
        <Router>
          {taxReceiptModal && (
            <TaxReceiptModal
              hostName={hostName}
              setLoggedInUserData={setLoggedInUserData}
              setTaxReceiptModal={setTaxReceiptModal}
            />
          )}
          {linkDonationModal && (
            <LinkReceiptModal
              hostName={hostName}
              setLoggedInUserData={setLoggedInUserData}
              setLinkDonationModal={setLinkDonationModal}
            />
          )}
          <Routes>
            {/* sidebar routes */}
            <Route
              exact
              path="/"
              element={
                <Home
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setOrganizationPath={setOrganizationPath}
                  setOrganizationDetails={setOrganizationDetails}
                  searchClass={searchClass}
                  toggleSearchAnimation={toggleSearchAnimation}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/dashboard"
              element={
                <Dashboard
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/donations"
              element={
                <Donations
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />
            <Route
              path="/recurring"
              element={
                <Recurring
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/wallet"
              element={
                <Wallet
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/tax-receipts"
              element={
                <TaxReceipts
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/profile"
              element={
                <Profile
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            {/* auth routes    */}
            <Route
              path="/signin"
              element={
                <Login
                  hostName={hostName}
                  setLoggedInUserData={setLoggedInUserData}
                  resetSuccessMsg={resetSuccessMsg}
                  setResetSuccessMsg={setResetSuccessMsg}
                  step={step}
                  setStep={setStep}
                />
              }
            />

            <Route
              path="/resetpassword"
              element={
                <ResetPassword
                  hostName={hostName}
                  setResetSuccessMsg={setResetSuccessMsg}
                  setStep={setStep}
                />
              }
            />

            <Route path="/signup" element={<Signup hostName={hostName} />} />

            {/* search routes */}
            <Route
              path={`/${organizationPath}`}
              element={
                <Organization
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  organizationPath={organizationPath}
                  organizationDetails={organizationDetails}
                  setCharityPath={setCharityPath}
                  setCharityDetails={setCharityDetails}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                  currentQuery={locationData.query}
                  setQuantity={setQuantity}
                />
              }
            />

            <Route
              path={`/${organizationPath}/${charityPath}`}
              element={
                <Charity
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  organizationPath={organizationPath}
                  organizationDetails={organizationDetails}
                  charityPath={charityPath}
                  charityDetails={charityDetails}
                  donationAmount={donationAmount}
                  setDonationAmount={setDonationAmount}
                  amountsWithoutZero={amountsWithoutZero}
                  donationModal={donationModal}
                  setDonationModal={setDonationModal}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                  vTag={locationData.vTag}
                  currentQuery={locationData.query}
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
              }
            />

            {/* direct hit */}
            <Route
              path="/link/*"
              element={
                <LinkDonation
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="/download-tax-receipt/*"
              element={
                <DownloadTaxReceiptByCode
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                />
              }
            />

            <Route
              path="*"
              element={
                <NotFound
                  hostName={hostName}
                  userData={userData}
                  setLoggedInUserData={setLoggedInUserData}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  dropdownToggle={dropdownToggle}
                  handleDropdownToggle={handleDropdownToggle}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  organizationPath={organizationPath}
                  setOrganizationPath={setOrganizationPath}
                  organizationDetails={organizationDetails}
                  setOrganizationDetails={setOrganizationDetails}
                  charityPath={charityPath}
                  setCharityPath={setCharityPath}
                  charityDetails={charityDetails}
                  setCharityDetails={setCharityDetails}
                  donationAmount={donationAmount}
                  setDonationAmount={setDonationAmount}
                  amountsWithoutZero={amountsWithoutZero}
                  donationModal={donationModal}
                  setDonationModal={setDonationModal}
                  setTaxReceiptModal={setTaxReceiptModal}
                  setLinkDonationModal={setLinkDonationModal}
                  paths={locationData.paths}
                  vTag={locationData.vTag}
                  currentQuery={locationData.query}
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
              }
            />
          </Routes>
        </Router>
      </div>
    </CacheBuster>
  );
}

export default App;
